import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.css';

import Footer from '../component/Footer/Footer';

import ResourceLinks from '../component/NavMenus/ResourceLinks';
const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
       }
}))

function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
      {/* <img            
                
                className="d-block w-70"
                 class="img-fluid"
                 src="../images/header.jpg" 
                 href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a>
    <MainMenu/> */}


      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum} >
                <Link color="inherit" to="/" >
              Home
            </Link>

            <Link
              color="textPrimary"
              to="#"
              
              aria-current="page">
              Publications at RC
            </Link>
            </Breadcrumbs>
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={8}>
          <Paper ><div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">


                  <h3>
                  Publications at RC</h3>
                    <ul class="linkitem">
                        
                        <li class="linkitem">
                            <a class='mojolink' href='http://ethiopianchamber.com/Data/Sites/1/rc-publications/project-papaers,-studies,-manuals-avialable-at-eccsa-resorce-center.xls'  onclick="window.open(this.href,'_blank');return false;"  title='Project Papers, Studies, Manuals Available at ECCSA Resource Center' >Project Papers, Studies, Manuals Available at ECCSA Resource Center</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class='mojolink' href='http://ethiopianchamber.com/Data/Sites/1/rc-publications/hard-copy-books-and-doc.xlsx'  onclick="window.open(this.href,'_blank');return false;"  title='List of Books at ECCSA Resource Center' >List of Books at ECCSA Resource Center</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class='mojolink' href='http://ethiopianchamber.com/Data/Sites/1/rc-publications/undp-and-eca-publications-at-eccsa-resource-center.xlsx'  onclick="window.open(this.href,'_blank');return false;"  title='UNDP and ECA Publications at ECCSA Resource Center' >UNDP and ECA Publications at ECCSA Resource Center</a>
                            
                            </li>
                    
                        </ul>

                 
                </div>

              </div>
            </div>
          </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          {/* <Paper > */}
          {/* <h5 class="text-uppercase">Quick Links</h5>

<ul class="list-unstyled mb-0">
 
  <li>
    <a href="#!" class="text-green"> Organizational Structure</a>
  </li>
    <li>
    <a href="#!" class="text-green">  General Assembly</a>
  </li>
    <li>
    <a href="#!" class="text-green">  Board of Directors</a>
  </li>
    <li>
    <a href="#!" class="text-green">  The Secretariat</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Achievements</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Former Presidents & Secretary G.</a>
  </li>
    <li>
    <a href="#!" class="text-green">Our Location</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Our Partners</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Strategic Plan</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Chamber Law (+Directives)</a>
  </li>
    <li>
    <a href="#!" class="text-green"> ECCSA Projects</a>
  </li>


</ul> */}
          <ResourceLinks />

          {/* </Paper> */}
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
