import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';
import { classExpression } from '@babel/types';
import HomeSlider from '../component/slider/HomeSlider';
import MainMenu from '../component/NavMenus/MainMenu';
import QuickLinks from '../component/NavMenus/QuickLinks';
import header from '../images/header.jpg';
import Topbar from '../component/topbar/Topbar';
import 'bootstrap/dist/css/bootstrap.css';
import Welcome from '../component/Welcome/Welcome';
import News from '../component/News/News';
import Footer from '../component/Footer/Footer';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
const useStyle = makeStyles((theme) => ({
    grid: {
        width: '100%',
        margin: '0px',
        padding: '3px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        background: theme.palette.success.light,
    },
    breadcrum: {
      backgroundColor: '#fff',
      padding: '10px',
      marginLeft: '100px',
    }
}))

function App() {
    const classes = useStyle();
    return (
        <div class="mmenu">
            {/* <img

                className="d-block w-70"
                class="img-fluid"
                src="../images/header.jpg"
                href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a>
             <MainMenu/> */}

            <Grid container spacing={2} className={classes.grid} className="grid">
                <Grid item xs={12} md={12}>
                <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum}>
           
           <Link  to="/" > Home</Link>
             <Link  eventKey="about" aria-current="page" to="#" > Secretary General</Link>
         
           
         </Breadcrumbs>
                </Grid>

                <Grid item xs={1} md={1}>

                    {/* <Welcome /> */}

                </Grid>
                <Grid item xs={11} md={8}>
                    <Paper ><div class="row">
                        <section id="team" class="pb-5">
                            <div class="container">
                                <h5 class="section-title h3">ECCSA’s Secretary General</h5>
                                <div class="row">

                                    <div class="col-xs-12 col-sm-6 col-md-4">
                                        <div class="image-flip" >
                                            <div class="mainflip flip-0">
                                                <div class="frontside">
                                                    <div class="card">
                                                        <div class="card-body text-center">
                                                            <p><img class=" img-fluid" src="https://ethiopianchamber.com/Data/Sites/1/2012%20EC/Images/SGphoto.jpg" width="200px" alt="card image" /></p>
                                                            <h4 class="card-title">Mr. Yesuf Ademnur </h4>
                                                            <h6>Secretary General</h6>
                                                            <p class="card-text">Tele: +251-115-514-005<br />
                                                                Fax: +251-115517699<br />
                                                                E-mail: yesufa@ethiopianchamber.com</p>
                                                            {/* <a href="https://www.fiverr.com/share/qb8D02" class="btn btn-primary btn-sm"><i class="fa fa-plus"></i></a> */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-4">
                                        <div class="image-flip" >
                                            <div class="mainflip flip-0">
                                                <div class="frontside">
                                                    <div class="card">
                                                        <div class="card-body text-center">
                                                            <p><img class=" img-fluid" src="https://ethiopianchamber.com/Data/Sites/1/mgt-image/Wubie.jpg" width="200px" alt="card image" /></p>
                                                            <h4 class="card-title">
                                                                Mr. Wubie Mengistu</h4>
                                                            <h6>D. Secretary General</h6>
                                                            <p class="card-text"> Tele: 251-115548096<br />
                                                                Fax: +251-115517699<br />
                                                                E-mail: wubiem@ethiopianchamber.com</p>
                                                            {/* <a href="https://www.fiverr.com/share/qb8D02" class="btn btn-primary btn-sm"><i class="fa fa-plus"></i></a> */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-sm-6 col-md-4">
                                        <div class="image-flip" >
                                            <div class="mainflip flip-0">
                                                <div class="frontside">
                                                    <div class="card">
                                                        <div class="card-body text-center">
                                                            <p><img class=" img-fluid" src="https://ethiopianchamber.com/Data/Sites/1/mgt-image/benyam-mesgina-ezra.jpg" width="200px" alt="card image" /></p>
                                                            <h4 class="card-title">Mr. Benyam Mesgina </h4>
                                                            <h6>Director, Investment Promotion Directorate</h6>
                                                            <p class="card-text">Tele: 251-911645352<br />
                                                                Fax: +251-115517699<br />
                                                                E-mail: benyamm@ethiopianchamber.com</p>
                                                            {/* <a href="https://www.fiverr.com/share/qb8D02" class="btn btn-primary btn-sm"><i class="fa fa-plus"></i></a> */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>

                    </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Paper >
                        <h5 class="text-uppercase">Quick Links</h5>

                        <QuickLinks />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={12}>
                    {/* <Paper className={classes.paper}>Footer Section</Paper> */}
                    <Footer />
                </Grid>
            </Grid>
        </div>
    );
}

export default App;
