import { React, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
//   import { Button } from "@material-ui/core";
import { Navbar, Nav, NavDropdown, Container, NavItem, Form, FormControl, Button } from 'react-bootstrap';
import './MainMenu.css';
import { makeStyles } from '@material-ui/core/styles';
//   import Home from './Home';
//   import AboutUs from './AboutUs';
import 'bootstrap/dist/css/bootstrap.min.css';
const useStyles = makeStyles((theme) => ({

  main: {
    width: '100%',
    background: '#198754',
    fontWeight: '600',
    fontStyle: 'normal',

    row: '10px',
    //   textAlign:'left',
    color: '#ffffff',
    alignContent: 'center',
    padding: '10px',


    // elevation:'0',
  },
  margin: {
    marginLeft: '200px',
    marginTop: '5px',
    marginBottom: '0px'
    // color:'primary',
    // float: 'right',
  }

}))

export default function Album() {
  const [active, setActive] = useState('default');
  const classes = useStyles();
  // const classes=useStyle();
  // render(){
  return (
    <div class="mmenu">
      <img
        className="d-block w-70"
        class="img-fluid"
        src="../images/header.jpg"
        href="/"></img>  <a href="/login" class="btn btn-outline-success">My Page</a>
      <Navbar collapseOnSelect className={classes.main} sticky="top" expand="lg" variant="dark">
        <Container>
          <Navbar.Brand href="#home">
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto" activeKey={active}
              onSelect={(selectedKey) => setActive(selectedKey)}>
              <Nav.Link as={Link} to="/"
                eventKey="default">Home</Nav.Link>
              <NavDropdown title="About Us" as={Link} eventKey="about" to="/about" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} eventKey="about" to="/about">About the Chamber</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="assembly" to="/assembly">General Assembly </NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="board" to="/board">Board of Directors</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="team" to="/team"> The Secretariat</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="action1" to="#action/3.4">Former Presidents & Secretary G.</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="action2" to="#action/3.4">Our Location</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="action3" to="#action/3.4">Our Partners</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="action4" to="#action/3.4">Strategic Plan</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="action5" to="#action/3.4">Chamber Law (+Directives)</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="action6" to="#action/3.4">ECCSA Projects</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown as={Link} eventKey="services" to="./services" title="Services" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} eventKey="trade" to="/trade-investment" >Trade & Investment Promotion</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="capacity" to="/capacity-building">Capacity Building</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="research" to="/research-advocacy">research & advocacy</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} eventKey="membership" to="/membership" class="navitems" activeClassName="selected">Membership</Nav.Link>
              <Nav.Link as={Link} eventKey="membership1" to="/" class="navitems" activeClassName="selected">Events</Nav.Link>

              <Nav.Link class="navitems" as={Link} eventKey="news" to="/news">News </Nav.Link>
              <NavDropdown as={Link} eventKey="eresources" to="./eresources" title="Resources" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} eventKey="eresources" to="/eresources" >e-Resources</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="publications" to="/publications">Publications at RC</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="newsletter" to="/newsletter">E-Newsletter Download</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="important" to="/important">Important Links</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="downloads" to="/downloads">Downloads</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="publications" to="/publications">Publications for Sale</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown as={Link} eventKey="services" to="./services" title="Opportunities" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} eventKey="trade" to="/trade-investment" >Business To Business</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="capacity" to="/capacity-building">Training </NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="research" to="/research-advocacy">Business Opportunities</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="research1" to="/research-advocacy">Exhibition</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} eventKey="trade" to="about">About Ethiopia </Nav.Link>
              <Nav.Link as={Link} eventKey="contact" to="/contact">Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

// export default MainMenu;