import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';

import 'bootstrap/dist/css/bootstrap.css';

import Footer from '../component/Footer/Footer';
import { Breadcrumbs } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
       }
}))
function handleClick(event) {
  event.preventDefault();

}
function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
      

      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum} >
                <Link color="inherit" to="/" >
              Home
            </Link>

            <Link
              color="textPrimary"
              to="#"
              
              aria-current="page">
             Capacity Building
            </Link>
            </Breadcrumbs>
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={8}>

        <h5 class="section-title h3">Capacity Building </h5>
        <ul >
	<li>
		<small>Need based training or technical & skill development (Ethiopian Chamber Academy)</small></li>
	<li>
		<small>Consultancy on preparation of Strategic Plan, Project Proposal, Governance Manuals</small></li>
	<li>
		<small>Resource Mobilization</small></li>

</ul>
<table class="table table-hover">
    <thead>
      <tr class=" table-success" >
        <th scope="col">#</th>
        <th scope="col">Training Course Name</th>
        <th scope="col">Schedule  </th>
        <th scope="col">Fee</th>
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr >
        <th scope="row">1</th>
        <td>Training 1</td>
        <td>21/12/2013</td>
        <td>5000</td>
        <td><a href="#"> Apply </a></td>
      </tr>
      <tr >
        <th scope="row">2</th>
        <td>Training 2</td>
        <td>21/12/2013</td>
        <td>5000</td>
        <td><a href="#"> Apply </a></td>
      </tr>
      <tr >
        <th scope="row">3</th>
        <td>Training 2</td>
        <td >21/12/2013</td>
        <td>5000</td>
        <td><a href="#"> Apply</a></td>
      </tr>
    </tbody>
  </table>
<br/>
For more information visit:<br/>

Ethiopian Chamber of Commerce and Sectoral Associations<br/>

Member Support Department<br/>

1st Floor, Room no. 7<br/>

Tel: 251-11 551 82 40<br/>

Fax: 251- 11-5517699<br/>

E-mail: info@ethiopianchamber.com

        </Grid>
        <Grid item xs={12} md={3}>
          <Paper >
            {/* <ServiceLinks /> */}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
