// import { Component } from 'react';
import React, { Component } from "react";

// import Calendar from "react-material-ui-calendar";
import Calendar from "@ericz1803/react-google-calendar";
import './Event.css'

const API_KEY = "AIzaSyBYkSb6LQKo_Ei-WOZ4zm6wNc2JC1NTFR0";
let calendars = [
  {calendarId: "calendar-323315 "},
  {
    // calendarId: "calendar-323315 ",
    color: "#B241D1", //optional, specify color of calendar 2 events
    border: "5px",
  }
];

// const getSelectedDays = (days) => {
//     console.log(days)
// }
// const today = new Date();
// const currentMonth = today.getMonth();
// const currentYear = today.getFullYear();

class Events extends Component{
    
//selected days will be available here
 
  render(){  
  
    return (
      <div class="main">
        <Calendar apiKey={API_KEY} calendars={calendars} />
      </div>
    )
}}

export default Events