
import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Breadcrumb,Nav, Container, Carousel, Row, Col, Form } from "react-bootstrap";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function MediaControlCard() {
    const classes = useStyles();
    const theme = useTheme();
  
        return (
            <div>
                 <Card className={classes.root}>
      {/* <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
          Engineer Melaku Ezezew <br/>
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
          President<br/>
          </Typography>
        </CardContent>
        
      </div> */}
      <CardMedia
        className={classes.cover}
        image="https://ethiopianchamber.com/Data/Sites/1/skins/artisteer31-green/images/Footerlogo.jpg"
        title="Live from space album cover"
      />
    </Card>
            </div>
          );
    }
