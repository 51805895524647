import React, { Component } from 'react';

import { ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';

import 'bootstrap/dist/css/bootstrap.min.css';
import {faBook} from '@fortawesome/free-solid-svg-icons';
import {faChalkboardTeacher} from '@fortawesome/free-solid-svg-icons';
import './Faq.css';
const toasts = [
  {
    id: "Networking",
    category: "Networking",
    message: "To find local and foreign markets for their products and  services."
  },
  {
    id: "Capacity",
    category: "Capacity Building",
    message: "in the area of training, manual preparation, strategic plan document designing, and the like)"
  },
  {
    category: "Consultancy",
    message: "membership and business development consultancy"
  },
  {
    category: "Experience Sharing",
    message: "exchange information and share experience etc."
  }
];

function Toast(props) {
  return (
    <div className={`Toast Toast--${props.category}`}>
      <main className="Toast__message">
        <header className="Toast__message-category">{props.category}</header>
        <p className="Toast__message-text">{props.message}</p>
      </main>
      <button className="Toast__button" type="button" onClick={() => props.dismiss(props.id)}>
         
      </button>
    </div>
  );
}
class Faq extends Component {
 
    render() {
        return(
         

/*  {

  <div className="App">
<Accordion>

  <AccordionSummary  expandIcon={<ExpandMore />}>
  Accordion 1
  </AccordionSummary>

  <AccordionDetails>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
  sit amet blandit leo lobortis eget.
  </AccordionDetails>

</Accordion>

<Accordion>

  <AccordionSummary  expandIcon={<ExpandMore />}>
  Accordion 2
  </AccordionSummary>

  <AccordionDetails>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
  sit amet blandit leo lobortis eget.
  </AccordionDetails>

</Accordion>

<Accordion>

  <AccordionSummary  expandIcon={<ExpandMore />}>
    Accordion 3
  </AccordionSummary>

  <AccordionDetails>
  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
  sit amet blandit leo lobortis eget.
  </AccordionDetails>

</Accordion>

</div> 


<div class="container" data-aos="fade-up">
<div class="row about-container">

  <div class="col-lg-12 content order-lg-1 order-2">
    <h2 class="title">Few Words About Us</h2>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
    </p>

    <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
      <div class="icon"><i class="fa fa-shopping-bag"></i></div>
      <h4 class="title"><a href="">Eiusmod Tempor</a></h4>
      <p class="description">Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>
    </div>

    <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
      <div class="icon"><i class="fa fa-photo"></i></div>
      <h4 class="title"><a href="">Magni Dolores</a></h4>
      <p class="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
    </div>

    <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
      <div class="icon"><i class="fa fa-bar-chart"></i></div>
      <h4 class="title"><a href="">Dolor Sitema</a></h4>
      <p class="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>
    </div>

  </div>

  
</div>

</div>
*/

<div>
{toasts.map((toast, i) => (
  <Toast category={toast.category} key={i} message={toast.message} />
))}
</div>


            )
    }
}

export default Faq