

// import { Icon } from '@material-ui/core/HomeIcon';
import ThreeDRotation from '@material-ui/icons/ThreeDRotation';
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkOutlined';
import React, { Component } from 'react';
// import { Breadcrumb,Nav, Container, Carousel, Card, Row, Col, Form } from "react-bootstrap";
// import './Footer.css'
<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
class Partners extends Component {
    render(){
       return(
<footer class="text-center text-white" >
 
  <div class="container p-4">
  
    <section class="">
      <div class="row">
        <div class="col-lg-2 col-md-12 mb-4 mb-md-0">
          <div
            class="bg-image hover-overlay ripple shadow-1-strong rounded"
            data-ripple-color="light"
          >
            <img
            style={{'height':"150px"}} 
              src="./images/clients/client-1.png"
              class="w-100"
            />
            <a href="#!">
              <div
                class="mask"
                
              ></div>
            </a>
          </div>
        </div>
        <div class="col-lg-2 col-md-12 mb-4 mb-md-0">
          <div
            class="bg-image hover-overlay ripple shadow-1-strong rounded"
            data-ripple-color="light"
          >
            <img
            style={{'height':"150px"}} 
              src="./images/clients/client-2.png"
              class="w-100"
            />
            <a href="#!">
              <div
                class="mask"
               
              ></div>
            </a>
          </div>
        </div>
        <div class="col-lg-2 col-md-12 mb-4 mb-md-0">
          <div
            class="bg-image hover-overlay ripple shadow-1-strong rounded"
            data-ripple-color="light"
          >
            <img
            style={{'height':"150px"}} 
              src="./images/clients/client-3.png"
              class="w-100"
            />
            <a href="#!">
              <div
                class="mask"
               
              ></div>
            </a>
          </div>
        </div>
        <div class="col-lg-2 col-md-12 mb-4 mb-md-0">
          <div
            class="bg-image hover-overlay ripple shadow-1-strong rounded"
            data-ripple-color="light"
          >
            <img
            style={{'height':"150px"}} 
              src="./images/clients/client-4.png"
              class="w-100"
            />
            <a href="#!">
              <div
                class="mask"
            
              ></div>
            </a>
          </div>
        </div>
        <div class="col-lg-2 col-md-12 mb-4 mb-md-0">
          <div
            class="bg-image hover-overlay ripple shadow-1-strong rounded"
            data-ripple-color="light"
          >
            <img
             style={{'height':"150px"}} 
              src="./images/clients/client-5.png"
              class="w-100"
            />
            <a href="#!">
              <div
                class="mask"
               
              ></div>
            </a>
          </div>
        </div>
       
      </div>
    </section>

  </div>
 
 
 
</footer>
)
    }
}
export default Partners