import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid,Paper } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
 import '../App.css';
import '../component/slider/HomeSlider';
import { Breadcrumb,Nav, Tab,  Card, Row, Col, Form } from "react-bootstrap";
 
import MainMenu from '../component/NavMenus/MainMenu'; 

import 'bootstrap/dist/css/bootstrap.css';

import Footer from '../component/Footer/Footer';


const useStyle =makeStyles((theme)=>({
  grid:{
    width:'100%',
    margin:'0px',
    padding:'3px',
    background:'#fff'
  },
  paper:{ 
    padding:theme.spacing(2),
    textAlign:'center',
    color: theme.palette.text.secondary,
    // background:theme.palette.success.light,
  }
}))

function App() {
  const classes=useStyle();
  return (
    <div class="mmenu">
     {/* <img            
                
                className="d-block w-70"
                 class="img-fluid"
                 src="../images/header.jpg" 
                 href="/"></img>  <a href="/users" class="btn btn-outline-success">Mypage</a>
    <MainMenu/>
   */}
     <Grid container spacing={2} className={classes.grid} className="grid">
     <Grid item xs={12} md={12}>
{/* <MainMenu/> */}
       </Grid>
       
       
       <div className="app2">
        <Grid item xs={11} md={12}>
         
        <Paper >
          
          <h3>Trade & Investment Promotion</h3>
          <Tab.Container id="left-tabs-example" defaultActiveKey="1">
          <Row>
          <Col sm={3}>
            <Nav variant="pills" className="">
            <Nav.Item>
                <Nav.Link eventKey="1">Request for arbitration services</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="2" >Request for legal Service</Nav.Link>
              </Nav.Item>
                         
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
            <Tab.Pane eventKey="1">
            Precondition
            <ol><li> Doc that justify the need for arbitration – arbitration clause/ post dispute agreement, commercial contract</li>
            <li> Agreement-for-ECCSA-to-Start arbitration resolution process (Yes/No) (first party)</li>
            <li> Agreement of the second party for ECCSA arbitration
            </li></ol><a href="/promotion" class="btn btn-success">Request Service </a>
              </Tab.Pane>
              <Tab.Pane eventKey="2">
              Prerequisites: attaching the following documents 
            <a href="/promotion" class="btn btn-success">Request Service </a>
              </Tab.Pane>
              
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
          </Paper>
        </Grid>
        <Grid item xs={12} md={1}>
          </Grid>
          </div>
        <Grid item xs={12} md={12}>
     
          <Footer />
        </Grid>
     </Grid>
    </div>
  );
}

export default App;
