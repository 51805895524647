import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';
import 'bootstrap/dist/css/bootstrap.css';
import Footer from '../component/Footer/Footer';
import Breadcrumbs from  '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom';
const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
       }
}))

function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
      {/* <img            
               
               className="d-block w-70"
                class="img-fluid"
                src="../images/header.jpg" 
                href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a>
   <MainMenu/> */}

      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum} >
                <Link color="inherit" to="/" >
              Home
            </Link>

            <Link
              color="textPrimary"
              to="#"
              
              aria-current="page">
              Trade & Investment Promotion
            </Link>
            </Breadcrumbs>
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={8}>

        <h5 class="section-title h3"> Advocacy & Research</h5>
          <h6>Advocacy </h6>
          <p>


            Advocacy is a top priority for the ECCSA, whether in consulting with the government, commenting on regulatory issues or working with the government to bring about equality, opportunity and change.  Accordingly, ECCSA identifies issues affecting the business environment in the country as a whole, and advocate for a resolution of such issues.  ECCSA believes that it's imperative for trade and investment promotion in the country and hence ECCSA represents a unified voice of the Ethiopian private sector through its advocacy efforts.  ECCSA has succeeded in establishing and maintaining a healthy and constructive relationship with the government, and in organizing the business community.  ECCSA addresses legislation, regulations and policy issues that can positively or negatively impact businesses community.  Business communities are free to bring issues of concern to the ECCSA Secretariat so that they would be tabled for discussion with the concerned government offices
          </p>
          For more information:<br />

          Advocacy & Research Department<br />

          Ethiopian Chamber of Commerce and Sectoral Associations<br />

          7th Floor, Room no. 218<br />

          Tel: +251-115-311-088<br />

          Fax: +251-115-517-699<br />

          E-mail: info@ethiopianchamber.com

        </Grid>
        <Grid item xs={12} md={3}>
          <Paper >
            {/* <ServiceLinks /> */}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
