import React from 'react'
// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     useParams,
//   } from "react-router-dom";
//   import { Navbar,Nav,NavDropdown,Form,FormControl,Button } from 'react-bootstrap'
  import './MainMenu.css'
  import 'bootstrap/dist/css/bootstrap.css';
  
  import { Breadcrumb,Nav, Dropdown,Card,ListGroup, Button,FormControl,Navbar,NavItem, NavDropdown, Container,form, Carousel,  Row, Col, Form } from "react-bootstrap";

class ServiceLinks extends React.Component{

    render(){
        return(
//             <ul class="navitems mb-0">
 
//  <h5 class="text-uppercase">Our Services</h5>

// <ul class=" navitems  mb-0">
 
// <li>
// 					<li>
// 						<a href="/trade-investment">
// 							Trade &amp; Investment Promotion</a>
// 						<ul class="AspNet-TreeView-Hide">
// 							<li>
// 								<a href="#">
// 									Business Information</a>
// 							</li>
// 							<li>
// 								<a href="#">
// 									Business Networking</a>
// 							</li>
// 							<li>
// 								<a href="#">
// 									Investment Guide</a>
// 							</li>
// 							<li>
// 								<a href="#">
// 									Exporters Guide</a>
// 							</li>
// 							<li>
// 								<a href="#">
// 									Importer Guide</a>
// 							</li>
// 							<li>
// 								<a href="#">
// 									Issuance of Certificate of Origin</a>
// 							</li>
// 							<li>
// 								<a href="#">
// 									Authentication of Documents</a>
// 							</li>
// 							<li>
// 								<a href="#">
// 									Confirmation of Re-export products</a>
// 							</li>
// 							<li>
// 								<a href="#">
// 									Support Letters</a>
// 							</li>
// 						</ul>
// 					</li>
// 					<li>
// 						<a href="/capacity-building">
// 							Capacity Building</a>
// 					</li>
// 					<li>
// 						<a href="/research-advocacy">
// 							Research &amp; Advocacy</a>
// 						<ul class="AspNet-TreeView-Hide">
// 							<li>
// 								<a href="#">
// 									Advocacy</a>
// 							</li>
// 						</ul>
// 					{/* </li> */}
// 				</li>
// 			</li>

// </ul>
//           </ul>
<Card text="white" style={{ width: '18rem' }}>
<Card.Header>Quick Links</Card.Header>
<Card.Body>
  <Card.Text>
  <li>
 						<a href="/trade-investment">
							Trade &amp; Investment Promotion</a>
						<ul class="AspNet-TreeView-Hide">
 							<li>
 								<a href="#">
 									Business Information</a>
 							</li>
 							<li>
 								<a href="#">
 									Business Networking</a>
 							</li>
 							<li>
 								<a href="#">
 									Investment Guide</a>
 							</li>
 							<li>
 								<a href="#">
 									Exporters Guide</a>
 							</li>
 							<li>
 								<a href="#">
 									Importer Guide</a>
 							</li>
 							<li>
 								<a href="#">
 									Issuance of Certificate of Origin</a>
							</li>
							<li>
								<a href="#">
									Authentication of Documents</a>
 							</li>
 							<li>
 								<a href="#">
 									Confirmation of Re-export products</a>
 							</li>
 							<li>
 								<a href="#">
 									Support Letters</a>
 							</li>
 						</ul>
 					</li>
					<li>
						<a href="/capacity-building">
 							Capacity Building</a>
 					</li>
					<li>
						<a href="/research-advocacy">
							Research &amp; Advocacy</a>
					<ul class="AspNet-TreeView-Hide">
							<li>
								<a href="#">
									Advocacy</a>
 							</li>
						</ul>
						</li>
						
  </Card.Text>
</Card.Body>
</Card>
        )  
    }
}

export default ServiceLinks;