import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.css';
import Welcome from '../component/Welcome/Welcome';
import News from '../component/News/News';
import Footer from '../component/Footer/Footer';
import { Breadcrumb, Nav, Dropdown, Button, FormControl, Navbar, NavItem, NavDropdown, Container, form, Carousel, Card, Row, Col, Form } from "react-bootstrap";
import QuickLinks from '../component/NavMenus/QuickLinks';
import ResourceLinks from '../component/NavMenus/ResourceLinks';
const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
       }
}))

function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
    <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum} >
            <Link color="inherit" to="/" >
              Home
            </Link>

            <Link
              color="textPrimary"
              to="#"
              aria-current="page">
              Downloads 
            </Link>
            </Breadcrumbs>
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={8}>
          <Paper ><div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">


                  <h3>
                  Ethiopian Standards Agency Documents</h3>
                  <ul class="linkitem">
                        
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/ESA/2012 Final plan list of  standards.docx" onclick="window.open(this.href,'_blank');return false;" title="2012 Final plan list of  standards">2012 Final plan list of  standards</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/ESA/2019 basic and generality team  catalogue.xlsx" onclick="window.open(this.href,'_blank');return false;" title="2019 basic and generality team  catalogue">2019 basic and generality team  catalogue</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/ESA/TC structure  2019.xlsx" onclick="window.open(this.href,'_blank');return false;" title="TC structure  2019">TC structure  2019</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/ESA/Etiopian Standard Project Proposal  form.doc" onclick="window.open(this.href,'_blank');return false;" title="Etiopian Standard Project Proposal  form">Etiopian Standard Project Proposal  form</a>
                                  
                            </li>
                    
                        </ul>

                  <hr />
                  <h4 >
                  Trade & Investment Prclamations</h4>
                  <ul class="linkitem">
                        
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2013 EC/Proclamation &amp; Directives/export-trade-duty-incentive-schemes-proclamation-no-768-2012.pdf" onclick="window.open(this.href,'_blank');return false;" title="Export Trade Duty Incentive Schemes Proclamation-no-768-2012">Export Trade Duty Incentive Schemes Proclamation-no-768-2012</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2013 EC/Proclamation &amp; Directives/Commercial Regisration &amp; License Proclamation 1150-2019.pdf" onclick="window.open(this.href,'_blank');return false;" title="Commercial Regisration &amp; License Proclamation 1150-2019">Commercial Regisration &amp; License Proclamation 1150-2019</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2013 EC/Attachement/Investment_Regulation_No_474_2020.pdf" onclick="window.open(this.href,'_blank');return false;" title="Investment Regulation No.474/2020">Investment Regulation No.474/2020</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/Attachments/New Investment Proclamation No. 1180-2020.pdf" onclick="window.open(this.href,'_blank');return false;" title="Investment Proclamation No. 1180/2020">Investment Proclamation No. 1180/2020</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/INVESTMENT  CODE/INVESTMENT  REGULATIONS NUMBER 84-1995.pdf" onclick="window.open(this.href,'_blank');return false;" title="Council of Ministers Regulations on Investement incentives and Investement Areas Reserved for Domestic Investors">Council of Ministers Regulations on Investement incentives and Investement Areas Reserved for Domestic Investors</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/INVESTMENT  CODE/INVESTMENT PROCLAMATION - AMMENDMENT - NUMBER 375-1996.pdf" onclick="window.open(this.href,'_blank');return false;" title="Investment (Amendment) Proclamation..">Investment (Amendment) Proclamation..</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/INVESTMENT  CODE/INVESTMENT PROCLAMATION NUMBER 280-1994.pdf" onclick="window.open(this.href,'_blank');return false;" title="Investment Proclamation">Investment Proclamation</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/INVESTMENT  CODE/Proc No. 280-2002 Investment.pdf" onclick="window.open(this.href,'_blank');return false;" title="Re-enactment of the investment Proclamation">Re-enactment of the investment Proclamation</a>
                                  
                            </li>
                    
                        </ul>
                        <h4 >
                        TAX & CUSTOMS CODE & PROCLAMATION</h4>
                        <ul class="linkitem">
                        
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2013 EC/Directives/Lifting Tax penality_opt.pdf" onclick="window.open(this.href,'_blank');return false;" title="የታክስ አስተዳደራዊ ቅጣት አነሳስ መመሪያ ቁጥር 137/2010">የታክስ አስተዳደራዊ ቅጣት አነሳስ መመሪያ ቁጥር 137/2010</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2013 EC/Directives/Habt Selemeyaz_opt.pdf" onclick="window.open(this.href,'_blank');return false;" title="የታክስ ከፋዩን ሀብት ስለመያዝና ስለመሸጥ የወጣ መመሪያ ቁጥር 6/2011">የታክስ ከፋዩን ሀብት ስለመያዝና ስለመሸጥ የወጣ መመሪያ ቁጥር 6/2011</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2013 EC/Directives/Excise Tax Directive 2013 E.C.pdf" onclick="window.open(this.href,'_blank');return false;" title="Excise Tax Directive 2013 E.C.">Excise Tax Directive 2013 E.C.</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/New-Directives/በኮቪድ - 19 (ኮሮና ቫይረስ).pdf" title="በኮቪድ - 19 (ኮሮና ቫይረስ) ወቅት ይግባኝ ኮሚሽን የዳኝነት ስራውን በአግባቡ ለመፈፀም እንዲችል የተዘጋጀ የአሰራር ማኑዋል ቁጥር 01/2012">በኮቪድ - 19 (ኮሮና ቫይረስ) ወቅት ይግባኝ ኮሚሽን የዳኝነት ስራውን በአግባቡ ለመፈፀም እንዲችል የተዘጋጀ የአሰራር ማኑዋል ቁጥር 01/2012</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/Attachments/new/Excise-Tax-Proclamation-No.1186-2020.pdf" onclick="window.open(this.href,'_blank');return false;" title="Excise Tax Proclamation No. 1186/2020">Excise Tax Proclamation No. 1186/2020</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/New-Directives/Lifting-penality-of-tax-due-to-COVID19-directive.pdf" onclick="window.open(this.href,'_blank');return false;" title="የኮረና ወረርሽኝ በታክስ ከፋዩ ላይ ያደረሰውን ጉዳት ለመቀነስ የወጣ የታክስ ዕዳ ምህረት መመሪያ ቁጥር 64/2012">የኮረና ወረርሽኝ በታክስ ከፋዩ ላይ ያደረሰውን ጉዳት ለመቀነስ የወጣ የታክስ ዕዳ ምህረት መመሪያ ቁጥር 64/2012</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/New-Directives/በገዢው ተይዞ ሰለሚከፈል የተጨማሪ እሴት ታክስ አፈጻጸም የወጣ  መመሪያ ቁጥር  602012.pdf" onclick="window.open(this.href,'_blank');return false;" title="በገዢው ተይዞ ሰለሚከፈል የተጨማሪ እሴት ታክስ አፈጻጸም የወጣ  መመሪያ ቁጥር  60-2012">በገዢው ተይዞ ሰለሚከፈል የተጨማሪ እሴት ታክስ አፈጻጸም የወጣ  መመሪያ ቁጥር  60-2012</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/New-Directives/ከገቢ ግብር ነጻ የተደረጉ ገቢዎች አፈፃፀም መመሪያ ቁጥር አንድ 2011.PDF.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከገቢ ግብር ነጻ የተደረጉ ገቢዎች አፈፃፀም መመሪያ ቁጥር አንድ 2011">ከገቢ ግብር ነጻ የተደረጉ ገቢዎች አፈፃፀም መመሪያ ቁጥር አንድ 2011</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/New-Directives/የቅድመ ግብር ክፍያ ስርዓት አፈፃፀም መመሪያ ቁጥር ሁለት 2011.PDF.pdf" onclick="window.open(this.href,'_blank');return false;" title="የቅድመ ግብር ክፍያ ስርዓት አፈፃፀም መመሪያ ቁጥር ሁለት 2011">የቅድመ ግብር ክፍያ ስርዓት አፈፃፀም መመሪያ ቁጥር ሁለት 2011</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/New-Directives/የታክስ ከፋይ ምዝገባና ሥረዛ አፈፃፀም መመሪያ ቁጥር ሶሰት 2011.PDF.pdf" onclick="window.open(this.href,'_blank');return false;" title="የታክስ ከፋይ ምዝገባና ሥረዛ አፈፃፀም መመሪያ ቁጥር ሶሰት 2011">የታክስ ከፋይ ምዝገባና ሥረዛ አፈፃፀም መመሪያ ቁጥር ሶሰት 2011</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/New-Directives/የአሰተዳደራዊ ቅጣት አወሳሰን አፈጻጸም መመሪያ .PDF.pdf" onclick="window.open(this.href,'_blank');return false;" title="የአሰተዳደራዊ ቅጣት አወሳሰን አፈጻጸም መመሪያ">የአሰተዳደራዊ ቅጣት አወሳሰን አፈጻጸም መመሪያ</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/New-Directives/የካፒታል ሀብቶችን በማሰተላለፍ በሚገኝ ጥቅም ላይ ሰለሚከፈል ግብር አፈጻጸም የወጣ መመሪያ.pdf" onclick="window.open(this.href,'_blank');return false;" title="የካፒታል ሀብቶችን በማሰተላለፍ በሚገኝ ጥቅም ላይ ሰለሚከፈል ግብር አፈጻጸም የወጣ መመሪያ">የካፒታል ሀብቶችን በማሰተላለፍ በሚገኝ ጥቅም ላይ ሰለሚከፈል ግብር አፈጻጸም የወጣ መመሪያ</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/New-Directives/የግዥ መመሪያ ማሻሻያ.pdf" onclick="window.open(this.href,'_blank');return false;" title="የግዥ መመሪያ ማሻሻያ">የግዥ መመሪያ ማሻሻያ</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/Laws &amp; Directives/166-2012 - ዕቃ በዋስትና ስለሚለቀቅበት ሁኔታ ለመወሰን የወጣ መመሪያ.pdf" onclick="window.open(this.href,'_blank');return false;" title="166-2012 - ዕቃ በዋስትና ስለሚለቀቅበት ሁኔታ ለመወሰን የወጣ መመሪያ">166-2012 - ዕቃ በዋስትና ስለሚለቀቅበት ሁኔታ ለመወሰን የወጣ መመሪያ</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/Laws &amp; Directives/164-2012 - የቱሪስት መገልገያ ዕቃዎች በጊዚያዊነት ወደ ሀገር ውስት ስለሚገቡበት ሁኔታ ለመወሰን የወጣ.pdf" onclick="window.open(this.href,'_blank');return false;" title="164-2012 - የቱሪስት መገልገያ ዕቃዎች በጊዚያዊነት ወደ ሀገር ውስት ስለሚገቡበት ሁኔታ ለመወሰን የወጣ">164-2012 - የቱሪስት መገልገያ ዕቃዎች በጊዚያዊነት ወደ ሀገር ውስት ስለሚገቡበት ሁኔታ ለመወሰን የወጣ</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/Laws &amp; Directives/160-2012 - የጉምሩክ ቀሪ ቀረጥና ታክስ መክፈያ ጊዜ ለመወሰን የወጣ መመሪያ .pdf" onclick="window.open(this.href,'_blank');return false;" title="160-2012 - የጉምሩክ ቀሪ ቀረጥና ታክስ መክፈያ ጊዜ ለመወሰን የወጣ መመሪያ ">160-2012 - የጉምሩክ ቀሪ ቀረጥና ታክስ መክፈያ ጊዜ ለመወሰን የወጣ መመሪያ </a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/Laws &amp; Directives/158-2011 - የጉምሩክ ቀረጥና ታክስ ማስከፈያ ዋጋ አተማመን ማብራሪያ ስለመስጠት.pdf" onclick="window.open(this.href,'_blank');return false;" title="158-2011 - የጉምሩክ ቀረጥና ታክስ ማስከፈያ ዋጋ አተማመን ማብራሪያ ስለመስጠት">158-2011 - የጉምሩክ ቀረጥና ታክስ ማስከፈያ ዋጋ አተማመን ማብራሪያ ስለመስጠት</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/Laws &amp; Directives/158-2011 - የጉምሩክ ቀረጥና ታክስ ማስከፈያ ዋጋ አተማመን መመሪያ.pdf" onclick="window.open(this.href,'_blank');return false;" title="158-2011 - የጉምሩክ ቀረጥና ታክስ ማስከፈያ ዋጋ አተማመን መመሪያ">158-2011 - የጉምሩክ ቀረጥና ታክስ ማስከፈያ ዋጋ አተማመን መመሪያ</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/Laws &amp; Directives/157-2011 - ዕቃዎች በጊዜያዊነት ወደ ውጪ አገር ወጥተው የሚመለሱበትን የጉምሩክ ስነ-ስርዓት አፈፃፀም  ሁኔታ ለመወሰን የወጣ መመሪያ.pdf" onclick="window.open(this.href,'_blank');return false;" title="157-2011 - ዕቃዎች በጊዜያዊነት ወደ ውጪ አገር ወጥተው የሚመለሱበትን የጉምሩክ ስነ-ስርዓት አፈፃፀም  ሁኔታ ለመወሰን የወጣ መመሪያ">157-2011 - ዕቃዎች በጊዜያዊነት ወደ ውጪ አገር ወጥተው የሚመለሱበትን የጉምሩክ ስነ-ስርዓት አፈፃፀም  ሁኔታ ለመወሰን የወጣ መመሪያ</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/Laws &amp; Directives/156-2011 - በፖስታ አገልግሎት ድርጅቶች  በኩል ወደ አገር ለሚገቡና  ከአገር ለሚወጡ  ዕቃዎች ተፈፃሚ የሚሆን የጉምሩክ ሥነ-ሥርዓት መመሪያ.pdf" onclick="window.open(this.href,'_blank');return false;" title="156-2011 - በፖስታ አገልግሎት ድርጅቶች  በኩል ወደ አገር ለሚገቡና  ከአገር ለሚወጡ  ዕቃዎች ተፈፃሚ የሚሆን የጉምሩክ ሥነ-ሥርዓት መመሪያ">156-2011 - በፖስታ አገልግሎት ድርጅቶች  በኩል ወደ አገር ለሚገቡና  ከአገር ለሚወጡ  ዕቃዎች ተፈፃሚ የሚሆን የጉምሩክ ሥነ-ሥርዓት መመሪያ</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/Laws &amp; Directives/155-2011 - የጉምሩክ ሥነ-ሥርዓት የኤሌክትሮኒክስ መረጃ ልውውጥ አጠቃቀም መመሪያ.pdf" onclick="window.open(this.href,'_blank');return false;" title="155-2011 - የጉምሩክ ሥነ-ሥርዓት የኤሌክትሮኒክስ መረጃ ልውውጥ አጠቃቀም መመሪያ">155-2011 - የጉምሩክ ሥነ-ሥርዓት የኤሌክትሮኒክስ መረጃ ልውውጥ አጠቃቀም መመሪያ</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/Laws &amp; Directives/154-2011 - የጉምሩክ መጋዘን አስተዳደር መመሪያ.pdf" onclick="window.open(this.href,'_blank');return false;" title="154-2011 - የጉምሩክ መጋዘን አስተዳደር መመሪያ">154-2011 - የጉምሩክ መጋዘን አስተዳደር መመሪያ</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/Laws &amp; Directives/153-2011 - የጉምሩክ አስተላላፊነት የሙያና ብቃት ማረጋገጫ አሰጣጥ እና በአስተላላፊዎች ጥፋት ላይ ስለሚወሰድ አስተዳደራዊ እርምጃ ለመወሰን የወጣ መመሪያ.pdf" onclick="window.open(this.href,'_blank');return false;" title="153-2011 - የጉምሩክ አስተላላፊነት የሙያና ብቃት ማረጋገጫ አሰጣጥ እና በአስተላላፊዎች ጥፋት ላይ ስለሚወሰድ አስተዳደራዊ እርምጃ ለመወሰን የወጣ መመሪያ">153-2011 - የጉምሩክ አስተላላፊነት የሙያና ብቃት ማረጋገጫ አሰጣጥ እና በአስተላላፊዎች ጥፋት ላይ ስለሚወሰድ አስተዳደራዊ እርምጃ ለመወሰን የወጣ መመሪያ</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/Laws &amp; Directives/60-2012 - በገዥው ተይዞ ስለሚከፈል የተጨማሪ እሴት ታክስ አፈፃፀም የወጣ መመሪያ ቁጥር 60-2012..pdf" onclick="window.open(this.href,'_blank');return false;" title="60-2012 - በገዥው ተይዞ ስለሚከፈል የተጨማሪ እሴት ታክስ አፈፃፀም የወጣ መመሪያ.">60-2012 - በገዥው ተይዞ ስለሚከፈል የተጨማሪ እሴት ታክስ አፈፃፀም የወጣ መመሪያ.</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/Directives/4-2011 – የታክስ ወኪልት ፈቃድ አሰጣጥን  ለመወሰን  የወጣ መመሪያ (የገንዘብ ሚኒስቴር).pdf" onclick="window.open(this.href,'_blank');return false;" title="የታክስ ወኪልት ፈቃድ አሰጣጥን  ለመወሰን  የወጣ መመሪያ (የገንዘብ ሚኒስቴር)">የታክስ ወኪልት ፈቃድ አሰጣጥን  ለመወሰን  የወጣ መመሪያ (የገንዘብ ሚኒስቴር)</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/Directives/3-2011 – የታክስ ከፋይ ምዝገባ እና ሥረዛ የአፈፃፀም መመሪያ (የገንዘብ ሚኒስቴር).pdf" onclick="window.open(this.href,'_blank');return false;" title="የታክስ ከፋይ ምዝገባ እና ሥረዛ የአፈፃፀም መመሪያ (የገንዘብ ሚኒስቴር)">የታክስ ከፋይ ምዝገባ እና ሥረዛ የአፈፃፀም መመሪያ (የገንዘብ ሚኒስቴር)</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/Directives/6-2011 – የታክስ ከፋዩን ሃብት ስለመያዝና ስለመሸጥ የወጣ መመሪያ (የገንዘብ ሚኒስቴር).pdf" onclick="window.open(this.href,'_blank');return false;" title="የታክስ ከፋዩን ሃብት ስለመያዝና ስለመሸጥ የወጣ መመሪያ (የገንዘብ ሚኒስቴር)">የታክስ ከፋዩን ሃብት ስለመያዝና ስለመሸጥ የወጣ መመሪያ (የገንዘብ ሚኒስቴር)</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/Directives/3-2011 /Data/Sites/1/downloadables/Directives/3-2011 – የታክስ ከፋይ ምዝገባ እና ሥረዛ የአፈፃፀም መመሪያ (የገንዘብ ሚኒስቴር).pdf" onclick="window.open(this.href,'_blank');return false;" title="ከገቢ ግብር ነፃ የተደረጉ ገቢዎች አፈፃጸም መመሪያ (የገንዘብ ሚኒስቴር)">ከገቢ ግብር ነፃ የተደረጉ ገቢዎች አፈፃጸም መመሪያ (የገንዘብ ሚኒስቴር)</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/Directives/5-2011 – ስለ ተቀናሽ ወጪዎች  የወጣ መመሪያ (የገንዘብ ሚኒስቴር).pdf" onclick="window.open(this.href,'_blank');return false;" title="ስለ ተቀናሽ ወጪዎች  የወጣ መመሪያ (የገንዘብ ሚኒስቴር)">ስለ ተቀናሽ ወጪዎች  የወጣ መመሪያ (የገንዘብ ሚኒስቴር)</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/Directives/2-2011 - የቅድመ ግብር ክፍያ ሥርዓት አፈፃፀም መመሪያ (የገንዘብ ሚኒስቴር).pdf" onclick="window.open(this.href,'_blank');return false;" title="የቅድመ ግብር ክፍያ ሥርዓት አፈፃፀም መመሪያ (የገንዘብ ሚኒስቴር)">የቅድመ ግብር ክፍያ ሥርዓት አፈፃፀም መመሪያ (የገንዘብ ሚኒስቴር)</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/tax laws/CUSTOMS PROCLAMATION.pdf" onclick="window.open(this.href,'_blank');return false;" title="Customs Proclamation">Customs Proclamation</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/tax laws/EXCISE TAX (AMENDMENT) PROCLAMATION.pdf" title="Excise Tax (Amendment) Proclamation">Excise Tax (Amendment) Proclamation</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/tax laws/EXCISE TAX PROCLAMATION 3072002.pdf" onclick="window.open(this.href,'_blank');return false;" title="Excise Tax Proclamation ">Excise Tax Proclamation </a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/tax laws/EXPORT TRADE DUTY INCENTIVE SCHEME ESTABLISHING PROCLAMATION.pdf" onclick="window.open(this.href,'_blank');return false;" title="Export Trade Duty Incentive Scheme Establishing Proclamation">Export Trade Duty Incentive Scheme Establishing Proclamation</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/tax laws/INCOME TAX PROCLAMATION 2862002.pdf" onclick="window.open(this.href,'_blank');return false;" title="Income Tax Proclamation ">Income Tax Proclamation </a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/tax laws/PROVIDE FOR THE PAYMENT OF STAMP DUTY PROCLAMATION.pdf" onclick="window.open(this.href,'_blank');return false;" title="Proclamation To Provide For The Payment Of Stamp Duty">Proclamation To Provide For The Payment Of Stamp Duty</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/REGULATIONS/COUNCIL OF MINISTERS REGULATIONS TO PROVIDE FOR THE IMPLEMEN.pdf" onclick="window.open(this.href,'_blank');return false;" title="Regulation To Provide For The Implementation Of Cooperative Societies Proclamation">Regulation To Provide For The Implementation Of Cooperative Societies Proclamation</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/REGULATIONS/COUNCIL OF MINISTERS RGULATIONS ON EXPORT PRIZE AWARDS.pdf" onclick="window.open(this.href,'_blank');return false;" title="Regulations On Export Prize Awards">Regulations On Export Prize Awards</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/REGULATIONS/CUSTOMS CLEARING AGENTS COUNCIL OF MINISTERS REGULATIONS.pdf" onclick="window.open(this.href,'_blank');return false;" title="Regulations To Customs Clearing Agents">Regulations To Customs Clearing Agents</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/tax laws/TAX WITHHOLDING SCHEME APPLICATION COUNCIL REGULARTION.pdf" onclick="window.open(this.href,'_blank');return false;" title="Regulations To Provide For The Application Of Tax Withholding Scheme">Regulations To Provide For The Application Of Tax Withholding Scheme</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/REGULATIONS/COUNCIL OF MINISTERS REGULATIONS ON THE ISSUANCE FRIEGHT FRE.pdf" onclick="window.open(this.href,'_blank');return false;" title="Regulations To Provide For The Issuance Of Freight Forwarding And Shipping Agency License">Regulations To Provide For The Issuance Of Freight Forwarding And Shipping Agency License</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/tax laws/IMPORT SUR-TAX COUNCIL OF MINISTERS REGULATIONS.pdf" onclick="window.open(this.href,'_blank');return false;" title="Regulations To Provide For The Payment Of Sur-Tax On Import Of Goods">Regulations To Provide For The Payment Of Sur-Tax On Import Of Goods</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/tax laws/TURNOVER TAX PROCLAMATION 3082002.pdf" onclick="window.open(this.href,'_blank');return false;" title="Turnover Tax Proclamation ">Turnover Tax Proclamation </a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/tax laws/VALUE ADDED TAX PROCLAMATION.pdf" onclick="window.open(this.href,'_blank');return false;" title="Value Added Tax Proclamation">Value Added Tax Proclamation</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/downloadables/Customs Proclamation No. 859-2014-7.pdf" onclick="window.open(this.href,'_blank');return false;" title="Customs Proclamation No. 859-2014">Customs Proclamation No. 859-2014</a>
                            
                            </li>
                    
                        </ul>
                </div>

              </div>
            </div>
          </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          {/* <Paper > */}
          {/* <h5 class="text-uppercase">Quick Links</h5>

<ul class="list-unstyled mb-0">
 
  <li>
    <a href="#!" class="text-green"> Organizational Structure</a>
  </li>
    <li>
    <a href="#!" class="text-green">  General Assembly</a>
  </li>
    <li>
    <a href="#!" class="text-green">  Board of Directors</a>
  </li>
    <li>
    <a href="#!" class="text-green">  The Secretariat</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Achievements</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Former Presidents & Secretary G.</a>
  </li>
    <li>
    <a href="#!" class="text-green">Our Location</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Our Partners</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Strategic Plan</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Chamber Law (+Directives)</a>
  </li>
    <li>
    <a href="#!" class="text-green"> ECCSA Projects</a>
  </li>


</ul> */}
          <ResourceLinks />

          {/* </Paper> */}
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
