import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';
import { classExpression } from '@babel/types';
import HomeSlider from '../component/slider/HomeSlider';
import MainMenu from '../component/NavMenus/MainMenu';

import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { CardMedia, Card } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import 'bootstrap/dist/css/bootstrap.css';
import Welcome from '../component/Welcome/Welcome';
import News from '../component/News/Blog';
import Footer from '../component/Footer/Footer';
// import News from '../component/News/News';

const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  }
}))

function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
      {/* <img            
               
               className="d-block w-70"
                class="img-fluid"
                src="../images/header.jpg" 
                href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a>
   <MainMenu/> */}

      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
          {/* <MainMenu/> */}
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={12}>
          <Grid item xs={12} sm={6} md={12}>
            <Card className={classes.card}>

              <Typography gutterBottom variant="h6" className={classes.title}>
                ECCSA-CCA: Bilateral Talk on Business Collaboration         </Typography>
              <CardMedia
                className={classes.cardMedia}
                src="http://ethiopianchamber.com/Data/Sites/1/2012%20EC/images/I6.jpg"
                title="Image title"
                maxWidth="90%"
              />
              <CardContent className={classes.cardContent}>

                <Typography class="body">
                  <p>
                    <img src="http://ethiopianchamber.com/Data/Sites/1/2012%20EC/images/cca.jpg" width="75%" />
                  </p><p>The Ethiopian Chamber of Commerce and Sectoral Associations (ECCSA) and Corporate Council on Africa (CCA) forwarded their respective interest of working together in business sector</p><p>This huge and remarkable event was organized by Indian ITME Society in partnership with the Ethiopian Chamber of Commerce and Sectoral Associations (ECCSA) under the theme: “Prosperity for Africa through Textile Technology” with view to initiate a revolution in technology up gradation entrepreneurship and investments in textiles across the region and beyond. It was also reported that the event anticipates being instrumental in establishing Africa’s textile footprints prominently across the globe.</p><p>According to his statement, Ethiopia warmly invites FDI to accelerate the ongoing economic growth seen over the last years and the cooperation of the two businesses is vital.

                  </p><p>  ECCSA and the CCA exchanged their ideas during the Council’s visit to the Chamber on 10 February 2020.

                    Melaku Ezezew (Eng.), President, Yesuf Ademnur, Secretary General (SG) and Wubie Mengistu, D/Secretary General (DSG) of ECCSA received Florizelle Liser, President and CEO of CCA and her colleague, Asfaw Alemayehu, Managing Director of GAZEBO INTERNATIONAL at SG’s office.</p><p> Due to low productivity, limited quality awareness and ineffective and inefficient management structures and limitations of skills training, Ethiopian textile is said still not in a position to compete in the international market, said the President. This exhibition is hopefully expected to bring about a better experience and knowledge from the various cultures that can fill the gap, he added
                  </p><p> Speaking about ECCSA’s duties, Yesuf stated the Chamber’s responsibility of carrying out
                    the activity of advocacy representing the private sector, providing capacity building and tra
                    de and investment promotion activities. ECCSA and CCA can create partnership with view of addre
                    ssing these issues, he recommended.

                    Liser on her part said, CCA is the leading U.S. business assoc
                    iation focused solely on connecting business interests between th
                    e United States and Africa and wants to strengthen the existing re
                    lation between the US and Africa in trade and investment.</p><p>    According to Liser, CCA works in the energy, banking, IT, health sector and financial services and other various sectors and it is her Council’s interest to collaborate with the Chamber so that their respective member companies would work in partnership in those areas for their mutual benefit.

                      CCA also provides, said the CEO, access the private sector to African governments, connections between the private sector through the hosting of events and insights by preparing newsletters that the US and African businesses need in order to be successful to invest in the continent. All the events hosted by CCA bring together government and private sector in US and Africa because they are key stakeholders in supporting trade and investment, she added.

                      CCA will organize business summit in June this year in Marrakesh, Morocco and the Council would love to collaborate with the Chamber to bring businesses to join about 2000 US and African businesses and senior government officials, the CEO said.

                      The CCA has also plan of bringing trade mission late this year with view to create partnership between their respective businesses and of hosting events focusing on investing in Ethiopia, according to the CEO.

                      Warmly welcoming the interest of CCA, ECCSA president also underlined the need to collaborate and networking. Melaku explained the interest on the side of Ethiopian private sector to cooperate with the US’s as they have a lot to give especially in technology transfer.

                      Ethiopia has comparative advantage in agriculture and livestock but needs technology and knowledge and the collaboration is a good opportunity for Ethiopian private sector, said the president

                      Finally they talked about updating the MoU they have signed during the summit in 2016.          </p>               </Typography>
              </CardContent>

            </Card>

          </Grid>


        </Grid>

        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
