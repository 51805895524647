import { React, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
//   import { Button } from "@material-ui/core";
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import './MainMenu.css';
import '../../App.css'
import { makeStyles } from '@material-ui/core/styles';
//   import Home from './Home';
//   import AboutUs from './AboutUs';
import 'bootstrap/dist/css/bootstrap.min.css';
const useStyles = makeStyles((theme) => ({

  main: {
    width: '100%',
    background: '#198754',
    fontWeight: '600',
    fontStyle: 'normal',

    row: '10px',
    //   textAlign:'left',
    color: '#ffffff',
    alignContent: 'center',
    padding: '10px',


    // elevation:'0',
  },
  margin: {
    marginLeft: '200px',
    marginTop: '5px',
    marginBottom: '0px'
    // color:'primary',
    // float: 'right',
  }

}))

export default function Album() {
  const classes = useStyles();
  const [active, setActive] = useState('default');
  // const classes=useStyle();
  // render(){
  return (
    <div class="mmenu">
      <img
        // className="d-block w-70"
        // class="img-fluid"
        src="../images/header.jpg"
        href="/"></img>
      <Link eventKey="login" class="btn btn-outline-success" to="/login" > Login/Register</Link>
      <Navbar collapseOnSelect className={classes.main} sticky="top" expand="lg" class="dark2" >

        <Container>
          {/* <div > */}

          {/* </div> */}
          <Navbar.Brand href="/">
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"
              activeKey={active}
              onSelect={(selectedKey) => setActive(selectedKey)}
            >
              <Nav.Link as={Link} to="/"
                eventKey="default">Home</Nav.Link >
              <NavDropdown title="About Us" href="/about"
                id="basic-nav-dropdown" eventKey="about">
                <NavDropdown.Item as={Link} eventKey="about" to="/about">About the Chamber</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="assembly" to="/assembly" eventKey="assembly">General Assembly </NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="assembly" to="/board" eventKey="board">Board of Directors</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="team" to="/team" eventKey="team"> The Secretariat</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="former" to="/former">Former Presidents & Secretary G.</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="location" to="/location">Our Location</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="partners" to="/partners">Our Partners</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="splan" to="/splan">Strategic Plan</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="chamberlaw" to="/chamberlaw">Chamber Law (+Directives)</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="projects" to="/projects">ECCSA Projects</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown href="./services" title="Services" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} eventKey="Services" to="/trade-investment" >Trade & Investment Promotion</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="capacity" to="/capacity-building">Capacity Building</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="research" to="/research-advocacy">Research & Advocacy</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="arbitration" to="/arbitration">Arbitration </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/membership" class="navitems" eventKey="membership">Membership</Nav.Link>
              <Nav.Link as={Link} to="/events" class="navitems" eventKey="events">Events</Nav.Link>
              <Nav.Link as={Link} class="navitems" to="/news" eventKey="news">News </Nav.Link>
              {/* <Nav.Link class="navitems" href="/news">Work with us </Nav.Link> */}
              <NavDropdown href="./services" title="Resources" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} eventKey="eresources1" to="/eresources" >e-Resources</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="eresources2" to="/eresources1">Publications at RC</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="eresources3" to="/eresources2">E-Newsletter Download</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="eresources4" to="/eresources3">Important Links</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="eresources5" to="/eresources4">Downloads</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="eresources6" to="/eresources5">Publications for Sale</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown href="./services" title="Opportunities" id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} eventKey="investment" to="/Opportunities" >Business To Business</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="training" to="/training">Training </NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="bopportunity" to="/bopportunity">Business Opportunities</NavDropdown.Item>
                <NavDropdown.Item as={Link} eventKey="tradefair" to="/tradefair">Exhibition</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link as={Link} to="/aboutet" eventKey="aboutet">About Ethiopia </Nav.Link>
              <Nav.Link as={Link} to="/contact" eventKey="contact">Contact Us</Nav.Link>
            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

// export default MainMenu;