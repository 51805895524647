// import { Icon } from '@material-ui/core/HomeIcon';
import ThreeDRotation from '@material-ui/icons/ThreeDRotation';
import BookmarkOutlinedIcon from '@material-ui/icons/BookmarkOutlined';
import React, { Component } from 'react';
// import { Breadcrumb,Nav, Container, Carousel, Card, Row, Col, Form } from "react-bootstrap";
import './Welcome.css'
<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
class Welcome extends Component {
    render(){
       return(
<div class="row">
  <div class="col-sm-8">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title"><BookmarkOutlinedIcon />Numbers </h3>
        <p class="card-text">The concept of Chamber of Commerce was introduced to Ethiopia for the first time inhe need for its establishment basically emanated from the economic crisis occurred during that time. The establishment of the Chamber of Commerce was seen as a solution to address the distribution of scarce commodities such as cotton, yarn, and woolen products. Members of the Chamber of Commerce were allowed to distribute those scarce commodities to stabilize the market.</p>
      <br />
        <a href="/about" class="btn btn-success">Read More </a>
      </div>
    </div>
  </div>
  <div class="col-sm-4">
    <div class="card">
      <div class="card-body">
          <div class="logo">
        <img src="./images/logo.jpg"></img>
      </div></div>
    </div>
  </div>
</div>

       )
    }
}
export default Welcome