import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';

import ChambersystemPage from './chambersystem';
import ChamberstructurePage from './chamberstructure';

import 'bootstrap/dist/css/bootstrap.css';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
       }
}))

function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum} >
                <Link color="inherit" to="/" >
              Home
            </Link>

            <Link
              color="textPrimary"
              to="#"
              
              aria-current="page">
              Membership
            </Link>
            </Breadcrumbs>
      <ChambersystemPage />

      <ChamberstructurePage />

      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
          {/* <MainMenu/> */}
        </Grid>


      </Grid>


    </div>
  );
}

export default App;
