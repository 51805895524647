import React from 'react'

  import './MainMenu.css'
import { Link } from 'react-router-dom';

 import { Card } from "react-bootstrap";



class ResourceLinks extends React.Component{

    render(){
        return(
          
         
          <Card text="white" style={{ width: '18rem' }}>
          <Card.Header>Resources Links</Card.Header>
          <Card.Body>
            <Card.Text>
         <li>
             <a href="#!" class="text-green"> </a>
            < Link  eventKey="team" to="/eresources" eventKey="eresources"> e-Resources </Link>
             </li>
              <li>
              < Link  eventKey="team" to="/eresources1" eventKey="eresources1"> Publications at RC </Link>
            </li>
            <li>
            < Link  eventKey="team" to="/eresources2" eventKey="eresources2">E-Newsletter Download </Link>
            </li>
              <li>
              < Link  eventKey="team" to="/eresources3" eventKey="eresources3"> Important Links </Link>
           </li>
               <li>
               < Link  eventKey="team" to="/eresources4" eventKey="eresources4"> Downloads </Link>
      </li>
      <li>
               < Link  eventKey="team" to="/eresources5" eventKey="eresources5"> Publications for Sale </Link>
      </li>
     

        
            </Card.Text>
          </Card.Body>
        </Card>
    
        )  
    }
}

export default ResourceLinks;