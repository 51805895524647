import React, { useState } from "react";
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid,Paper,Button } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import { Breadcrumb,Nav, Navbar,NavItem, NavDropdown, Container,form, Carousel, Card, Row, Col, Form } from "react-bootstrap";
// import { Dropdown, MenuItem, DropdownButton } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import { BrowserRouter as Router, Route, Swithch, Link, Redirect } from 'react-router-dom';
 import '../../App.css';

import { classExpression } from '@babel/types';
import MainMenu from '../../component/NavMenus/MainMenu'; 

import 'bootstrap/dist/css/bootstrap.css';


import Partners2 from '../../component/Teams/Partners';

import Footer from '../../component/Footer/Footer';

const useStyle =makeStyles((theme)=>({
  grid:{
    width:'100%',
    margin:'15px',
    padding:'1px',
    row:'20px',
      background:'#fff',
  },
  main:{
    width:'100%',
    background:'#fff',
    padding:'10px',
  

  },
  paper:{ 
    // padding:theme.spacing(1),
    textAlign:'left',
    color: '#198754',
    background:'#aaf',
    padding:'10px',
    
    // elevation:'0',
  },
  
  
  appBar:{
    color: '#000',
    background:'#fff',
  },
  margin:{
    marginLeft:'100px',
    marginTop:'5px',
    color:'primary',
    
  }


}))

export default function Membership2() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const classes=useStyle();
  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    // event.preventDefault();
    event.target('/about');
  }
  return (
    <div className={classes.main} >
     {/* <Topbar/> */}
     
     <Grid container spacing={2} className={classes.grid} className="grid">
     <Grid item xs={12} md={12}>
{/* <MainMenu/> */}
       </Grid>
       
        
        
        <Grid item xs={3} md={3} className={classes.counter}>
          </Grid>
          <Grid item xs={6} md={6} className={classes.counter}>
        <div class="row">
    <div class="bg">Become a Member Step 2 </div>
 </div> 
 <Form onSubmit={handleSubmit}>
          <Row className="g-2">
        <Col md>
  <Form.Label> Registration Fee</Form.Label>
  <Form.Control plaintext readOnly defaultValue="readOnly text" />
    
  <Form.Label> Annual Fee  </Form.Label>
          <Form.Control type="text" />
     </Col>
  
</Row>
        <Row className="g-2">
        <Col md>
  <Form.Label> Invoice No</Form.Label>
  
  <Form.Control type="email" />
    
  </Col>
  <Col md>
  <Form.Label> Receipt No </Form.Label>
          <Form.Control type="text" />
     </Col>
  
</Row>
<Row className="g-2">
      
  
     <Col md>
  <Form.Label> I want to be Member of </Form.Label>
  
      <Form.Select aria-label="Floating label select example">
        
        <option value="1">Cash</option>
        <option value="2">Bank Transfer</option>
        <option value="3">Mobile Money</option>
        
      </Form.Select>
      
          <Form.Label>Attachments</Form.Label>
          <Form.Control
            autoFocus
            type="file"
            // value={name}
            onChange={(e) => setEmail(e.target.value)}
          />
       
  </Col>
  
</Row>   
<a href="/users" class="btn btn-success">Next</a>
      <br />
      </Form>
        </Grid> 
        <Grid item xs={3} md={3} className={classes.counter}>
          </Grid>

        <br/><br/>
        <Grid item xs={12} md={12}>
         
          <Footer />
        </Grid>
     </Grid>
    </div>
  );
}

// export default App;
