import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import './Pages.css';
import '../component/slider/HomeSlider';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.css';

import Footer from '../component/Footer/Footer';

const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
       }
}))
function handleClick(event) {
  event.preventDefault();

}
function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
      {/* <img            
               
               className="d-block w-70"
                class="img-fluid"
                src="../images/header.jpg" 
                href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a>
   <MainMenu/> */}


      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
		<Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum} >
            <Link color="inherit" to="/" >
              Home
            </Link>

            <Link
              color="textPrimary"
              to="#"
              aria-current="page">
             Business to Busuness 
            </Link>
            </Breadcrumbs>
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={10}>

        <h3>Business Opportunities</h3>
        <p>
																	UNICEF (Ethiopia) wishes to request eligible bidders to participate in a Request for proposal (LRPS) to Institutional Consultancy to an assessment of the 6,000 Solar Direct Drive (SDD) cold chain equipment Project in Ethiopia.</p>
																<p>
																	Details of the requirements for this bid and eligibility criteria etc. can be found in the bidding document. &nbsp;Interested and eligible bidders can get the bidding document with the links: <a href="https://2merkato.com/images/downloads/UNICEF/LRPS-2021-9169583.pdf">https://2merkato.com/images/downloads/UNICEF/LRPS-2021-9169583.pdf</a></p>
																<p>
																	Any query or clarification with regard to this bid shall be send through an email to <a href="mailto:supplyaddisababa@unicef.org">supplyaddisababa@unicef.org</a> before or on 15 September 2021.</p>
																<p>
																	The due date for submission of proposals/Bid to UNICEF Addis Ababa Office, is on or before 2:00PM standard time (East African Time) 22 September 2021.&nbsp; Please read the LRPS for detailed requirements and due dates.</p>
																<p>
																	<strong>ADDRESS: </strong>UNICEF Ethiopia, UNECA Compound, Zambezi Building, 2nd floor Supply Section,&nbsp;Addis Ababa, Ethiopia OR Email - <a href="mailto:eth-supplytenderbox@unicef.org">eth-supplytenderbox@unicef.org</a></p>
																<hr />
																<p>
																	<strong>Indian company known as </strong>BEML Limited is a leading multi-technology and multi-location company under the Ministry of Defense, Government of India, has expressed interest to offer high-quality products for diverse sectors of the economy such as coal, mining, steel, cement, power, irrigation, construction, road building, aviation, defense, metro and railways.&nbsp;</p>
																<ol>
																	<li>
																		<strong>Mining &amp; Construction Business</strong></li>
																</ol>
																<p>
																	BEML offers a comprehensive &amp; diverse range of mining machinery for&nbsp;opencast mines, construction and infrastructure projects. The various products manufactured by BEML are as follows:</p>
																<p>
																	<strong>a) Open Cast Mining:</strong></p>
                                  <table class="table table-hover">
																	<tbody>
																		<tr>
																			<td>
																				
																					Dozers
																			</td>
																			<td>
																				
																					Pipe Layers
																			</td>
																			<td>
																				
																					Excavators
																			</td>
																		</tr>
																		<tr>
																			<td>
																				
																					Wheel Dozers
																			</td>
																			<td>
																			
																					Loaders
																			</td>
																			<td>
																			
																					Water sprinklers
																			</td>
																		</tr>
																		<tr>
																			<td>
																				
																					Dump trucks
																			</td>
																			<td>
																				
																					Motor graders
																			</td>
																			<td>
																			
																					Tyre Handlers
																			</td>
																		</tr>
																</tbody>
																</table>
																<p>
																	<strong>b)&nbsp;Underground Mining:</strong></p>
                                  <table class="table table-hover">
																	<tbody>
																
																		<tr>
																			<td>
																				
																					Load Haul Dumpers
																			</td>
																			<td>
																				
																					Side discharge loaders
																			</td>
																			<td>
																			
																					Granby cars
																			</td>
																			<td>
																			
																					Mine cruiser
																			</td>
																		</tr>
																</tbody>
																</table>
																<p>
																	<strong>c)&nbsp;Construction Equipment:</strong></p>
                                  <table class="table table-hover">
																	<tbody>
																		<tr>
																			<td>
																				<p>
																					Dozers</p>
																			</td>
																			<td>
																				<p>
																					Excavators</p>
																			</td>
																			<td>
																				<p>
																					Motor graders</p>
																			</td>
																			<td>
																				<p>
																					Backhoe Loaders</p>
																			</td>
																			<td>
																				<p>
																					Front end loaders</p>
																			</td>
																		</tr>
																	</tbody>
																</table>
																<p>
																	<strong>2.&nbsp;Defence &amp; Aerospace Business</strong></p>
																<p>
																	BEML keeps Indian Army and other Defence Forces abreast with state-of-the-art Military equipment &amp; plays a major role in the country's Integrated Guided Missile Development Project by supplying Ground Support Vehicles. BEML has also forayed into aerospace business by manufacturing and supplying aggregates for Akash missiles.</p>
																<p>
																	BEML product offerings in the Defence &amp; Aerospace include:</p>
																<p>
																	<strong>a)&nbsp;Defence:</strong></p>
                                  <table class="table table-hover">
																	<tbody>
																		<tr>
																			<td>
																				<p>
																					Armoured Recovery &amp; Repair Vehicle</p>
																			</td>
																			<td >
																				<p>
																					Heavy-duty terrain trucks</p>
																			</td>
																			<td >
																				<p>
																					Pontoon Mainstream Bridge Systems</p>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<p>
																					Armoured Recovery Vehicle</p>
																			</td>
																			<td >
																				<p>
																					Mobile Mast Vehicle</p>
																			</td>
																			<td >
																				<p>
																					Tank Transportation Trailers</p>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<p>
																					Heavy Recovery Vehicle</p>
																			</td>
																			<td >
																				<p>
																					Weapon loading equipment</p>
																			</td>
																			<td >
																				<p>
																					Field Artillery Tractor</p>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<p>
																					Surface Mine Clearance System</p>
																			</td>
																			<td >
																				<p>
																					Military Rail Coach &amp; Wagon</p>
																			</td>
																			<td >
																				<p>
																					Engineering Mine Ploughs</p>
																			</td>
																		</tr>
																	</tbody>
																</table>
																<p>
																	<strong>b)&nbsp;Aerospace:</strong></p>
                                  <table class="table table-hover">
																	<tbody>
																		<tr>
																			<td>
																				<p>
																					Aircraft Towing Tractor</p>
																			</td>
																			<td>
																				<p>
																					Crash Fire Tenders</p>
																			</td>
																			<td>
																				<p>
																					Transponder Landing System</p>
																			</td>
																		</tr>
																	</tbody>
																</table>
																<p>
																	<strong>3.&nbsp;Railway &amp; Metro Business</strong></p>
																<p>
																	BEML is India’s first rail coach manufacturing company and the only Indian company which has forayed into high-tech metro trains deployed for intra-city commuting.</p>
																<p>
																	The Railway products manufactured by BEML are as follows</p>
                                  <table class="table table-hover">
																	<tbody>
																		<tr>
																			<td>
																				<p>
																					Overhead Inspection Cars</p>
																			</td>
																			<td >
																				<p>
																					Postal Vans</p>
																			</td>
																			<td >
																				<p>
																					Treasury Vans</p>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<p>
																					Utility Track Vehicles</p>
																			</td>
																			<td >
																				<p>
																					Track Laying Equipment</p>
																			</td>
																			<td >
																				<p>
																					Spoil Disposal Units</p>
																			</td>
																		</tr>
																		<tr>
																			<td>
																				<p>
																					AC/DC Electric Multiple Units Stainless-Steel EMUs</p>
																			</td>
																			<td >
																				<p>
																					&nbsp;Wagons</p>
																			</td>
																			<td >
																				<p>
																					&nbsp;</p>
																			</td>
																		</tr>
																	</tbody>
																</table>
																<p>
																	For further details you may like to contact Mr. Ajeet Pratap Srivastava, Head of International Business Division Tel: +91- 9820161804, E-mail: <a href="mailto:export1@beml.co.in" target="_blank">export1@beml.co.in</a>,&nbsp;&nbsp; Website: <a href="http://www.beml.co.in" target="_blank">www.beml.co.in</a>.</p>
																<hr />
																<p>
																	UNICEF (Ethiopia) wishes to request eligible bidders to participate in a Request for proposal (LRPS) Providing of Photo copy, Printing service, Spiral binding, Laminating and document re organization services to UNICEF Ethiopia Office. Interested and eligible bidders can get the bidding document with the links: <a href="https://2merkato.com/images/downloads/UNICEF/LITB-2021-9169001.pdf">https://2merkato.com/images/downloads/UNICEF/LITB-2021-9169001.pdf</a>.</p>
																<p>
																	Any query or clarification with regard to this bid shall be send through an email to <a href="mailto:supplyaddisababa@unicef.org">supplyaddisababa@unicef.org</a> before or on 5<sup>th</sup> September &nbsp;2021.</p>
																<p>
																	The due date for submission of proposals/Bid to UNICEF Addis Ababa Office, is on or before 2:00PM standard time (East African Time) 8<sup>th</sup> September 2021.&nbsp; Please read the LRPS for detailed requirements and due dates.</p>
																<p>
																	<strong>ADDRESS:&nbsp; </strong>UNICEF Ethiopia, UNECA Compound, Zambezi Building, 2nd floor Supply Section,&nbsp;Addis Ababa, Ethiopia OR Email - <a href="mailto:eth-supplytenderbox@unicef.org">eth-supplytenderbox@unicef.org</a>.</p>
																<hr />
																<p>
																	UNICEF (Ethiopia) wishes to request eligible bidders to participate in a Request for proposal (LRPS) to Institutional Consultancy to Generation Unlimited Youth Challenge Ethiopia. Interested and eligible bidders can get the bidding document with the below links: <a href="https://2merkato.com/images/downloads/UNICEF/N-LRPS-2021-9168831.pdf">https://2merkato.com/images/downloads/UNICEF/N-LRPS-2021-9168831.pdf</a></p>
																<p>
																	Any query or clarification with regard to this bid shall be send through an email to <a href="mailto:supplyaddisababa@unicef.org">supplyaddisababa@unicef.org</a> before or on 11 August 2021.</p>
																<p>
																	The due date for submission of proposals/Bid to UNICEF Addis Ababa Office, is on or before 2:00PM standard time (East African Time) 18 August 2021.&nbsp; Please read the LRPS for detailed requirements and due dates.</p>
																<p>
																	<strong>ADDRESS: </strong>UNICEF Ethiopia, UNECA Compound, Zambezi Building, 2nd floor Supply Section,&nbsp;Addis Ababa, Ethiopia OR Email - <a href="mailto:eth-supplytenderbox@unicef.org">eth-supplytenderbox@unicef.org</a></p>
																<hr />
																<p>
																	UNICEF (Ethiopia) wishes to request eligible bidders to participate in a Request for proposal (LRPS) to Institutional Consultancy to establish Long Term Agreement (LTA) for the HACT audit &amp; Spot check performance - LRPS-2020-9168391 (Applicable for local bidders)</p>
																<p>
																	Details of the requirements for this bid and eligibility criteria etc. can be found in the bidding document.&nbsp; Interested and eligible bidders can get the bidding document with the below links: <a href="http://2merkato.com/images/downloads/UNICEF/LRPS-2021-9168391.pdf">http://2merkato.com/images/downloads/UNICEF/LRPS-2021-9168391.pdf</a></p>
																<p>
																	Any query or clarification with regard to this bid shall be send through an email to <a href="mailto:supplyaddisababa@unicef.org">supplyaddisababa@unicef.org</a> before or on 21 July 2021. Bid clarification will be communicated on the same website at 2merkato.com to the public.&nbsp;While sending your request for clarification, please ensure that you specify the LRPS number in the subject email, provide the name of your company, contact person, email and mobile number.</p>
																<p>
																	The due date for submission of proposals/Bid to UNICEF Addis Ababa Office, is on or before 2:00PM standard time (East African Time) 28 July 2021.&nbsp; Please read the LRPS for detailed requirements and due dates.</p>
																<hr />
																<p>
																	UNICEF (Ethiopia) wishes to request eligible bidders to participate in a Request for proposal (LRPS) Consultancy Services for Supervision and quality assurance of construction works for Maitsebri water and sanitation project, Tigray, Ethiopia. &nbsp;Interested and eligible bidders can get the bidding document with the links:</p>
																<p>
																	<a href="http://www.2merkato.com/images/downloads/UNICEF/April_4-24-2019_18-14-29link1.pdf">http://www.2merkato.com/images/downloads/UNICEF/April_4-24-2019_18-14-29link1.pdf</a></p>
																<p>
																	<a href="http://www.2merkato.com/images/downloads/UNICEF/April_4-24-2019_18-14-29link2.pdf">http://www.2merkato.com/images/downloads/UNICEF/April_4-24-2019_18-14-29link2.pdf</a></p>
																<p>
																	Any query or clarification with regard to this bid shall be send through an email to <a href="mailto:supplyaddisababa@unicef.org">supplyaddisababa@unicef.org</a> before or on 30<sup>th</sup> June 2021.</p>
																<p>
																	Please note that pre bid meeting is arranged for Thursday June 24, 2021 and those interested to participate in the meeting kindly send your contact detail through an email to <a href="mailto:supplyaddisababa@unicef.org">supplyaddisababa@unicef.org</a>.</p>
																<p>
																	The due date for submission of proposals/Bid to UNICEF Addis Ababa Office is on or before 2:30PM standard time (East African Time) 30<sup>th</sup> June 2021.&nbsp; Please read the LRPS for detailed requirements and due dates.</p>
																<hr />
																<p>
																	UNICEF (Ethiopia) wishes to request eligible bidders to participate in a Request for proposal (LRPS) to Consultancy to Financial and Economic Costing for Hepatitis B Birth Dose.</p>
																<p>
																	Details of the requirements for this bid and eligibility criteria etc. can be found in the bidding document. &nbsp;Interested and eligible bidders can get the bidding document with the links: <a href="http://2merkato.com/images/downloads/UNICEF/LRPS-2021-9167283.pdf">http://2merkato.com/images/downloads/UNICEF/LRPS-2021-9167283.pdf</a> or UNGM - https://www.ungm.org/UNUser/Notice/129509</p>
																<p>
																	Any query or clarification with regard to this bid shall be send through an email to <a href="mailto:supplyaddisababa@unicef.org">supplyaddisababa@unicef.org</a> before or on 09 June 2021.</p>
																<p>
																	The due date for submission of proposals/Bid to UNICEF Addis Ababa Office, is on or before 2:00PM standard time (East African Time) 16 June 2021.&nbsp; Please read the LRPS for detailed requirements and due dates.</p>
								<p>
                UNICEF (Ethiopia) wishes to request eligible bidders to participate in a Request for proposal (LRPS) to Institutional Consultancy to establish Long Term Agreement (LTA) for the HACT audit & Spot check performance - LRPS-2020-9168391 (Applicable for local bidders)

</p><p>Details of the requirements for this bid and eligibility criteria etc. can be found in the bidding document.  Interested and eligible bidders can get the bidding document with the below links: http://2merkato.com/images/downloads/UNICEF/LRPS-2021-9168391.pdf

</p><p>Any query or clarification with regard to this bid shall be send through an email to supplyaddisababa@unicef.org before or on 21 July 2021. Bid clarification will be communicated on the same website at 2merkato.com to the public. While sending your request for clarification, please ensure that you specify the LRPS number in the subject email, provide the name of your company, contact person, email and mobile number.

</p><p>The due date for submission of proposals/Bid to UNICEF Addis Ababa Office, is on or before 2:00PM standard time (East African Time) 28 July 2021.  Please read the LRPS for detailed requirements and due dates.</p>							

        </Grid>
        <Grid item xs={12} md={1}>
          <Paper >
            {/* <ServiceLinks /> */}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
