import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid,Paper } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
 import '../App.css';
import '../component/slider/HomeSlider';
import { classExpression } from '@babel/types';
import HomeSlider from '../component/slider/HomeSlider'; 
import MainMenu from '../component/NavMenus/MainMenu'; 
import MembershipLinks from '../component/NavMenus/MembershipLinks'; 
import header from '../images/header.jpg';
import Topbar from '../component/topbar/Topbar';
import 'bootstrap/dist/css/bootstrap.css';
import Welcome from '../component/Welcome/Welcome';
import News from '../component/News/News';
import Footer from '../component/Footer/Footer';
import Services from '../component/Services/Services';

const useStyle =makeStyles((theme)=>({
  grid:{
    width:'100%',
    margin:'0px',
    padding:'3px'
  },
  paper:{ 
    padding:theme.spacing(2),
    textAlign:'center',
    color: theme.palette.text.secondary,
    background:theme.palette.success.light,
  }
}))

function App() {
  const classes=useStyle();
  return (
    <div class="mmenu">
     {/* <img            
                
                className="d-block w-70"
                 class="img-fluid"
                 src="../images/header.jpg" 
                 href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a>
    <MainMenu/>
   */}
     
     <Grid container spacing={2} className={classes.grid} className="grid">
     
       
        <Grid item xs={1} md={1}>
        
        {/* <Welcome /> */}
        
        </Grid>
        <Grid item xs={11} md={8}>
         
           <h3>The Chamber System</h3>
  
         <p>  Ethiopian Chamber of Commerce and Sectoral Associations (ECCSA), is a national Chamber of Commerce and Sectoral Associations having eighteen members comprising of nine Regional Chambers of Commerce and Sectoral Associations, two city Chambers of Commerce and Sectoral Associations, one national Chamber of Sectoral Association and six Sectoral Associations organized at a national level.
 
         </p><p>ECCSA is established by proclamation no. 341/2003 (chambers of commerce and Sectoral association establishment, proclamation).  The chamber has the following members:
         </p>
         <ul>
           <li>

           Representatives of Regional Chambers of Commerce and Sectoral Associations.

           </li>
           <li>
             
Representatives of the Chamber of the National Sectoral Associations.

             </li>
             <li>
             
Representatives of City Chambers of Commerce and Sectoral Associations.

             </li>
             <li>
             Chambers of Commerce and Sectoral Associations established in Addis Ababa and Dire Dawa shall become members of the Ethiopian Chamber of Commerce and Sectoral Associations.

             </li>
             <li>
Sectoral Associations established at national level.
</li>
         </ul>
         <p> The members of ECCSA are also established according to the Directive Issued to Implement Proclamation No of 341/2003 of Chamber of Commerce and Sectoral Associations Council. For further information:
         </p><ul><li>
Chamber of commerce and Sectoral Association Directive (Download)</li><li>
Chambers of Commerce and Sectoral Association Establishment Proclamation No. 341/2003  (Read/Download)</li>
</ul>
ECCSA has:
<ul><li>
a General Assembly;
a Board of Directors;</li><li>
a President, a Vice-president, a Secretary, and; the necessary staff</li>
</ul>

        </Grid>
        <Grid item xs={12} md={3}>
          <Paper >
          {/* <MembershipLinks /> */}
          </Paper>
        </Grid>
     </Grid>
    </div>
  );
}

export default App;
