import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.css';

import Footer from '../component/Footer/Footer';

import ResourceLinks from '../component/NavMenus/ResourceLinks';
const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
       }
}))

function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
      {/* <img            
                
                className="d-block w-70"
                 class="img-fluid"
                 src="../images/header.jpg" 
                 href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a>
    <MainMenu/> */}


      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum} >
            <Link color="inherit" to="/" >
              Home
            </Link>

            <Link
              color="textPrimary"
              to="#"
              aria-current="page">
              Publications for Sale  
            </Link>
            </Breadcrumbs>
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={8}>
          <Paper ><div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">


                  <h3>
                  Business Development Publications For Sale</h3>
                  <ol>
                    <li>
                      <a href="/Data/Sites/1/2013 EC/Media Digest/Information Center Business News.pdf" target="_blank">01 Information Center Business News December 2013 E.C</a></li>
                    <li>
                      <a href="/Data/Sites/1/2013 EC/Media Digest/02 Information Center Business News January 2013 EC.pdf" target="_blank">02 Information Center Business News January 2013 EC</a></li>
                    <li>
                      <a href="/Data/Sites/1/2013 EC/Media Digest/03 Information Center Business News Feburary 2013 E.C.pdf" target="_blank">03 Information Center Business News February 2013 EC</a></li>
                  </ol>

                  <hr />
                  <h4 >
                    ECCSA Media Digest</h4>
                  <ol>
                  <li>
		How to prepare business plan (English/Amharic)</li>
	<li>
		How to start and improve your business (English/Amharic)</li>
	<li>
		Taxation in Ethiopia (English/Amharic)</li>
	<li>
		Financing business in Ethiopia (English/Amharic)</li>
	<li>
		How to calculate and record cost (English/Amharic)</li>
	<li>
		Book Keeping (English/Amharic)</li>
	<li>
		Investment Guide To Ethiopia (English/Amharic)</li>
	<li>
		Ethiopian Technical &amp; Vocational Education &amp; Training Directory (TVET ) 2010/11</li>
	<li>
		Export Guide 2011/12</li>
	<li>
		Ethiopian Business Directory 4th Edition 2020-21</li>
	<li>
		Ethiopian Code of Business Ethics (English/Amharic)</li> </ol>

                </div>

              </div>
            </div>
          </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          {/* <Paper > */}
          {/* <h5 class="text-uppercase">Quick Links</h5>

<ul class="list-unstyled mb-0">
 
  <li>
    <a href="#!" class="text-green"> Organizational Structure</a>
  </li>
    <li>
    <a href="#!" class="text-green">  General Assembly</a>
  </li>
    <li>
    <a href="#!" class="text-green">  Board of Directors</a>
  </li>
    <li>
    <a href="#!" class="text-green">  The Secretariat</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Achievements</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Former Presidents & Secretary G.</a>
  </li>
    <li>
    <a href="#!" class="text-green">Our Location</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Our Partners</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Strategic Plan</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Chamber Law (+Directives)</a>
  </li>
    <li>
    <a href="#!" class="text-green"> ECCSA Projects</a>
  </li>


</ul> */}
          <ResourceLinks />

          {/* </Paper> */}
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
