import React, { useState } from "react";
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid,Paper,Button, Input, TextField, Select } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import { Breadcrumb,Nav, Navbar,NavItem, NavDropdown, Container,form, Carousel, Card, Row, Col, Form } from "react-bootstrap";
// import { Dropdown, MenuItem, DropdownButton } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import { BrowserRouter as Router, Route, Swithch, Link, Redirect } from 'react-router-dom';
 import '../../App.css';

import { classExpression } from '@babel/types';
import MainMenu from '../../component/NavMenus/MainMenu'; 
import { serviceForm } from "../../ServiceForm";
import 'bootstrap/dist/css/bootstrap.css';


import Partners2 from '../../component/Teams/Partners';

import Footer from '../../component/Footer/Footer';

const useStyle =makeStyles((theme)=>({
  grid:{
    width:'100%',
    margin:'15px',
    padding:'1px',
    row:'20px',
      background:'#fff',
  },
  main:{
    width:'100%',
    background:'#fff',
    padding:'10px',
  

  },
  paper:{ 
    // padding:theme.spacing(1),
    textAlign:'left',
    color: '#198754',
    background:'#aaf',
    padding:'10px',
    
    // elevation:'0',
  },
  
  
  appBar:{
    color: '#000',
    background:'#fff',
  },
  margin:{
    marginLeft:'100px',
    marginTop:'5px',
    color:'primary',
    
  }


}))

export default function Membership() {
    function handleSubmit(event) {
    // event.preventDefault();
    event.target('/about');
  }
  return (
    <div  >
     {/* <Topbar/> */}
     <div class="row">
    <div class="bg">Commercial Invoice </div>
    <Grid item xs={1} sm={2}>
         
        
         </Grid> <Grid item xs={10} sm={8}>
         
        
       
    <Form onSubmit={handleSubmit}>
        {
          
       serviceForm.map(Input=>    
         
       
        <Row xs={1} md={6}>
          <label>{Input.label}</label>
          
          {(Input.type=="select"? (<select  className={`form-control `} name={Input.name}  >
           {Input.options.map(x=> <option  value={x.key} > {x.value}  </option> )}
            </select>  ) :
          (<input name={Input.name}  type={Input.type} className={`form-control `} />)
                   
      )}
     
     </Row>
       )
     }  
       
       
      </Form>

      <br />  
      <a href="/users" class="btn btn-success">Submit</a>
      <br />
    
      </Grid>             
                
      <Grid item xs={1} sm={2}>
         
        
       </Grid>
        </div> 
        <Grid container spacing={2} >  
        <br/><br/>
        <Grid item xs={12} md={12}>
         
          <Footer />
        </Grid>
     </Grid>
    </div>
  );
}

// export default App;
