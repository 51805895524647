import React, { Component } from "react";
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';



class EventTimeline extends Component{
    render(){  
  
        return (
          
                <React.Fragment>
                  <Timeline align="alternate">
                    
                    <TimelineItem>
                      <TimelineOppositeContent>
                        <Typography  color="greeb">05/13/13</Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography>Exhibition Ending Date</Typography>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineOppositeContent>
                        <Typography class=" btn-outline-success" color="yellow">20/12/13</Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot variant="outlined"/>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography class="btn btn-outline-success">Exhibition Starting Date</Typography>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineOppositeContent>
                        <Typography color="secondary">16/11/13</Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography color="secondary" >announcement  </Typography>
                      </TimelineContent>
                    </TimelineItem>
                    <TimelineItem>
                      <TimelineOppositeContent>
                        <Typography color="secondary">01/11/13 -15/11/13</Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Typography color="secondary">Registration Period</Typography>
                      </TimelineContent>
                    </TimelineItem>
                  </Timeline>
                </React.Fragment>
              );
            }
            
      }
    
    export default EventTimeline