import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card, CardMedia, Typography, cardContent, CardContent } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';
import { classExpression } from '@babel/types';
import HomeSlider from '../component/slider/HomeSlider';
import MainMenu from '../component/NavMenus/MainMenu';
import ServiceLinks from '../component/NavMenus/ServiceLinks';
import header from '../images/header.jpg';
import Topbar from '../component/topbar/Topbar';
import 'bootstrap/dist/css/bootstrap.css';
import Welcome from '../component/Welcome/Welcome';
import News from '../component/News/Blog';
import Footer from '../component/Footer/Footer';
// import News from '../component/News/News';

const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  }
}))

function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
      {/* <img            
               
               className="d-block w-70"
                class="img-fluid"
                src="../images/header.jpg" 
                href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a>
   <MainMenu/> */}

      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
          {/* <MainMenu/> */}
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={12}>


          {/* {cards.map((card) => ( */}
          <Grid item xs={12} sm={6} md={12}>
            <Card className={classes.card}>

              <Typography gutterBottom variant="h6" className={classes.title}>
                ECCSA Receives Sri Lankan Delegation               </Typography>
              <CardMedia
                // className={classes.cardMedia}
                image="http://ethiopianchamber.com/Data/Sites/1/2012%20EC/images/sri.jpg"
                title="Image title"
                maxWidth="90%"
              />

              <CardContent className={classes.cardContent}>

                <Typography class="body">
                  <img src="http://ethiopianchamber.com/Data/Sites/1/2012%20EC/images/sri.jpg " width="65%" />
                  <p>   Melaku Ezezew (Eng.), President of the Ethiopian Chamber of Commerce and Sectoral Associations (ECCSA), along with the secretariat, received on 19 February 2020 Sugeeshwara Gunaratna, Sri Lankan Ambassador to Ethiopia, and his colleague Sumith Dassanayake, former Ambassador, to discuss on ways of maximizing economic ties between the respective business communities of the two countries.  </p><p>

                  </p><p>Having appreciated the Ethiopia-Sri Lanka business forum hosted here in Addis last year in November, the officials discussed on progressive business networks.
                  </p><p>Following the event, said the Ambassador, lots of Sri Lankan companies made inquiries of making business meetings with their Ethiopian counterparts in late March this year, citing a prominent Sri Lankan construction company as an instance, in order that they would be able to work in partnership.
                  </p><p>Warmly accepting the request, President Melaku reflected the interest on Ethiopian side stating that there is a vital need of investment prominently in machinery produce so as to minimize the existing import-export gap and foreign currency scarcity; any network between countries should be on a win-win platform if both sides have to benefit, the President added.
                  </p><p>According to his statement, Ethiopia warmly invites FDI to accelerate the ongoing economic growth seen over the last years and the cooperation of the two businesses is vital.

                  </p><p> According to the Ambassador’s statement, there was also an inquiry from Sri Lankan businesses that Ethiopian trade mission would make a business visit too and the two sides discussed upon the issue.

                    A number of Sri Lankan companies have also begun manufacturing, mainly in the garment sector at the Hawassa Industrial Park and are now looking at expanding trade opportunities, as was learnt.                 </p>               </Typography>
              </CardContent>

            </Card>

          </Grid>




        </Grid>

        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
