import { render } from '@testing-library/react';
import React from 'react';
import { Component } from 'react';
import { Breadcrumb,Nav, Container, Carousel, Card, Row, Col, Form } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
// import AppBar from '@material-ui/core/AppBar';    
// import Toolbar from '@material-ui/core/Toolbar';  
import { Grid, AppBar, Toolbar,Typography } from '@material-ui/core';  
import ExpansionPanel from '@material-ui/core/ExpansionPanel';    
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';    
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';    
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';  
import { FontAwesomeIcon,FaBalanceScale } from '@fortawesome/react-fontawesome';
import {faBalanceScale,faHammer,faBullhorn} from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter as Router, Route, Swithch, Link, Redirect } from 'react-router-dom';
//  import { FaLandmark } from "react-icons/fa";
import {faBook,faBuilding} from '@fortawesome/free-solid-svg-icons';
import {faChalkboardTeacher} from '@fortawesome/free-solid-svg-icons';
import counterUp from 'counterup2'
import './Services.css';
// import 'font-awesome/css/font-awesome.min.css';
class Services extends Component {
    render(){
       return(
         <div> 
      
      <hr />
      
            <Grid container spacing={5} >
     <Grid item xs={6}  > 
     <ExpansionPanel >    
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon></ExpandMoreIcon>} >    

            <div class="icon"><FontAwesomeIcon icon={faBullhorn} />  </div>
            <br />
                                          <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                          

                                          <p class="links"  >Trade & Investment Promotion</p> 
                                             
                                          </div>   
              </ExpansionPanelSummary>    
              <ExpansionPanelDetails>    
              <p class="text">
                  <ul>
                      <li>
                      Provision of business information to the business community</li><li>
Business Advisory</li><li>
Business Networking</li><li>
Trade Fairs/Exhibition</li><li>
Investment Guide</li><li>
Exporters Guide
                      </li>
                  </ul>
                  </p>    
              <br />
        
            </ExpansionPanelDetails>    
            <Link  eventKey="trade-investment" class="btn btn-outline-success" to="/trade-investment" > Read More</Link>
           
               </ExpansionPanel>   
     </Grid>
     <Grid item xs={6} >
     <ExpansionPanel >    
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon></ExpandMoreIcon>} >    
            < div class="icon">      <FontAwesomeIcon icon={faChalkboardTeacher} />  
                                     </div> 
            <br />
                                          <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                          

                                          <p class="links"  >Capacity Building</p> 
                                             
                                          </div>   
              </ExpansionPanelSummary>    
              <ExpansionPanelDetails>    
              <p class="text">
                  <ul>
                  Need based training or technical & skill development (Ethiopian Chamber Academy)
    Consultancy on preparation of Strategic Plan, Project Proposal, Governance Manuals
    Resource Mobilization
                  </ul>
                  </p>    
              <br />
        
            </ExpansionPanelDetails>    
            {/* <a href="/capacity-building" class="btn btn-success">Read More </a> */}
            <Link  eventKey="capacity-building" class="btn btn-outline-success" to="/capacity-building" > Read More</Link>
               </ExpansionPanel>    
     </Grid >
     <Grid item xs={6}  > 
     <ExpansionPanel >    
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon></ExpandMoreIcon>} >    
            <div class="icon"><FontAwesomeIcon icon={faBook} /></div>
            <br />
                                          <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                          

                                          <p class="links"  >Research & Advocacy</p> 
                                             
                                          </div>   
              </ExpansionPanelSummary>    
              <ExpansionPanelDetails>    
              <p class="text">
                  <ul>
                  Advocacy is a top priority for the ECCSA, whether in consulting with the government, commenting on regulatory issues or working with the government to bring about equality, opportunity and change
                  </ul>
                  </p>    
              <br />
        
            </ExpansionPanelDetails>    
            <Link  eventKey="research-advocacy" class="btn btn-outline-success" to="/research-advocacy" > Read More</Link>
            {/* <a href="/research-advocacy" class="btn btn-success">Read More </a> */}
               </ExpansionPanel>   
     </Grid>
     <Grid item xs={6} > 
     <ExpansionPanel >    
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon></ExpandMoreIcon>} >    
            < div class="icon">      <FontAwesomeIcon icon={faHammer} />  
                                     </div> 
            <br />
                                          <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                          

                                          <p class="links"  >Arbitration</p> 
                                             
                                          </div>   
              </ExpansionPanelSummary>    
              <ExpansionPanelDetails>    
              <p class="text">
                 
                  </p>    
              <br />
        
            </ExpansionPanelDetails>    
            {/* <a href="/about" class="btn btn-success">Read More </a> */}
            <Link  eventKey="arbitration" class="btn btn-outline-success" to="/arbitration" > Read More</Link> 
               </ExpansionPanel>    
     </Grid>
</Grid>
          
              </div>  


       )}}

export default Services