import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';
import { classExpression } from '@babel/types';
import HomeSlider from '../component/slider/HomeSlider';
import MainMenu from '../component/NavMenus/MainMenu';
import QuickLinks from '../component/NavMenus/QuickLinks';
import header from '../images/header.jpg';
import Topbar from '../component/topbar/Topbar';
import 'bootstrap/dist/css/bootstrap.css';
import Welcome from '../component/Welcome/Welcome';
import News from '../component/News/News';
import Footer from '../component/Footer/Footer';

const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
  }
}))
function handleClick(event) {
  event.preventDefault();

}
function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
      {/* <img

        className="d-block w-70"
        class="img-fluid"
        src="../images/header.jpg"
        href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a>
      <MainMenu/> */}


      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
          {/* <MainMenu/> */}

          <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum}>
           
            <Link  to="/" > Home</Link>
              <Link  eventKey="about" aria-current="page" to="/board" > Board of Directors</Link>
          
            
          </Breadcrumbs></Grid>
        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={8}>
          <Paper ><div class="row">
            <section id="team" class="pb-5">
              <div class="container">
                <h5 class="section-title h3">Board of Directors</h5>
                <div class="row">
                  <p class="card-text">
                    The General Assembly elects a board of directors comprising of 11 persons that directs Ethiopian Chamber of Commerce and Sectoral Association (ECCSA) every two years.

                    The Board has the following powers and duties:<ol><li>
                      to ensure the implementation of the decisions of the General Assembly;</li><li>
                        to employ and dismiss the Secretary General;</li><li>
                        to decide on the employment and dismissal of the heads of departments and services of the Chamber, submitted to it by the Secretary General;</li><li>
                        to call through the president extraordinary meetings of the General Assembly as it deems necessary or when one third of the members of the General Assembly demand;</li><li>
                        to submit annual budget and work program of the Chamber to the General Assembly;;</li><li>
                        to decide on matters submitted to it by the Secretary General;</li><li>
                        to establish different committees to assist the Board in carrying out its duties;</li><li>
                        to perform such other duties as are necessary
                      </li>

                    </ol>

                    The General Assembly may delegate its powers and duties to the Board, the President, the Vice President or the Secretary as appropriate.

                  </p>
                  <h3>The Board of Directors</h3>
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <div class="image-flip" >
                      <div class="mainflip flip-0">
                        <div class="frontside">
                          <div class="card">
                            <div class="card-body text-center">
                              <p><img class=" img-fluid" src="https://ethiopianchamber.com/Data/Sites/1/boardpicture/AEng%20Melaku.png" width="200px" alt="card image" /></p>
                              <h4 class="card-title">Engineer Melaku Ezezew (President)</h4>

                              <p class="card-text">Tele: +251-918774646 <br />
                                <br />
                                E-mail: melakuezezew@gmail.com</p>
                              {/* <a href="https://www.fiverr.com/share/qb8D02" class="btn btn-primary btn-sm"><i class="fa fa-plus"></i></a> */}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <div class="image-flip" >
                      <div class="mainflip flip-0">
                        <div class="frontside">
                          <div class="card">
                            <div class="card-body text-center">
                              <p><img class=" img-fluid" src="https://ethiopianchamber.com/Data/Sites/1/mgt-image/Wubie.jpg" width="200px" alt="card image" /></p>
                              <h4 class="card-title">

                                Mr. Aseffa Gebre Sillasie (V. President)</h4>

                              <p class="card-text"> Tele: 251-914301197/11760512<br />
                                Fax: +251-115517699<br />
                                E-mail: asefa.gie03@yahoo.com</p>
                              {/* <a href="https://www.fiverr.com/share/qb8D02" class="btn btn-primary btn-sm"><i class="fa fa-plus"></i></a> */}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </section>

          </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper >
            {/* <h5 class="text-uppercase">Quick Links</h5> */}

            <QuickLinks />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
