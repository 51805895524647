export const presidents = [
  {
    id: 1,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/1babaya.jpg",
  },
  {
    id: 2,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/2weerts.jpg",
  },
  {
    id: 3,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/3bekele_beshah.jpg",
  },
  {
    id: 4,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/4ato-gebreselassie-oda.jpg",
  },
  {
    id: 5,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/5ato-taffara-deguefe.jpg",
  },
  {
    id: 6,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/6ato-hailu-shawol.jpg",
  },
  {
    id: 7,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/7ato-ayenew-bitewulign.jpg",
  },
  {
    id: 8,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/8ato-tadesse-liben.jpg",
  },
  {
    id: 9,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/9ato-alemu-abera.jpg",
  },
  {
    id: 10,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/10ato-bekele-endalew.jpg",
  },
  {
    id: 11,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/11atoayalew-bezabeh.jpg",
  },
  {
    id: 12,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/12ato-damtew-bereded.jpg",
  },
  {
    id: 13,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/13ato-fikre-hegiyane.jpg",
  },
  {
    id: 14,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/14ato-woubeshet-workalemahu.jpg",
  },
  {
    id: 15,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/15ato-kibour-genna.jpg",
  },
  {
    id: 16,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/16ato-aschalew-haile.jpg",
  },
  {
    id: 17,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/17ato-birhane-mewa.jpg",
  },
  {
    id: 18,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/18ato-getachew-ayenew.jpg",
  },
  {
    id: 19,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/19ato-eyessuswork-zafu.jpg",
  },
  {
    id: 20,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/20wro-mulu-solomon.jpg",
  },
  {
    id: 20,
    name: "",
    imageUrl:
      "  http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/102/WebImages/21Ato-solomon-Afework.jpg",
  }
];
export const secretaries = [
  {
    id: 1,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/103/WebImages/1mr_a_stir.jpg",
  },
  {
    id: 2,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/103/WebImages/2ato-idris.jpg",
  },
  {
    id: 3,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/103/WebImages/3ato-alem-silassie-woldeamanuel.jpg",
  },
  {
    id: 4,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/103/WebImages/4ato-tulu-gula.jpg",
  },
  {
    id: 5,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/103/WebImages/5ato-bekele-wolde-semayat.jpg",
  },
  {
    id: 6,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/103/WebImages/6ato-mebrate-mengistu.jpg",
  },
  {
    id: 7,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/103/WebImages/7ato-mengesha-workneh.jpg",
  },
  {
    id: 8,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/103/WebImages/8ato-kassahun-jembere.jpg",
  },
  {
    id: 9,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/103/WebImages/9ato-adaneh-gudena.jpg",
  },
  {
    id: 10,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/103/WebImages/10ato-andualem-tegegne.jpg",
  },
  {
    id: 11,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/103/WebImages/10ato-gashaw-debebe.jpg",
  },
  {
    id: 12,
    name: "",
    imageUrl:
      "http://ethiopianchamber.com/Data/Sites/1/media/GalleryImages/103/WebImages/10ato-Endalk-Sime.jpg",
  }
];