// import { Component } from 'react';
import React, { Component } from "react";

// import Calendar from "react-material-ui-calendar";
import { Button, Carousel, Card, Row, Col, Form } from "react-bootstrap";


class Ads extends Component{
    
//selected days will be available here
 
  render(){  
  
    return (
        <Carousel variant="dark">
        <Carousel.Item>
          <img 
           style={{'height':"750px"}}  
            className="d-block w-100"
            src="http://ethiopianchamber.com/Data/Sites/1/2013%20EC/us.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5><a href="#" class="date span">New Year Expo</a> </h5>
           
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
           style={{'height':"750px"}}  
            className="d-block w-100"
            src="http://ethiopianchamber.com/Data/Sites/1/2013%20EC/Law_ad.jpg"
            alt="Second slide"
          />
          <Carousel.Caption>
            <h5>Meskel Expo </h5>
           {/* http://ethiopianchamber.com/Data/Sites/1/2012%20EC/SMEs-Business-Guideline-During-COVID-19-Situation.pdf */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
           style={{'height':"750px"}}  
            className="d-block w-100"
            src="http://ethiopianchamber.com/Data/Sites/1/2012%20EC/Images/Press.png"
            alt="Second slide"
          />
          <Carousel.Caption>
            <h5>Meskel Expo </h5>
           {/* http://ethiopianchamber.com/Data/Sites/1/2012%20EC/SMEs-Business-Guideline-During-COVID-19-Situation.pdf */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>  
    )
}}

export default Ads