import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.css';
import Welcome from '../component/Welcome/Welcome';
import News from '../component/News/News';
import Footer from '../component/Footer/Footer';
import { Breadcrumb, Nav, Dropdown, Button, FormControl, Navbar, NavItem, NavDropdown, Container, form, Carousel, Card, Row, Col, Form } from "react-bootstrap";
import QuickLinks from '../component/NavMenus/QuickLinks';
import ResourceLinks from '../component/NavMenus/ResourceLinks';
const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
       }
}))

function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
      {/* <img            
                
                className="d-block w-70"
                 class="img-fluid"
                 src="../images/header.jpg" 
                 href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a>
    <MainMenu/> */}


      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum} >
                <Link color="inherit" to="/" >
              Home
            </Link>

            <Link
              color="textPrimary"
              to="#"
              
              aria-current="page">
              e-Newsletter
            </Link>
            </Breadcrumbs>
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={8}>
          <Paper ><div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">


                  <h3>
                  E-Newsletter Download</h3>
                 <p> በየ15 ቀኑ የሚዘጋጅ ዜና-መጽሔት</p>
</div>
<div id="ctl00_mainContent_ctl00_theList_updPnl">
		
                    
                            <ul class="linkitem">
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/E-newsletter/ECCSA e-newsletter no.189.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከነሀሴ 1-30 ቀን 2012 ዓ.ም. ዜና-መጽሔት ቁ.189">ከነሀሴ 1-30 ቀን 2012 ዓ.ም. ዜና-መጽሔት ቁ.189</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/E-newsletter/ECCSA e-newsletter-187.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከግንቦት 1-30 ቀን 2012 ዓ.ም. ዜና-መጽሔት ቁ.187">ከግንቦት 1-30 ቀን 2012 ዓ.ም. ዜና-መጽሔት ቁ.187</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/E-newsletter/ECCSA e-newsletter No. 185.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከጥር 1-30 ቀን 2012 ዓ.ም. ዜና-መጽሔት ቁ.185">ከጥር 1-30 ቀን 2012 ዓ.ም. ዜና-መጽሔት ቁ.185</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/E-newsletter/ECCSA e-newsletter No. 184.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከታህሣሥ 1-30 ቀን 2012 ዓ.ም. ዜና-መጽሔት ቁ.184">ከታህሣሥ 1-30 ቀን 2012 ዓ.ም. ዜና-መጽሔት ቁ.184</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/E-newsletter/ECCSA e-newsletter 183.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከህዳር 1-30 ቀን 2012 ዓ.ም. ዜና-መጽሔት ቁ.183">ከህዳር 1-30 ቀን 2012 ዓ.ም. ዜና-መጽሔት ቁ.183</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/E-newsletter/e-newsletter-issue 182.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከጥቅምት 1-30 ቀን 2012 ዓ.ም. ዜና-መጽሔት ቁ.182">ከጥቅምት 1-30 ቀን 2012 ዓ.ም. ዜና-መጽሔት ቁ.182</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/2012 EC/E-newsletter/e-newsletter issue 181.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከመስከረም 1-30 ቀን 2012 ዓ.ም. ዜና-መጽሔት ቁ.181">ከመስከረም 1-30 ቀን 2012 ዓ.ም. ዜና-መጽሔት ቁ.181</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 179.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከነሃሴ 1—15 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.179">ከነሃሴ 1—15 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.179</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/ECCSA e-newsletter issue 178.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከሀምሌ 1-30 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.178">ከሀምሌ 1-30 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.178</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 177.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከሰኔ 16—30 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.177">ከሰኔ 16—30 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.177</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 176.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከግንቦት 1—30 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.176">ከግንቦት 1—30 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.176</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 175.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከሚያዝያ 16—30 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.175">ከሚያዝያ 16—30 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.175</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 174.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከሚያዝያ 1—15 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.174">ከሚያዝያ 1—15 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.174</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 172.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከየካቲት 15—30 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.172">ከየካቲት 15—30 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.172</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/2011/e-newsletter issue 168.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከጥቅምት 19-30 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.168">ከጥቅምት 19-30 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.168</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/2011/e-newsletter issue 167.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከጥቅምት 1-20 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.167">ከጥቅምት 1-20 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.167</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/2011/e-newsletter issue 165.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከመስከረም 1-15 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.165">ከመስከረም 1-15 ቀን 2011 ዓ.ም. ዜና-መጽሔት ቁ.165</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 164.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከነሀሴ 16—30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.164">ከነሀሴ 16—30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.164</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 160.pdf" onclick="window.open(this.href,'_blank');return false;" title="ሰኔ1-15 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.160">ሰኔ1-15 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.160</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 159.pdf" onclick="window.open(this.href,'_blank');return false;" title="ግንቦት 16-30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.159">ግንቦት 16-30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.159</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 158.pdf" onclick="window.open(this.href,'_blank');return false;" title="ሚያዝያ 16-30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.158">ሚያዝያ 16-30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.158</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 157.pdf" onclick="window.open(this.href,'_blank');return false;" title="ሚያዝያ 1-15 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.157">ሚያዝያ 1-15 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.157</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 156.pdf" onclick="window.open(this.href,'_blank');return false;" title="መጋቢት 15-30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.156">መጋቢት 15-30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.156</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 155.pdf" onclick="window.open(this.href,'_blank');return false;" title="መጋቢት 1-15 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.155">መጋቢት 1-15 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.155</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 154.pdf" onclick="window.open(this.href,'_blank');return false;" title="የካቲት 16 -30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.154">የካቲት 16 -30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.154</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 153.pdf" onclick="window.open(this.href,'_blank');return false;" title="የካቲት 01—15 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.153">የካቲት 01—15 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.153</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 152.pdf" onclick="window.open(this.href,'_blank');return false;" title="ጥር 01—15 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.152">ጥር 01—15 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.152</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 151.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከታህሣስ 16 - 30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.151">ከታህሣስ 16 - 30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.151</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 150.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከታህሣስ 1-15 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.150">ከታህሣስ 1-15 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.150</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 149.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከህዳር 16 - 30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.149">ከህዳር 16 - 30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.149</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 148.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከጥቅምት 16-30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.148">ከጥቅምት 16-30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.148</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 146.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከመስከረም16-30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.146">ከመስከረም16-30 ቀን 2010 ዓ.ም. ዜና-መጽሔት ቁ.146</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 146.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከነሀሴ15-ጳግሜን 5 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.145">ከነሀሴ15-ጳግሜን 5 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.145</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/e-newsletter issue 143.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከሀምሌ 1 - 15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.143">ከሀምሌ 1 - 15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.143</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/eccsa-e-newsletter-142.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከሰኔ 16 - 30 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.142">ከሰኔ 16 - 30 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.142</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/eccsa-e-newsletter-141.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከሰኔ 1 - 15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.141">ከሰኔ 1 - 15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.141</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/eccsa-e-newsletter-140.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከግንቦት 16-30 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.140">ከግንቦት 16-30 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.140</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/eccsa-e-newsletter-139.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከግንቦት 1-15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.139">ከግንቦት 1-15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.139</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/eccsa-e-newsletter-138.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከሚያዝያ 1-16 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.138">ከሚያዝያ 1-16 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.138</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/eccsa-e-newsletter-137.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከመጋቢት 16-30 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.137">ከመጋቢት 16-30 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.137</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/eccsa-e-newsletter-136.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከመጋቢት 1-15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.136">ከመጋቢት 1-15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.136</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/eccsa-e-newsletter-135.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከየካቲት 16-30 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.135">ከየካቲት 16-30 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.135</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/newsletter-134.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከየካቲት 1-15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.134">ከየካቲት 1-15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.134</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/newsletter-132.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከታህሳስ 1-15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.132">ከታህሳስ 1-15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.132</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/newsletter-131.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከህዳር 16-30 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.131">ከህዳር 16-30 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.131</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/newsletter-130.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከህዳር 1-15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.130">ከህዳር 1-15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.130</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/newsletter-129.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከጥቅምት 1-15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.129">ከጥቅምት 1-15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.129</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/newsletter-128.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከመስከረም 1-15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.128">ከመስከረም 1-15 ቀን 2009 ዓ.ም. ዜና-መጽሔት ቁ.128</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/newsletter-127.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከነሀሴ 15 - ጰጉሜ 5 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.127">ከነሀሴ 15 - ጰጉሜ 5 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.127</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/newsletter-126.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከነሀሴ 01-15 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.126">ከነሀሴ 01-15 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.126</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/newsletter-125.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከሀምሌ 16-30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.125">ከሀምሌ 16-30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.125</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/newsletter-124.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከሀምሌ 01-15 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.124">ከሀምሌ 01-15 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.124</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/newsletter-123.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከሰኔ 16-30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.123">ከሰኔ 16-30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.123</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/newsletter-122.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከሰኔ 01-15 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.122">ከሰኔ 01-15 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.122</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/newsletter-121_2.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከግንቦት 16-30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.121">ከግንቦት 16-30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.121</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/bi-monthly-newsletter/e-newsletter-120.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከግንቦት 01-15 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.120">ከግንቦት 01-15 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.120</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/newsletter-119.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከሚያዝያ 16-30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.119">ከሚያዝያ 16-30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.119</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/bi-monthly-newsletter/newsletter-117-f.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከመጋቢት 16-30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.117">ከመጋቢት 16-30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.117</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/bi-monthly-newsletter/megabit-1-(116).pdf" onclick="window.open(this.href,'_blank');return false;" title="ከመጋቢት 1-15 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.116">ከመጋቢት 1-15 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.116</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/bi-monthly-newsletter/newsletter-115.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከየካቲት 15-30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.115">ከየካቲት 15-30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.115</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/bi-monthly-newsletter/eccsa-newsletter112.pdf" onclick="window.open(this.href,'_blank');return false;" title="ከየካቲት 1-15  ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.112">ከየካቲት 1-15  ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.112</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/bi-monthly-newsletter/tire-15-(111).pdf" onclick="window.open(this.href,'_blank');return false;" title="የጥር 15 - 30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.111">የጥር 15 - 30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.111</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/tahisas-15-(110)-11.pdf" onclick="window.open(this.href,'_blank');return false;" title="የታህሳስ 15 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.110">የታህሳስ 15 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.110</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/eccsa-109.pdf" onclick="window.open(this.href,'_blank');return false;" title="የህዳር 23 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.109">የህዳር 23 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.109</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/eccsa-108.pdf" onclick="window.open(this.href,'_blank');return false;" title="የህዳር 1 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.108">የህዳር 1 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.108</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/tikimt-15-(107)-(1).pdf" onclick="window.open(this.href,'_blank');return false;" title="የጥቅምት 15 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.107">የጥቅምት 15 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.107</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/mes-30-(106).pdf" onclick="window.open(this.href,'_blank');return false;" title="የመስከረም 30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.106">የመስከረም 30 ቀን 2008 ዓ.ም. ዜና-መጽሔት ቁ.106</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/hamle-30-(104).pdf" onclick="window.open(this.href,'_blank');return false;" title="የሀምሌ 30 ቀን 2007 ዓ.ም. ዜና-መጽሔት ቁ.104">የሀምሌ 30 ቀን 2007 ዓ.ም. ዜና-መጽሔት ቁ.104</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/ginbot-15(102).pdf" onclick="window.open(this.href,'_blank');return false;" title="የግንቦት 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.102">የግንቦት 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.102</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/ginbot-1(101)(1).pdf" onclick="window.open(this.href,'_blank');return false;" title="የግንቦት 1 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.101">የግንቦት 1 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.101</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/eccsa-100(1).pdf" onclick="window.open(this.href,'_blank');return false;" title="የሚያዚያ 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.100">የሚያዚያ 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.100</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/megabit-99.pdf" onclick="window.open(this.href,'_blank');return false;" title="የመጋቢት 30 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.99">የመጋቢት 30 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.99</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/megabit-98.pdf" onclick="window.open(this.href,'_blank');return false;" title="የመጋቢት 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.98">የመጋቢት 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.98</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/yeka-30-(97).pdf" onclick="window.open(this.href,'_blank');return false;" title="የካቲት 30 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.97">የካቲት 30 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.97</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/yek-15(96).pdf" onclick="window.open(this.href,'_blank');return false;" title="የካቲት 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.96">የካቲት 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.96</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/yek-1(95).pdf" onclick="window.open(this.href,'_blank');return false;" title="የካቲት 1 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.95">የካቲት 1 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.95</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/tir-15-(94).pdf" onclick="window.open(this.href,'_blank');return false;" title="የጥር 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.94">የጥር 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.94</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/tahisas-30-(93).pdf" onclick="window.open(this.href,'_blank');return false;" title="ታህሳስ 30 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.93">ታህሳስ 30 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.93</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/hidar-15(92)(1).pdf" onclick="window.open(this.href,'_blank');return false;" title="የህዳር 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.92">የህዳር 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.92</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/hidar-1-(91)(1).pdf" onclick="window.open(this.href,'_blank');return false;" title="የህዳር 1 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.91">የህዳር 1 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.91</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/tikimt-15-(90).pdf" onclick="window.open(this.href,'_blank');return false;" title="የጥቅምት 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.90">የጥቅምት 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.90</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/tikimt 89.pdf" onclick="window.open(this.href,'_blank');return false;" title="የጥቅምት 1 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.89">የጥቅምት 1 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.89</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/meskerem 88.pdf" onclick="window.open(this.href,'_blank');return false;" title="የመስከረም 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.88">የመስከረም 15 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.88</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/e-newsletter/meskerem 87.pdf" onclick="window.open(this.href,'_blank');return false;" title="የመስከረም 1 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.87">የመስከረም 1 ቀን 2007 ዓ.ም. ዜና- መጽሔት ቁ.87</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/nehsie-15-(86)(1).pdf" onclick="window.open(this.href,'_blank');return false;" title="የነሀሴ 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.86">የነሀሴ 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.86</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/nehasie-1(85)(1).pdf" onclick="window.open(this.href,'_blank');return false;" title="የነሀሴ 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.85">የነሀሴ 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.85</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/hamle-15(84).pdf" onclick="window.open(this.href,'_blank');return false;" title="የሀምሌ 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.84">የሀምሌ 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.84</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/eccsa-83.pdf" onclick="window.open(this.href,'_blank');return false;" title="የሀምሌ 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.83">የሀምሌ 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.83</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/sene-1(82).pdf" onclick="window.open(this.href,'_blank');return false;" title="የሰኔ 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.82">የሰኔ 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.82</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/eccsa-81.pdf" onclick="window.open(this.href,'_blank');return false;" title="የግንቦት15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.81">የግንቦት15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.81</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/bi-monthly-newsletter/mia-(80)(1).pdf" onclick="window.open(this.href,'_blank');return false;" title="የግንቦት1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.88">የግንቦት1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.88</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/megabit-15-(77).pdf" title="የመጋቢት 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.77">የመጋቢት 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.77</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/megabit-1(76).pdf" onclick="window.open(this.href,'_blank');return false;" title="የመጋቢት 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.76">የመጋቢት 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.76</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/yek-15(74).pdf" onclick="window.open(this.href,'_blank');return false;" title="የየካቲት 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.74">የየካቲት 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.74</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/yek-1(73).pdf" onclick="window.open(this.href,'_blank');return false;" title="የየካቲት 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.73">የየካቲት 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.73</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/tir-15-(72).pdf" onclick="window.open(this.href,'_blank');return false;" title="የጥር 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.72">የጥር 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.72</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/eccsa71.pdf" onclick="window.open(this.href,'_blank');return false;" title="የጥር 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.71">የጥር 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.71</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/eccsa-70.pdf" onclick="window.open(this.href,'_blank');return false;" title="የታህሳስ 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.70">የታህሳስ 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.70</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/eccsa-69.pdf" onclick="window.open(this.href,'_blank');return false;" title="የህዳር 30 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.69">የህዳር 30 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.69</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/eccsa-68.pdf" onclick="window.open(this.href,'_blank');return false;" title="የህዳር 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.68">የህዳር 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.68</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/bi-monthly-newsletter/eccsa-67.docx.pdf" onclick="window.open(this.href,'_blank');return false;" title="የህዳር 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.67">የህዳር 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.67</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/bi-monthly-newsletter/eccsa-66.pdf" onclick="window.open(this.href,'_blank');return false;" title="የጥቅምት 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ. 66">የጥቅምት 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ. 66</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/bi-monthly-newsletter/64-eccsa-tikmit.pdf" onclick="window.open(this.href,'_blank');return false;" title="የጥቅምት 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.64">የጥቅምት 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.64</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/meskerem-15-(63).pdf" onclick="window.open(this.href,'_blank');return false;" title="የመስከረም 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.63">የመስከረም 15 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.63</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/meskerem-1-(62).pdf" onclick="window.open(this.href,'_blank');return false;" title="የመስከረም 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.62">የመስከረም 1 ቀን 2006 ዓ.ም. ዜና- መጽሔት ቁ.62</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/nehassie-15(61).pdf" onclick="window.open(this.href,'_blank');return false;" title="የነሀሴ 15 ቀን 2005 ዜና- መጽሔት ቁ.61">የነሀሴ 15 ቀን 2005 ዜና- መጽሔት ቁ.61</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/nehassie1(60).pdf" onclick="window.open(this.href,'_blank');return false;" title="የነሀሴ 1 ቀን 2005 ዜና- መጽሔት ቁ.60">የነሀሴ 1 ቀን 2005 ዜና- መጽሔት ቁ.60</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/hamle-15(59)_4.pdf" onclick="window.open(this.href,'_blank');return false;" title="የሀምሌ 15 ቀን 2005 ዜና- መጽሔት ቁ.59">የሀምሌ 15 ቀን 2005 ዜና- መጽሔት ቁ.59</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/hamle-1(58).pdf" onclick="window.open(this.href,'_blank');return false;" title="የሀምሌ 1 ቀን 2005 ዜና- መጽሔት ቁ.58">የሀምሌ 1 ቀን 2005 ዜና- መጽሔት ቁ.58</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/eccsa-57.pdf" onclick="window.open(this.href,'_blank');return false;" title="የሰኔ 15 ቀን 2005 ዜና- መጽሔት ቁ.57">የሰኔ 15 ቀን 2005 ዜና- መጽሔት ቁ.57</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/sene1(56).pdf" onclick="window.open(this.href,'_blank');return false;" title="የሰኔ 1ቀን 2005 ዜና- መጽሔት ቁ.56">የሰኔ 1ቀን 2005 ዜና- መጽሔት ቁ.56</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/ginbot-1.pdf" onclick="window.open(this.href,'_blank');return false;" title=" የግንቦት 1ቀን 2005 ዜና- መጽሔት ቁ.55"> የግንቦት 1ቀን 2005 ዜና- መጽሔት ቁ.55</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/eccsa-54.pdf" onclick="window.open(this.href,'_blank');return false;" title="የሚያዚያ 30 ቀን 2005 ዜና- መጽሔት ቁ.54">የሚያዚያ 30 ቀን 2005 ዜና- መጽሔት ቁ.54</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/eccsa-53.pdf" onclick="window.open(this.href,'_blank');return false;" title="የሚያዚያ 15 ቀን 2005 ዜና- መጽሔት ቁ.53">የሚያዚያ 15 ቀን 2005 ዜና- መጽሔት ቁ.53</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/megabit-30.pdf" onclick="window.open(this.href,'_blank');return false;" title="የመጋቢት 30 ቀን 2005 ዜና- መጽሔት ቁ.52">የመጋቢት 30 ቀን 2005 ዜና- መጽሔት ቁ.52</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/megabit-15.pdf" onclick="window.open(this.href,'_blank');return false;" title="የመጋቢት 15 ቀን 2005 ዜና- መጽሔት ቁ.51">የመጋቢት 15 ቀን 2005 ዜና- መጽሔት ቁ.51</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/megabit-50.pdf" onclick="window.open(this.href,'_blank');return false;" title="የየካቲት 30 ቀን 2005 ዜና- መጽሔት ቁ.50">የየካቲት 30 ቀን 2005 ዜና- መጽሔት ቁ.50</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/yekatit-49.pdf" onclick="window.open(this.href,'_blank');return false;" title="የየካቲት 15 ቀን 2005 ዜና- መጽሔት ቁ.49 ">የየካቲት 15 ቀን 2005 ዜና- መጽሔት ቁ.49 </a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/publications/yekatit-48.pdf" onclick="window.open(this.href,'_blank');return false;" title="የጥር 30 ቀን 2005 ዜና- መጽሔት ቁ.48 ">የጥር 30 ቀን 2005 ዜና- መጽሔት ቁ.48 </a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/eccsa47.pdf" onclick="window.open(this.href,'_blank');return false;" title="የጥር 15 ቀን 2005 ዜና- መጽሔት ቁ.47 ">የጥር 15 ቀን 2005 ዜና- መጽሔት ቁ.47 </a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/eccsa46.pdf" onclick="window.open(this.href,'_blank');return false;" title="የታህሳስ 30 ቀን 2005 ዜና- መጽሔት ቁ.46">የታህሳስ 30 ቀን 2005 ዜና- መጽሔት ቁ.46</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/eccsa45.pdf" onclick="window.open(this.href,'_blank');return false;" title="የታህሳስ 15 ቀን 2005 ዜና- መጽሔት ቁ.45">የታህሳስ 15 ቀን 2005 ዜና- መጽሔት ቁ.45</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/publications/hidar-30-(44).pdf" onclick="window.open(this.href,'_blank');return false;" title="የህዳር 30 ቀን 2005 ዜና-መጽሔት ቁ.44">የህዳር 30 ቀን 2005 ዜና-መጽሔት ቁ.44</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/publications/hidar-15-(43).pdf" title="የህዳር 15 ቀን 2005 ዜና-መጽሔት ቁ.43">የህዳር 15 ቀን 2005 ዜና-መጽሔት ቁ.43</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/publications/hidar-1-(42).pdf" onclick="window.open(this.href,'_blank');return false;" title="የጥቅምት 30 ቀን 2005 ዜና-መጽሔት ቁ.42">የጥቅምት 30 ቀን 2005 ዜና-መጽሔት ቁ.42</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/publications/tikimt-15(-41).pdf" onclick="window.open(this.href,'_blank');return false;" title="የጥቅምት 15 ቀን 2005 ዜና-መጽሔት ቁ.41">የጥቅምት 15 ቀን 2005 ዜና-መጽሔት ቁ.41</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/meskerem-(40).pdf" title="የመስከረም 30 ቀን 2005 ዜና-መጽሔት ቁ.40">የመስከረም 30 ቀን 2005 ዜና-መጽሔት ቁ.40</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/meskerem-(39).pdf" title="የመስከረም 15 ቀን 2005 ዜና-መጽሔት ቁ.39">የመስከረም 15 ቀን 2005 ዜና-መጽሔት ቁ.39</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/nehassie30(38).pdf" title="የነሀሴ 30 ቀን 2004 ዜና-መጽሔት ቁ.38">የነሀሴ 30 ቀን 2004 ዜና-መጽሔት ቁ.38</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/nehassie-15(37).pdf" title="የነሀሴ 15 ቀን 2004 ዜና-መጽሔት ቁ.37">የነሀሴ 15 ቀን 2004 ዜና-መጽሔት ቁ.37</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/nehase-3(36).pdf" title="የነሀሴ 3 ቀን 2004 ዜና-መጽሔት ቁ.36">የነሀሴ 3 ቀን 2004 ዜና-መጽሔት ቁ.36</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/hamle-16(35).pdf" title="የሀምሌ 16 ቀን 2004 ዜና-መጽሔት ቁ.35">የሀምሌ 16 ቀን 2004 ዜና-መጽሔት ቁ.35</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/hamle-1(34).pdf" title="የሀምሌ 1 ቀን 2004 ዜና-መጽሔት ቁ.34">የሀምሌ 1 ቀን 2004 ዜና-መጽሔት ቁ.34</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://ethiopianchamber.com/Data/Sites/1/sene-5(32).pdf" title="የሰኔ 5 ቀን 200 ዜና-መጽሔት ቁ.32">የሰኔ 5 ቀን 200 ዜና-መጽሔት ቁ.32</a>
                                      
                                </li>
                        
                            </ul>
                    
                    <div class="modulepager">
                    
                    </div>
                <div id="ctl00_mainContent_ctl00_theList_divFooter" class="modulefooter">

</div>
            
	</div>

        
  </div>

</div>
</div>

</Paper>
</Grid>
<Grid item xs={12} md={3}>

     <ResourceLinks />

          {/* </Paper> */}
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
