import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';
import { classExpression } from '@babel/types';
import HomeSlider from '../component/slider/HomeSlider';
import MainMenu from '../component/NavMenus/MainMenu';
import ServiceLinks from '../component/NavMenus/ServiceLinks';
import header from '../images/header.jpg';
import Topbar from '../component/topbar/Topbar';
import 'bootstrap/dist/css/bootstrap.css';
import Welcome from '../component/Welcome/Welcome';
import News from '../component/News/Blog';
import Footer from '../component/Footer/Footer';
// import News from '../component/News/News';

const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  }
}))

function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
      {/* <img            
                
                className="d-block w-70"
                 class="img-fluid"
                 src="./../images/header.jpg" 
                 href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a>
    <MainMenu/> */}

      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
          {/* <MainMenu/> */}
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={12}>

          <News />


        </Grid>

        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
