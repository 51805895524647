

// import { Icon } from '@material-ui/core/HomeIcon';
import ThreeDRotation from '@material-ui/icons/ThreeDRotation';
import {FaFacebookF,FaTwitter, FaGoogle,FaYoutube,FaLinkedin} from "react-icons/fa";
import React, { Component } from 'react';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import './Footer.css'
<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
class Footer extends Component {
    render(){
       return(
<footer class="footer  text-center ">

  <div class="container p-4">
  
    <section class="mb-4">
   
      <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"
        ><i class="fab fa-facebook-f"><FaLinkedin /></i
      ></a>

    
      <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"
        ><i class="fab fa-twitter"><FaTwitter /></i
      ></a>

  
      <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"
        ><i class="fab fa-google"><FaGoogle /></i
      ></a>

    
      <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"
        ><i class="fab fa-instagram"><FaYoutube /></i
      ></a>

      <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"
        ><i class="fab fa-linkedin-in"><FaFacebookF /></i
      ></a>

     
     <ScrollToTop />
    </section>
 
    <section class="">
      <form action="">
      
        <div class="row d-flex justify-content-center">
        
          <div class="col-auto">
            <p class="pt-2">
              <strong>Sign up for our newsletter</strong>
            </p>
            
          </div>
      

      
          <div class="col-md-5 col-12">
          
            <div class="form-outline  mb-4">
              <input type="email" id="form5Example2" class="form-control" />
              <label class="form-label" for="form5Example2">Email address</label>
            </div>
          </div>
        
          <div class="col-auto">
         
            <button type="submit" class="btn btn-success mb-4">
              Subscribe
            </button>
          </div>
      
        </div>
    
      </form>
    </section>
  
    <section class="mb-4">
      <p>
       
      </p>
    </section>
  
    <section class="">
   
      <div class="row">
       
        <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Useful Links</h5>

          <ul class="list-unstyled mb-0">
          <li>
             
             <a href="/" class="link">Survey</a>
           </li>
           <li>
             
             <a href="/" class="link">FAQ</a>
           </li>
            <li>
             
              <a href="/" class="link">Home</a>
            </li>
            <li>
              <a href="/about" class="link">About us</a>
            </li>
            <li>
              <a href="/service" class="link">Services</a>
            </li>
            <li>
              <a href="/membership" class="link">Membership</a>
            </li>
            <li>
              <a href="/resources" class="link">Resources</a>
            </li>
          </ul>
        </div>
      
        <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
          <h5 class="text-uppercase">Important Links</h5>

          <ul class="list-unstyled mb-0">
 							<li>
 								<a href="#" class="link">
 									Training </a>
 							</li>
 							<li>
 								<a href="#" class="link">
 									Arbitration</a>
 							</li>
 							<li>
 								<a href="#" class="link">
 									Trade Facilitation</a>
 							</li>
 							<li>
 								<a href="#" class="link">
 									Networking</a>
 							</li>
 							<li>
 								<a href="#" class="link">
 									Research</a>
 							</li>
          </ul>
        </div>
       
        
        
        <div class="col-lg-4   col-md-6 mb-4 mb-md-0">
     

        <h5 class="text-uppercase">Contact Us</h5>

         <div class="address">
         <ul class="list-unstyled mb-0">
          <p><i class="fas fa-home me-3"></i> Mexico Square</p>
          <p><i class="fas fa-home me-3"></i> Addis Ababa, Ethiopia</p>
          <p><i class="fas fa-phone me-3"></i>Phone: + 251 115 514 005</p>
          <p><i class="fas fa-print me-3"></i> Email:info@ethiopianchamber.com</p>
       </ul>
        </div>
        </div>
      </div>
   
    </section>
  
  </div>
  


  <div class="text-center p-3" >
    {/* © 2021 Copyright: */}
  
  </div>

</footer>
)
    }
}
export default Footer