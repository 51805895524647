import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import './Pages.css';
import '../component/slider/HomeSlider';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.css';

import Footer from '../component/Footer/Footer';
import Maps from '../component/Map/Map';




const useStyle = makeStyles((theme) => ({
    grid: {
        width: '100%',
        margin: '0px',
        padding: '3px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        background: theme.palette.success.light,
    },
    breadcrum: {
      backgroundColor: '#fff',
      padding: '10px',
      marginLeft: '100px',
         }
}))

function App() {

    const classes = useStyle();
    return (
        <div class="mmenu">
            {/* <img            
                
                className="d-block w-70"
                 class="img-fluid"
                 src="../images/header.jpg" 
                 href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a>
    <MainMenu/> */}


            <Grid container spacing={2} className={classes.grid} className="grid">
                <Grid item xs={12} md={12}>
                <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum} >
            <Link color="inherit" to="/" >
              Home
            </Link>

            <Link
              color="textPrimary"
              to="#"
              aria-current="page">
             Contact Us
            </Link>
            </Breadcrumbs>
                </Grid>

                <Grid item xs={1} md={1}>

                    {/* <Welcome /> */}

                </Grid>
                <Grid item xs={12} md={12}>
                    <Paper >
                        <div class="container">
                            <div class="row">
                                <div class="col-md-6">
                                    {/* <div class="well well-sm"> */}
                                    <form>
                                        {/* <div class="row"> */}
                                        {/* <div class="col-md-8"> */}
                                        <div class="form-group">
                                            <label for="name">
                                                Name</label>
                                            <input type="text" class="form-control" id="name" placeholder="Enter name" required="required" />
                                        </div>
                                        <div class="form-group">
                                            <label for="email">
                                                Email Address</label>
                                            <div class="input-group">
                                                <span class="input-group-addon"><span class="glyphicon glyphicon-envelope"></span>
                                                </span>
                                                <input type="email" class="form-control" id="email" placeholder="Enter email" required="required" /></div>
                                        </div>
                                        <div class="form-group">
                                            <label for="subject">
                                                Subject</label>
                                            <select id="subject" name="subject" class="form-control" required="required">
                                                <option value="na" selected="">Choose One:</option>
                                                <option value="service">General Customer Service</option>
                                                <option value="suggestions">Suggestions</option>
                                                <option value="product">Product Support</option>
                                            </select>
                                        </div>
                                        {/* </div>
                    <div class="col-md-6"> */}
                                        <div class="form-group">
                                            <label for="name">
                                                Message</label>
                                            <textarea name="message" id="message" class="form-control" rows="9" required="required"
                                                placeholder="Message"></textarea>
                                        </div>
                                        {/* </div> */}
                                        <div class="form-group">
                                            <button type="submit" class="btn btn-primary pull-right" id="btnContactUs">
                                                Send Message</button>
                                        </div>
                                        {/* </div> */}
                                    </form>
                                    {/* </div> */}
                                </div>
                                <div class="col-md-6">
                                    <form>
                                        <legend><span class="glyphicon glyphicon-globe"></span> Our office</legend>

                                    </form>
                                    <Maps />
                                </div>
                            </div>
                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={12}>
                    {/* <Paper className={classes.paper}>Footer Section</Paper> */}
                    <Footer />
                </Grid>
            </Grid>
        </div>
    );
}

export default App;
