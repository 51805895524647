import React from 'react'
// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     useParams,
//   } from "react-router-dom";
   import { Navbar,Card, Nav,NavDropdown,Form,FormControl,Button } from 'react-bootstrap'
  import './MainMenu.css'
//   import Home from './Home';
//   import AboutUs from './AboutUs';
//   import ContactUs from './ContactUs';
const linkStyle = {
	margin: "1rem",
	textDecoration: "none",
	color: 'green'
  
  };
class QuickLinks extends React.Component{

    render(){
        return(
			<Card text="white" >
			<Card.Header>Membership</Card.Header>
			<Card.Body>
			  <Card.Text className={linkStyle}>
 

					<li class="text-green">
						<a href="/membership/chambersystem">
						The Chamber System</a>
						
					</li>
					<li class="text-green">
						<a href="/membership/chamberstructure">
						Structure of the Chamber System</a>
					</li>
					<li class="text-green">
						<a href="/research-advocacy">
							ECCSA’s Members</a>
						
			
				</li>
				<li class="text-green" >
					<a href="#">
						Membership Benefit
					</a>
				</li>
				</Card.Text>
          </Card.Body>
        </Card>
        )  
    }
}

export default QuickLinks;