import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import './Pages.css';
import '../component/slider/HomeSlider';
import { Row, Col } from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.css';

import Footer from '../component/Footer/Footer';

const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
       }
}))
function handleClick(event) {
  event.preventDefault();

}
function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
          <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum}>
             
             <Link  to="/" > Home</Link>
             <Link  eventKey="about" to="/aboutet" > About Ethiopina</Link>
         
           </Breadcrumbs>
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={8}>

        <h3>About Ethiopia </h3>
        <Row>
    <Col><div class="card" style={{ width: '18rem' }}>
  <img class="card-img-top" src="http://ethiopianchamber.com/Data/Sites/1/2012%20EC/About%20Ethiopia/President.png" height="200px" alt="Card image cap"></img>
  <div class="card-block">
    <h4 class="card-title">Head of State</h4>
    <p class="card-text">President - Sahle-Work Zewde, (Parliament elected Sahle-Work Zewde as the Ethiopia's first woman president in October 2018.)</p>
    {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
  </div>
</div></Col>
    <Col><div class="card"style={{ width: '18rem' }} >
  <img class="card-img-top" src="http://ethiopianchamber.com/Data/Sites/1/2012%20EC/About%20Ethiopia/PM2.jpg" height="200px"  alt="Card image cap"></img>
  <div class="card-block">
    <h4 class="card-title">Head of Government</h4>
    <p class="card-text">
    Prime Minister – Dr. Abiy Ahmed
    </p></div>
</div></Col>
  </Row>
  <p>  <strong>Location</strong>:&nbsp; Eastern Africa, west of Somalia</p>
<p>
	<strong>Geography</strong>: Eastern Africa, west of Somalia</p>
<p>
	<strong>Geographic coordinates</strong>: 8 00 N, 38 00 E</p>
<p>
	<strong>Map references</strong>: Africa</p>
<p>
	<strong>Time difference</strong>: UTC+3</p>
<p>
	<strong><a href="https://www.cia.gov/library/publications/the-world-factbook/docs/notesanddefs.html#279">Area</a></strong>:</p>
<p>
	total:&nbsp;1,104,300 sq km&nbsp;</p>
<p>
	land:&nbsp;1,096,570 sq km&nbsp;</p>
<p>
	water:&nbsp;7,730 sq km&nbsp;</p>
<p>
	<strong>Population</strong>:&nbsp;108,386,391 (July 2018 est.) the second most populous country in Africa and the 13th in the world.</p>
<p>
	<strong>Urban population</strong>:&nbsp;20.8% of total population&nbsp;(2018)</p>
<p>
	<strong>Rate of urbanization</strong>:&nbsp;4.63% annual rate of change&nbsp;(2015-20 est.)</p>
<p>
	Addis Ababa (capital)&nbsp;population: 4.4 million (2018)</p>
  
        

        </Grid>
        <Grid item xs={12} md={3}>
          <Paper >
            {/* <ServiceLinks /> */}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
