import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.css';
import Welcome from '../component/Welcome/Welcome';
import News from '../component/News/News';
import Footer from '../component/Footer/Footer';
import ResourceLinks from '../component/NavMenus/ResourceLinks';
const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
       }
}))

function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
    

      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum} >
                <Link color="inherit" to="/" >
              Home
            </Link>

            <Link
              color="textPrimary"
              to="#"
              
              aria-current="page">
              Important Links 
            </Link>
            </Breadcrumbs>
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={8}>
          <Paper ><div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">


                  <h3>
                  Government</h3>
                    <ul class="linkitem">
                        
                        <li class="linkitem">
                            <a class="mojolink" href="http://www.pmo.gov.et/" onclick="window.open(this.href,'_blank');return true;" title="Office of the Prime Minister">Office of the Prime Minister</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="https://www.business.gov.et/business/home" onclick="window.open(this.href,'_blank');return true;" title="Ethiopian Business Portal">Ethiopian Business Portal</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://www.ethiopia.gov.et" onclick="window.open(this.href,'_blank');return true;" title="Ethiopian Government Portal (All Government Offices)">Ethiopian Government Portal (All Government Offices)</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://www.investethiopia.gov.et/" onclick="window.open(this.href,'_blank');return true;" title="Ethiopian Investment Agency">Ethiopian Investment Agency</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://cn.investethiopia.gov.et/" onclick="window.open(this.href,'_blank');return true;" title="Ethiopian Investment Commission (Chinese Investers)">Ethiopian Investment Commission (Chinese Investers)</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://www.erca.gov.et" onclick="window.open(this.href,'_blank');return true;" title="Ethiopian Revenues and Customs Authority">Ethiopian Revenues and Customs Authority</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://www.ethiostandards.org/Default.aspx" onclick="window.open(this.href,'_blank');return true;" title="Ethiopian Standard Agency (ESA)">Ethiopian Standard Agency (ESA)</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://www.mofed.gov.et/English/Pages/Home.aspx" onclick="window.open(this.href,'_blank');return true;" title="Ministry of Finanace and Economic Development">Ministry of Finanace and Economic Development</a>
                                  
                            </li>
                    
                        </ul>

                  <hr />
                  <h4 >
                  Ethiopian Shipping & Air Lines</h4>
                    <ul class="linkitem">
                        
                        <li class="linkitem">
                            <a class="mojolink" href="http://www.ethiopianairlines.com" onclick="window.open(this.href,'_blank');return true;" title="Ethiopian Airlines ">Ethiopian Airlines </a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://www.ethiopianshippinglines.com.et" onclick="window.open(this.href,'_blank');return true;" title="Ethiopian Shipping Lines">Ethiopian Shipping Lines</a>
                                  
                            </li>
                    
                        </ul>
                        <h4 >
                        Finances</h4>
                        <ul class="linkitem">
                        
                        <li class="linkitem">
                            <a class="mojolink" href="http://www.awash-international-bank.com" onclick="window.open(this.href,'_blank');return true;" title="Awash International Bank">Awash International Bank</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://www.bankofabyssinia.com " onclick="window.open(this.href,'_blank');return true;" title="Bank of Abyssinia ">Bank of Abyssinia </a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://www.dashenbanksc.com " onclick="window.open(this.href,'_blank');return true;" title="Dashen Bank ">Dashen Bank </a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://www.combanketh.com/" onclick="window.open(this.href,'_blank');return true;" title="Commercial Bank of Ethiopia">Commercial Bank of Ethiopia</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://www.dbe.com.et " onclick="window.open(this.href,'_blank');return true;" title="Development Bank of Ethiopia ">Development Bank of Ethiopia </a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://www.nbe.gov.et" onclick="window.open(this.href,'_blank');return true;" title="National Bank of Ethiopia, ">National Bank of Ethiopia, </a>
                                  
                            </li>
                    
                        </ul>
                        <h4 >
                        National & International Chambers</h4>
                        <ul class="linkitem">
                        
                        <li class="linkitem">
                            <a class="mojolink" href="www.cfcib.org" onclick="window.open(this.href,'_blank');return true;" title="Chambre Fédérale de Commerce et d’Industrie du Burundi">Chambre Fédérale de Commerce et d’Industrie du Burundi</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://www.iccwbo.org/" onclick="window.open(this.href,'_blank');return true;" title="International Chamber of Commerce">International Chamber of Commerce</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://www.ascci.info/#" onclick="window.open(this.href,'_blank');return true;" title="Association of SADC Chambers of Commerce and Industry (ASCCI)">Association of SADC Chambers of Commerce and Industry (ASCCI)</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://www.pacci.org/en/index.html" onclick="window.open(this.href,'_blank');return true;" title="Pan African Chamber of Commerce &amp; Industry">Pan African Chamber of Commerce &amp; Industry</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://www.zncc.co.zw/" onclick="window.open(this.href,'_blank');return true;" title="Zimbabwe National Chamber of Commerce">Zimbabwe National Chamber of Commerce</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://www.chamberuganda.com/" onclick="window.open(this.href,'_blank');return true;" title="Uganda National Chamber of Commerce and Industry">Uganda National Chamber of Commerce and Industry</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://www.somalilandchamber.com/" onclick="window.open(this.href,'_blank');return true;" title="Somaliland Chamber of Commerce, Industry &amp; Agriculture ">Somaliland Chamber of Commerce, Industry &amp; Agriculture </a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://www.sudabiz.org/" onclick="window.open(this.href,'_blank');return true;" title="Sudanese Businessmen &amp; Employers Federation">Sudanese Businessmen &amp; Employers Federation</a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://www.kenyachamber.co.ke/" onclick="window.open(this.href,'_blank');return true;" title="Kenya National Chamber of Commerce and Industry">Kenya National Chamber of Commerce and Industry</a>
                            
                            </li>
                    
                        <li class="linkaltitem">
                            <a class="mojolink" href="http://www.nafcoc.org.za/en/index-en.html" onclick="window.open(this.href,'_blank');return true;" title="National African Federated Chamber of Commerce and Industry (NAFCOC) ">National African Federated Chamber of Commerce and Industry (NAFCOC) </a>
                                  
                            </li>
                    
                        <li class="linkitem">
                            <a class="mojolink" href="http://www.sacci.org.za/" onclick="window.open(this.href,'_blank');return true;" title="South African Chamber of Commerce and Industry (SACCI)">South African Chamber of Commerce and Industry (SACCI)</a>
                            
                            </li>
                    
                        </ul>

                        <h4 >
                        Accommodations</h4>
                        <ul class="linkitem">
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://www.ghionhotel.com.et " onclick="window.open(this.href,'_blank');return true;" title="Ghion Hotel">Ghion Hotel</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://www.harmonyhotelethiopia.com" onclick="window.open(this.href,'_blank');return true;" title="Harmony Hotel">Harmony Hotel</a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://www.hilton.com" onclick="window.open(this.href,'_blank');return true;" title="Hilton Addis Ababa">Hilton Addis Ababa</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://www.Jupiterinternationalhotel.com" onclick="window.open(this.href,'_blank');return true;" title="Jupiter Hotel ">Jupiter Hotel </a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://www.radissonblu.com/hotel-addisababa" onclick="window.open(this.href,'_blank');return true;" title="Radisson Blu">Radisson Blu</a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://www.luxurycollection.com/addis" onclick="window.open(this.href,'_blank');return true;" title="Sheraton Addis Ababa ">Sheraton Addis Ababa </a>
                                      
                                </li>
                        
                            </ul>
                            <h4 >
                            Tour Operators </h4>
                            <ul class="linkitem">
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://www.abyssiniantours.com" onclick="window.open(this.href,'_blank');return true;" title="Abyssinian Tours ">Abyssinian Tours </a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://www.moderneth.com/index.html " onclick="window.open(this.href,'_blank');return true;" title="Altour Ethiopia ">Altour Ethiopia </a>
                                      
                                </li>
                        
                            <li class="linkitem">
                                <a class="mojolink" href="http://www.ethiopiatravel.com" onclick="window.open(this.href,'_blank');return true;" title="Ethiopia Travel ">Ethiopia Travel </a>
                                
                                </li>
                        
                            <li class="linkaltitem">
                                <a class="mojolink" href="http://www.ethiopianriftvalleysafaris.com" onclick="window.open(this.href,'_blank');return true;" title="Ethiopian Rift Valley Safaris">Ethiopian Rift Valley Safaris</a>
                                      
                                </li>
                        
                            </ul>
                </div>

              </div>
            </div>
          </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          {/* <Paper > */}
          {/* <h5 class="text-uppercase">Quick Links</h5>

<ul class="list-unstyled mb-0">
 
  <li>
    <a href="#!" class="text-green"> Organizational Structure</a>
  </li>
    <li>
    <a href="#!" class="text-green">  General Assembly</a>
  </li>
    <li>
    <a href="#!" class="text-green">  Board of Directors</a>
  </li>
    <li>
    <a href="#!" class="text-green">  The Secretariat</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Achievements</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Former Presidents & Secretary G.</a>
  </li>
    <li>
    <a href="#!" class="text-green">Our Location</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Our Partners</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Strategic Plan</a>
  </li>
    <li>
    <a href="#!" class="text-green"> Chamber Law (+Directives)</a>
  </li>
    <li>
    <a href="#!" class="text-green"> ECCSA Projects</a>
  </li>


</ul> */}
          <ResourceLinks />

          {/* </Paper> */}
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
