import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import './App.css';
// ort 'imp./component/slider/HomeSlider';
import 'bootstrap/dist/css/bootstrap.css';
import Home from './pages/index';
import AboutPage from './pages/about';
import MembershipPage from './pages/membership';
import ChambersystemPage from './pages/chambersystem';
import ChamberstructurePage from './pages/chamberstructure';
import assembly from './pages/assembly';
import team from './pages/team';
import board from './pages/board';
import contact from './pages/contact';
import tradeinvestment from './pages/trade&investment';
import capacity from './pages/capacitybuilding';
import services from './pages/services';
import research from './pages/researchadvocacy';
import news from './pages/News';
import news1 from './pages/News1';
import news2 from './pages/News2';
import news3 from './pages/News3';
import news4 from './pages/News4';
import eresources from './pages/eResources';
import eresources1 from './pages/eResources1';
import eresources2 from './pages/eResources2';
import eresources3 from './pages/eResources3';
import eresources4 from './pages/eResources4';
import eresources5 from './pages/eResources5';
import login from './pages/login/Login';
// import arbitration from './pages/login/Arbitration';
import counseling from './pages/login/LegalCounseling';
import membership1 from './pages/login/Membership';
import membership2 from './pages/login/Membership2';
import register from './pages/login/Register';
import promotion from './pages/login/promotion';
import commercialInvoice from './pages/login/CommercialInvoice'
import users from './pages/index2';
import membership from './pages/membership';
import events from './pages/Events';
import location from './pages/location';
import partners from './pages/partners';
import splan from './pages/splan';
import chamberlaw from './pages/Chamberlaw';
import projects from './pages/Projects';
import MainMenu from './component/NavMenus/MainMenuy';
import arbitration from './pages/Arbitration';
import arbitration2 from './pages/Arbitration2';
import Opportunities from './pages/p404';
import tradefair from './pages/Tradefair';
import bopportunity from './pages/Bopportunity';
import training from './pages/Training';
import aboutet from './pages/AboutET';
import FormerPresident from './pages/formerPresident';
import { presidents, secretaries } from './dummyData';
function App() {
       // const classes=useStyle();
       return (
              <Router>
                     <MainMenu />
                     <Switch>
                            <Route exact path="/">
                                   <Home />
                            </Route>
                            <Route path="/membership" component={membership}>
                            </Route>
                            < Route path="/Opportunities" component={Opportunities}>
                            </Route>
                            <Route path="/membership/chambersystem">
                                   <ChambersystemPage />
                            </Route>
                            <Route path="/mchamberstructure">
                                   <ChamberstructurePage />
                            </Route>
                            <Route path="/about" component={AboutPage}>
                                   <AboutPage />
                            </Route>
                            <Route path="/former">
                                   <FormerPresident presidents={presidents} secretaries={secretaries} />
                            </Route>
                            <Route path="/aboutet" component={aboutet}>
                            </Route>
                            <Route path="/assembly" component={assembly}>
                            </Route>
                            <Route path="/team" component={team}>
                            </Route>
                            <Route path="/training" component={training}>
                            </Route>
                            <Route path="/bopportunity" component={bopportunity}>
                            </Route>
                            <Route path="/tradefair" component={tradefair}>
                            </Route>
                            <Route path="/aboutet" component={aboutet}>
                            </Route>
                            <Route path="/partners" component={partners}>
                            </Route>
                            <Route path="/projects" component={projects}>
                            </Route>
                            <Route path="/chamberlaw" component={chamberlaw}>
                            </Route>
                            <Route path="/users" component={users}>
                            </Route>
                            <Route path="/splan" component={splan}>
                            </Route>
                            <Route path="/register" component={register}>
                            </Route>
                            <Route path="/board" component={board}>
                            </Route>
                            <Route path="/contact" component={contact}>
                            </Route>
                            <Route path="/services" component={services}>
                            </Route>
                            <Route path="/trade-investment" component={tradeinvestment}>
                            </Route>
                            <Route path="/capacity-building" component={capacity}>
                            </Route>
                            <Route path="/research-advocacy" component={research}>
                            </Route>
                            <Route path="/login" component={login}>
                            </Route>
                            <Route path="/membership1" component={membership1}>
                            </Route>
                            <Route path="/membership2" component={membership2}>
                            </Route>
                            <Route path="/commercialInvoice" component={commercialInvoice}>
                            </Route>
                            <Route path="/promotion" component={promotion}>
                            </Route>
                            <Route path="/counseling" component={counseling}>
                            </Route>
                            <Route path="/arbitration" component={arbitration}>
                            </Route>
                            <Route path="/news" component={news}>
                            </Route>
                            <Route path="/news1" component={news1}>
                            </Route>
                            <Route path="/news2" component={news2}>
                            </Route>
                            <Route path="/location" component={location}>
                            </Route>
                            <Route path="/news3" component={news3}>
                            </Route>
                            <Route path="/news4" component={news4}>
                            </Route>
                            <Route path="/events" component={events}>
                            </Route>
                            <Route path="/eresources" component={eresources}>
                            </Route>
                            <Route path="/eresources1" component={eresources1}>
                            </Route>
                            <Route path="/eresources2" component={eresources2}>
                            </Route>
                            <Route path="/eresources3" component={eresources3}>
                            </Route>
                            <Route path="/eresources4" component={eresources4}>
                            </Route>
                            <Route path="/eresources5" component={eresources5}>
                            </Route>
                            <Route path="/ChamberstructurePage" component={ChamberstructurePage}>
                            </Route>
                            <Route path="/membership/chamberstructure">
                                   <MembershipPage />
                                   <ChamberstructurePage />
                            </Route>
                            <Route path="/membership">
                                   <MembershipPage />
                            </Route>
                     </Switch>
              </Router>
       );
}

export default App;
