// import { Component } from 'react';
import React, { Component } from "react";

import { Breadcrumb,Nav, Container, Carousel, Button, Row, Col, Form } from "react-bootstrap";

import './Event2.css'


class Events extends Component{
    

  render(){  
  
    return (
        <Carousel variant="light">
        <Carousel.Item>
          <img
           style={{'height':"350px"}}  
            className="d-block w-100"
            src="https://pbs.twimg.com/media/E3043qPWUAIl_5S.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
          <h3>New Year Exhibition </h3>
						<p>
							Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          
            <button class="Primary"> Click to View Detail</button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            style={{'height':"350px"}}  
            className="d-block w-100"
            src="https://pbs.twimg.com/media/E3043qPWUAIl_5S.jpg"
            alt="Second slide"
          />
          <Carousel.Caption>
            <h5>Meskel Expo </h5>
            <button class="Primary"> Click to View Detail</button>
          </Carousel.Caption>
        </Carousel.Item>
       
      </Carousel>  
    )
}}

export default Events