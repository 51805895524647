import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { BrowserRouter as Router, Route, Swithch, Link, Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.green,
    padding: theme.spacing(2, 0, 1),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxWidth:'100%',
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', 
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor:'#fff',
    padding: theme.spacing(1),
  },
  title:{
    color: '#198754',
    fontWeight: '500',
    fontStyle: 'normal',
    textAlign:'center',
   },
  paper:{ 
    padding:theme.spacing(1),
    textAlign:'center',
    color: '#000',

    background:'#fff',
  },
}));

// const cards = [1, 2, 3, 4];

export default function Album() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      
      <main>
        
        <Container className={classes.cardGrid} >
          {/* End hero unit */}
          <Grid container spacing={1}>
            {/* {cards.map((card) => ( */}
              <Grid item  xs={12} sm={6} md={3}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="../../images/be.jpg"
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h6" className={classes.title}>
                    S.G. Welcomes Belgian Ambassador                 </Typography>
                    <Typography class="body">
                    Secretary General (S.G.) Yesuf Ademnur of the Ethiopian Chamber of Commerce and Sectoral Associations (ECCSA) had                     </Typography>
                  </CardContent>
                  <CardActions>
                    
                  <Link  eventKey="news1" class="btn btn-outline-success" to="/news1" > Read More</Link> 
                  </CardActions>
                </Card>
                
              </Grid>
              <Grid item  xs={12} sm={6} md={3}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="../../images/sri.jpg"
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h6" className={classes.title} component="h2">
                    ECCSA Receives Sri Lankan Delegation                </Typography>
                    <Typography class="body">
                    Melaku Ezezew (Eng.), President of the Ethiopian Chamber of Commerce and Sectoral Associations (ECCSA), along with                     </Typography>
                  </CardContent>
                  <CardActions>
                  <Link  eventKey="news2" class="btn btn-outline-success" to="/news2" > Read More</Link> 
                   </CardActions>
                </Card>
                
              </Grid>
              <Grid item  xs={12} sm={6} md={3}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="../../images/I.jpg"
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h6" className={classes.title}>
                    ITME Africa 2020 Officially Opened           </Typography>
                    <Typography class="body">
                    The inauguration of the International Textile Machinery and Engineering Exhibition (ITME-Africa 2020) which is                     </Typography>
                  </CardContent>
                  <CardActions>
                    
                  <Link  eventKey="news3" class="btn btn-outline-success" to="/news3" > Read More</Link> 
                  </CardActions>
                </Card>
                
              </Grid>
              <Grid item  xs={12} sm={6} md={3}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="../../images/cca.jpg"
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h6" className={classes.title}>
                    ECCSA-CCA: Bilateral Talk on Business Collaboration             </Typography>
                    <Typography class="body">
                    The Ethiopian Chamber of Commerce and Sectoral Associations (ECCSA) and Corporate Council on                     </Typography>
                  </CardContent>
                  <CardActions>
                    
                  <Link  eventKey="news4" class="btn btn-outline-success" to="/news4" > Read More</Link> 
                  </CardActions>
                </Card>
                
              </Grid>
              
            {/* ))} */}
            <Grid item xs={12} md={6}>
        
      </Grid>
      <Grid item xs={12} md={6}>
      <Button variant="outlined" class="btn btn-success" href="/news"  className={classes.margin}>
                    More News
                  </Button> 
      </Grid>
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      
      {/* End footer */}
    </React.Fragment>
  );
}