import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import './Pages.css';
import '../component/slider/HomeSlider';
import 'bootstrap/dist/css/bootstrap.css';
import { Tabs, Tab } from "react-bootstrap";
import Footer from '../component/Footer/Footer';

const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  main: {
    width: '100%',
    background: '#fff',
    padding: '10px',


  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
  }
}))
function handleClick(event) {
  event.preventDefault();

}


function App() {
  const classes = useStyle();
  return (
    <div class="mmenu root">
      <div className={classes.main}>
        <Grid container spacing={2} className={classes.grid} className="grid">
          <Grid item xs={12} md={12}>
          <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum} >
            <Link color="inherit" to="/" >
              Home
            </Link>

            <Link
              color="textPrimary"
              to="#"
              aria-current="page">
             Business to Busuness 
            </Link>
            </Breadcrumbs>
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="container">
              <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="home" title="Ethiopian Women Experts">
                <p>
			Name: ADILA ISMAIL<br />
			Sector: Handicraft<br />
			Tel: 0115529999<br />
			Mobile: 0911600559<br />
			E-mail: Adifashion100@yahoo.com</p>
		<p>
			Name: ASNAKECH THOMAS<br />
			Sector: Coffee<br />
			Tel: 0116462820<br />
			Mobile: 0911216323<br />
			E-mail: thaomas@ethionet.et</p>
		<p>
			Name: ASTER MENGESHA<br />
			Sector: Coffee<br />
			Tel: 0115500913<br />
			Mobile: 0911218908<br />
			E-mail: asterbun@ethionet.et</p>
		<p>
			Name: ASTER RETTA<br />
			Sector: Handicraft Textile<br />
			Tel: 0113728080<br />
			Mobile: 0911216944<br />
			Fax: 0114652444<br />
			E-mail: asmabdella@yahoo.com</p>
		<p>
			Name: ABAYNESH BEYENE<br />
			Sector: Leather<br />
			Mobile: 0911239485 or 0911248876<br />
			E-mail: Abyi2003@yahoo.com</p>
		<p>
			Name: AYENALEM AYALE<br />
			Sector: Handicrafts<br />
			Mobile: 0911407037<br />
			E-mail: Aynisdesign@gmail.com</p>
		<p>
			Name: ATSEDE TEKELE<br />
			Sector: Agriculture &amp;Food<br />
			Mobile: 0912168611 or 0912149099</p>
		<p>
			Name: AMELEWORK SETEGN<br />
			Sector: Precious stone<br />
			Mobile: 0924859060 or 0911609176<br />
			E-mail: Setegne.amelework@yahoo.com</p>
		<p>
			Name: ABEBA ZENEBE<br />
			Sector: Textile<br />
			Mobile: 0916828905<br />
			E-mail: wolleabe@uahoo.com</p>
		<p>
			Name: AZALECH TESEFAYE<br />
			Sector: Coffee<br />
			Mobile: 0911633322 or 0911639614<br />
			E-mail: Omanuelkebede53@yahoo.com</p>
		<p>
			Name: BETHLEHEM TILAHUNE<br />
			Sector: Leather, Textile &amp; Handicraft<br />
			Mobile: 0911110848<br />
			E-mail: rootsculturetires@gmail.com</p>
		<p>
			Name: BIRTUKAN ALEMAYEHU<br />
			Sector: Honey<br />
			Mobile: 0911230596</p>
		<p>
			Name: BEKELESHE TOLA<br />
			Sector: Organic foods<br />
			Mobile: 0911840985<br />
			E-mail: bekeboku@yahoo.com</p>
		<p>
			Name: DEHAB MESFIN<br />
			Sector: Coffee<br />
			Tel: 0116292614<br />
			Mobile: 0930014502<br />
			Fax: 0116292614<br />
			E-mail: Diamondnt80@yahoo.com</p>
		<p>
			Name: ELZABETH KASSA<br />
			Sector: Handicraft<br />
			Tel: 0114400752<br />
			Mobile: 0911641949 or 0911679359<br />
			E-mail: kassaesa@hotmal.com or akmossie@hotail.com</p>
		<p>
			Name: ELZABETH TAYE<br />
			Sector: Textile<br />
			Tel: 0116628682/83<br />
			Mobile: 0911204643<br />
			E-mail: elsat@ethionet.et</p>
		<p>
			Name: EMEBET TAFESSE<br />
			Sector: Coffee<br />
			Tel: 0114404128<br />
			Mobile: 0911229380<br />
			Fax: 0114403418 or 0114421714<br />
			E-mail: director@zebadgei.com or emu.taf@ethionet.et</p>
		<p>
			Name: ELZABETH BELAY<br />
			Sector: Homemade foods<br />
			Mobile: 0915737189<br />
			E-mail: Elsabelay891@yahoo.com</p>
		<p>
			Name: EJIGAYEHU H/GIORGIS<br />
			Sector: Textile<br />
			Tel: 0114668004<br />
			Mobile: 0911220889<br />
			E-mail: ejigtebeb@yahoo.com</p>
		<p>
			Name: FREZWED MENGESHA<br />
			Sector: Textile products<br />
			Mobile: 0911600017 or 0910297698<br />
			E-mail: frehandcraft@yahoo.com</p>
		<p>
			Name: FIKIRTE KORCHO<br />
			Mobile: 0910194225 or 0915732327<br />
			E-mail: fikiietkoriche@gmail.com</p>
		<p>
			Name: FIKIRTE ADDIS<br />
			Sector: Handicrafts, Textile Products<br />
			Tel: 0118601052<br />
			Mobile: 0911210478 or 0930035109<br />
			E-mail: fikirtea@yetikirdasign.com</p>
		<p>
			Name: GENET KEBEDE<br />
			Sector: Textile<br />
			Tel: 0116522872<br />
			Mobile: 0911207981<br />
			Fax: 0114197376<br />
			E-mail: gmmgar@ethionet.et</p>
		<p>
			Name: GENET BERHE<br />
			Sector: Enjera<br />
			Mobile: 0913547621<br />
			E-mail: Info@merobinjera.com</p>
		<p>
			Name: GELAYE DEYAS<br />
			Sector: Handicraft<br />
			Mobile: 0911863174<br />
			E-mail: gelayedeyas@gamil.com</p>
		<p>
			Name: HADIA MOHAMMED GONJI<br />
			Sector: Flowers<br />
			Tel: 0116513365 or 0116513969<br />
			Mobile: 0911201230 or 0911502157<br />
			Fax: 0114671794<br />
			E-mail: hadiamg@yahoo.com</p>
		<p>
			Name: HIRUT ZELEKE<br />
			Sector: Leather<br />
			Mobile: 0911615876<br />
			E-mail: hirutzel@yahoo.com</p>
		<p>
			Name: HAYAT TUHA<br />
			Sector: Handicrafts, Textile Products<br />
			Tel: 0115525523<br />
			Mobile: 0911636211<br />
			E-mail: info@dondoorhandcraft.com</p>
		<p>
			Name: KATHY MARSHALL<br />
			Sector: Textile products<br />
			Tel: 0113215112<br />
			Mobile: 0911217948<br />
			E-mail: sabahar@gmail.com</p>
		<p>
			Name: KIDEST SHITA<br />
			Sector: Textile<br />
			Mobile: 0911563576<br />
			E-mail: kidestshita@yahoo.com</p>
		<p>
			Name: MARY NALBANDIAN<br />
			Sector: Leather<br />
			Tel: 0115539428/29<br />
			Fax: 0115536593<br />
			E-mail: Bale.plc@ethionet.te</p>
		<p>
			Name: MESEGANNA G/EGZIABHRE<br />
			Sector: Leather shoes<br />
			Tel: 0114650763<br />
			Mobile: 0911026830<br />
			E-mail: Tsegayege2005@yahoo.com</p>
		<p>
			Name: MARTA DEBOCH<br />
			Sector: Textile<br />
			Mobile: 0930034927<br />
			E-mail: yarrowhandwouen@gmail.com</p>
		<p>
			Name: MULUEMEBET GEBEYEHU<br />
			Sector: Honey and Honey<br />
			Mobile: 0911968080 or 0931709592<br />
			E-mail: info@mulutej.com</p>
		<p>
			Name: MARTA KASSA<br />
			Sector: Agriculture and Food<br />
			Tel: 011272871<br />
			Mobile: 0911676137<br />
			E-mail: marthakassa@yahoo.com</p>
		<p>
			Name: LULIT MEKONNEN<br />
			Sector: Handicraft (bamboo)<br />
			Mobile: 0922095195<br />
			E-mail: Lulitmekonnen@yahoo.com</p>
		<p>
			Name: RAHEL ZEWDE<br />
			Sector: Textile<br />
			Tel: 0114655652<br />
			Mobile: 0911227073<br />
			Fax: 0114667656<br />
			E-mail: kdesignk@ethionet.et</p>
		<p>
			Name: RAHEL ZEWDE<br />
			Sector: Coffee<br />
			Mobile: 0911203767<br />
			E-mail: Zewdiera@yahoo.com</p>
		<p>
			Name: SALPI NALBANDIAN<br />
			Sector: Leather<br />
			Tel: 0115500609<br />
			Mobile: 0911204924<br />
			Fax: 0115536593<br />
			E-mail: Bale.plc@ethionet.et</p>
		<p>
			Name: SELAMAWIT MEURIA<br />
			Sector: Leather<br />
			Mobile: 0911464343 or 0911602428<br />
			E-mail: tefse99@yahoo.com</p>
		<p>
			Name: SHITAYE KNIFE<br />
			Sector: Textile<br />
			Tel: 0116546674<br />
			Mobile: 0911165560<br />
			E-mail: Shitaye-2005@yahoo.com</p>
		<p>
			Name: SASKIA KLOEZEMAN<br />
			Sector: Consultant of EWEA<br />
			Mobile: 0911815059<br />
			E-mail: Saskia.kloezeman@pum.nl</p>
		<p>
			Name: SEFANIT AMDE<br />
			Sector: Fruits/Vegetables<br />
			Mobile: 0911169036 or 0922826968<br />
			E-mail: sefanit.amde@yahoo.com</p>
		<p>
			Name: TEMINIT ABHAHA<br />
			Sector: Oil seeds&amp; textile<br />
			Mobile: 0911486846</p>
		<p>
			Name: TIGIST DAMTEW<br />
			Sector: Handicraft<br />
			Tel: 0115522318<br />
			Mobile: 0911977486<br />
			E-mail: Tandhesigns@gmail.com</p>
		<p>
			Name: TESFANESH MITIKU<br />
			Sector: Textile<br />
			Mobile: 0911868998<br />
			E-mail: tesfaneshmitiku5@gmail.com or tesfaneshmitiku@yahoo.com</p>
		<p>
			Name: WUDASSIES ENQUBERHAN<br />
			Sector: Handicraft<br />
			Tel: 0111569650<br />
			Mobile: 0911605338<br />
			E-mail: w-souvenirs@ethionet.et</p>
		<p>
			Name: WOINSHET TOLOSA |<br />
			Sector: Leather<br />
			Mobile: 0911359378</p>
		<p>
			Name: YORDANOS ABATE<br />
			Sector: Handicraft &amp; paint<br />
			Mobile: 0911912514<br />
			E-mail: yordanosabte@yahoo.com</p>
		<p>
			Name: YABUNE TELAKE<br />
			Sector: Homemade foods<br />
			Mobile: 0911439758</p>
		<p>
			Name: Aster Tsegaye<br />
			Sector: Textile<br />
			Mobile: 0930012628<br />
			E-mail: astubosten@yahoo.com</p>
		<p>
			Name: Zewditu Desalegne<br />
			Sector: Leather<br />
			Mobile: 0911313930</p>
                </Tab>
                <Tab eventKey="profile" title="Ethiopian Campanies">
               <h5> <u>Joint Venture Applicant</u></h5>
		<p>
			Name: Edao International Trading<br />
			Sector: Export of Agricultural products<br />
			Interest: Value addition of agro processing<br />
			Tel;0221123132<br />
			Mob:0911490696<br />
			Email:info@edaotrading.com<br />
			Capital: 24,000,000<br />
			Ownership: Sole proprietorship<br />
			Employee: 83<br />
			<br />
			Name: Belayneh Kinde Import and export<br />
			Sector: Import, Export, Transport<br />
			Interest: Agro processing, Import, Export<br />
			Tel:0111118740<br />
			Fax: 0111118607<br />
			Mob:0911204882<br />
			Email:belaynehkindie@gmail.com<br />
			Capital: 50,000,000<br />
			Ownership: Sole proprietorship<br />
			Employee: 1500<br />
			<br />
			Name: Vital Pharmaceuticals plc<br />
			Sector: Pharmaceuticals Import<br />
			Interest: Pharmaceuticals production<br />
			Tel: 0911539251<br />
			Mob: 0930011255<br />
			Fax 0111550037<br />
			Email: vitalpharmaceuticals@yahoo.com<br />
			Capital: 5,400,000<br />
			Ownership: PLC<br />
			Employee: 20<br />
			<br />
			Name: Kaleb Service Farmers house PLC<br />
			Sector: Import<br />
			Interest: Automotive &amp; motor cycle, Agro processing, small scale agriculture machinery<br />
			Tel: 0114391459<br />
			Fax:0114393674<br />
			Mob:0920497938<br />
			Email:Michael@kalebservice.com<br />
			Capital: 15,000,000<br />
			Ownership: PLC<br />
			Employee: 97<br />
			<br />
			Name: Sisay Investment Group<br />
			Sector: Manufacturing, Real Estate, Construction<br />
			Interest: Automobile and motor cycle<br />
			Tel:0113870845<br />
			Fax:0113870846<br />
			Mob:0911206123<br />
			Email:sisay41@yahoo.com<br />
			Capital: 3,210,000,000<br />
			Ownership: PLC<br />
			Employee: 1458<br />
			<br />
			Name: Bahran Trading plc<br />
			Sector: Export, Import, Manufacturing, Transport<br />
			Interest: Agro processing, Automobile &amp; motor cycle<br />
			Tel:0111566299<br />
			Fax:0111568872<br />
			Mob:0911229428<br />
			Email:btrade2009@gmail.com<br />
			Capital: 20,000,000<br />
			Ownership: PLC<br />
			Employee: 225<br />
			<br />
			Name: Dama Trading<br />
			Sector: Import, Export<br />
			Interest: Agro processing, Manufacturing<br />
			Fax: 0113711741<br />
			Mob: 0911614877<br />
			Email: kebe999@yahoo.com<br />
			<br />
			Ownership: Sole proprietorship<br />
			Employee: 75<br />
			<br />
			Name: Arduneja Trading PLC<br />
			Sector: Export, Import<br />
			Interest: Construction material production<br />
			Tel: 0113203738<br />
			Fax: 0113203740<br />
			Mob:0935409248<br />
			Email: ardunejatrading@gmail.com<br />
			Capital: 7,065,000<br />
			Ownership: PLC<br />
			Employee: 56<br />
			<br />
			Name: Belsty Negassa &amp; His children trading plc<br />
			Sector: Import, Export, agriculture<br />
			Interest: Automobile and motor cycle<br />
			Tel: 0111561805<br />
			Fax: 0111561789<br />
			Mob: 0911259730<br />
			Email:belstynegessa@gmail.com<br />
			Capital: 12,000,000<br />
			Ownership: PLC<br />
			Employee: 68<br />
			<br />
			Name: Ginger Land Ethiopia general business Plc<br />
			Sector: Agro processing<br />
			Interest: Agro Processing<br />
			Tel: 0116631952<br />
			Fax:0116633434<br />
			Mob: 0911200081<br />
			Email: nazrawi24@yahoo.com<br />
			Capital: 10,000,000<br />
			Ownership: PLC<br />
			Employee: 36<br />
			<br />
			Name: Tahses ICT &amp; consultancy<br />
			Sector: Consultancy, ICT, Energy<br />
			Interest: Agro processing, Small agricultural machine, Import, Export<br />
			Tel: 0118964343<br />
			Mob: 0913007519<br />
			Email:fesseha@theteahses.com<br />
			Capital: 2,000,000<br />
			Ownership: PLC<br />
			Employee: 12<br />
			<br />
			Name: Jara Agro processing plc<br />
			Sector: Agriculture<br />
			Interest: Agro processing, Small agricultural machine<br />
			Tel: 0114714872<br />
			Fax: 0114714873<br />
			Mob: 0911207758<br />
			Email: tewodrose.gelaye@jaraagro.com<br />
			Capital: 12,332,000<br />
			Ownership: PLC<br />
			Employee: 25<br />
			<br />
			Name: Hakfen Export Trading Partners<br />
			Sector: Import, Export<br />
			Interest: Agro processing, Import, Export<br />
			Tel: 0115155020<br />
			Fax: 0115155288<br />
			Mob: 0911527152<br />
			Email:hakfenmarketing@gmail.com<br />
			Capital: 6,500,000<br />
			Ownership: Sole proprietorship<br />
			Employee: 200<br />
			<br />
			Name: Hambd Trading PLC<br />
			Sector: Import<br />
			Interest: Automobile and motor cycle, Small agricultural machine<br />
			Tel: 0115576605<br />
			Mob:0923373737<br />
			Email:berhankb@gmail.com<br />
			Capital: 3,000,000<br />
			Ownership: PLC<br />
			Employee: 20<br />
			<br />
			Name: Fawes Pharmaceuticals plc<br />
			Sector: Pharmaceuticals<br />
			Interest: pharmaceuticals<br />
			Tel: 0114394715<br />
			Fax: 0114392011<br />
			Mob: 0911079459<br />
			Email: alem.denekew@gmail.com<br />
			Capital: 26,000,000<br />
			Ownership: PLC<br />
			Employee: 62<br />
			<br />
			Name: Maru Metal Industry PLC<br />
			Sector: Metal Engineering<br />
			Interest: Automobile and Motor cycle<br />
			Tel: 0114654433<br />
			Fax: 0114654799<br />
			Mob:0911526768<br />
			Email:marumetalindustry@gmail.com<br />
			Capital: 16,880,000<br />
			Ownership: PLC<br />
			Employee: 176<br />
			<br />
			Name: Silverstar Engineering<br />
			Sector: Engineering, Construction, Manufacturing, Automotive, ICT<br />
			Interest: Agro processing, Automotive and motor cycle, Import, Export<br />
			Mob: 0911201105<br />
			Email: akal.mamo@silverstar-engineering.com<br />
			Capital: 10,000,000<br />
			Ownership: PLC<br />
			<br />
			Name: Logiskils Custom Clearance<br />
			Sector: Logistics<br />
			Interest: Pharmaceuticals, Agro processing<br />
			Tel: 0114707934<br />
			Fax: 0114707935<br />
			Mob:0911410577<br />
			Email: logiskils@gmail.com<br />
			Capital: 300,000<br />
			Ownership: Sole proprietorship<br />
			Employee: 5<br />
			<br />
			Name: Nazareth International Trading<br />
			Sector: Import, Real estate, Hotel, Commission Agent<br />
			Interest: Leather product, Agro processing<br />
			Tel: 0116611679<br />
			Mob: 0911200295<br />
			Email: nazarethplc@gmail.com<br />
			Capital: 100,000,000<br />
			Ownership: PLC<br />
			Employee: 180<br />
			<br />
			Name: Hab general trading plc<br />
			Sector: Automotive spare part, Foreign trade axillary<br />
			Interest: Automotive, Import, Export<br />
			Tel: 0116672610<br />
			Fax: 0116672477<br />
			Mob: 0911200373<br />
			Email: birhanewg@yahoo.com<br />
			Capital: 1,000,000<br />
			Ownership: PLC<br />
			Employee: 5<br />
			<br />
			Name: Gize PLC<br />
			Sector: Metal engineering, Real estate, Logistics &amp; shipping, Consultancy<br />
			Interest: agro processing, Automobile and motor cycle<br />
			Tel: 0114700880<br />
			Fax: 0114700877<br />
			Mob: 0911516478<br />
			Email:gizeshwork.tessema@gizeplc.com<br />
			Capital: 59,000,000<br />
			Ownership: PLC<br />
			<br />
			Name: Hafde Tannery PLC<br />
			Sector: Leather<br />
			Interest: Leather<br />
			Tel: 0118132723<br />
			Mob; 0911219331<br />
			Email: hafdeplc@gmail.com<br />
			Capital: 32,000,000<br />
			Ownership: PLC<br />
			Employee: 240<br />
			<br />
			Name: Valcor Trading PLC<br />
			Sector: Medical supplies and reagents<br />
			Interest: Pharmaceuticals, Medical supplies and reagents<br />
			Mob: 0911507862<br />
			Email: thailu@valcorint.com<br />
			Capital: 12,000,000<br />
			Ownership: PLC<br />
			Employee: 20<br />
			<br />
			Name: GHTY General Trading plc<br />
			Sector: Export<br />
			Interest: Import, Export<br />
			Tel: 0111560272<br />
			Fax:0111576839<br />
			Mob: 0911518886<br />
			Email: me.gebre@gmail.com<br />
			Capital: 50,000,000<br />
			Ownership: PLC<br />
			Employee: 14<br />
			<br />
			Name: Walk in Ethiopia tour travel and car rental<br />
			Sector: Tourism, Import<br />
			Interest: Automobile and motor cycle<br />
			Tel: 0116672880<br />
			Fax: 0116672800<br />
			Mob:0930099360<br />
			Email: walkinethiopia@yahoo.com<br />
			Capital: 5,000,000<br />
			Ownership: Sole proprietorship<br />
			Employee: 22<br />
			<br />
			Name: Afran Global Business Plc<br />
			Sector: Manufacturing, Import, Export<br />
			Interest: Agro Processing, Import,<br />
			Export, Automobile and motor cycle<br />
			Tel: 0115150748<br />
			Fax:0115159819<br />
			Mob:0911544871<br />
			Email:zerihun@afranltd.com<br />
			Capital: 35,000,000<br />
			Ownership: PLC<br />
			Employee: 70<br />
			<br />
			Name: Off-Road Ethiopia Tour PLC<br />
			Sector: Tour and Travel, import<br />
			Interest: Leather, Import<br />
			Tel: 0118693802<br />
			Fax: 0116621383<br />
			Mob: 0911513264<br />
			Email: info@tourtoethiopia.com<br />
			Capital: 2,100,000<br />
			Ownership: PLC<br />
			Employee: 10<br />
			<br />
			Name: Dagley Agro Industry PLC<br />
			Sector: Export, Import, Agriculture<br />
			Interest: Agro processing, Small agricultural machine, automobile and motor cycle<br />
			Tel: 0118290203<br />
			Fax: 0114702625<br />
			Mob:0911348495<br />
			Email: dagleydema@gmail.com<br />
			Capital: 2,000,000<br />
			Ownership: PLC<br />
			<br />
			Name: Seid Hassen &amp; family Business<br />
			Sector: Import, agriculture, metal Engineering, tobacco<br />
			Interest: Agro processing, Small agricultural machine<br />
			Tel:0112758275<br />
			Fax: 0112758274<br />
			Mob:0911505599<br />
			Email:hast@ethionet.et<br />
			Email: haststeel@gmail.com<br />
			Capital: 137,000,000<br />
			Ownership: PLC<br />
			<br />
			Name: Plateau Business International in Ethiopia PLC<br />
			Sector: Consultancy, Agriculture, Export<br />
			Interest: export, Import, Agro processing, Agriculture<br />
			Tel: 0118100106<br />
			Mob: 0947652435<br />
			Email: plateaubusiness@ymail.com<br />
			Capital: 2,400,000<br />
			Ownership: PLC<br />
			<br />
			Name: Ayele Shone Import<br />
			Sector: Import<br />
			Interest: Pharmaceuticals<br />
			Import<br />
			Mob: 0911253673<br />
			Email: ayugca@yahoo.com<br />
			Capital: 1,100,000<br />
			Ownership: Sole proprietorship<br />
			<br />
			Name: Hidase telecom<br />
			Sector: Telecommunication<br />
			Interest: Automobile and motor cycle, Small agricultural machine, Import, Export<br />
			Tel: 0114653711<br />
			Fax: 0114663649<br />
			Mob: 0939595858<br />
			Mob: 0916869652<br />
			Email: mesmatsol@gmail.com<br />
			Capital: 74,000,000<br />
			Ownership: Share company<br />
			Employee: 4500<br />
			<br />
			Name: Tamrin International Trading PLC<br />
			Sector: Import, Export<br />
			Interest: Import, Export, Automobile &amp; motor Cycle<br />
			Agro processing<br />
			Tel: 0114431444<br />
			Fax: 0114431028<br />
			Mob: 0911258990<br />
			Email: tamrinint@gmail.com<br />
			Capital: 20,000,000<br />
			Ownership: PLC<br />
			Employee: 260<br />
			<br />
			Name: Eleciv Contracting and Trading PLC<br />
			Sector: Agency, Electrical, Manufacturing<br />
			Interest: Leather, Import, Export<br />
			Tel:0118963588<br />
			Mob; 0929109452<br />
			Mob:0911317425<br />
			Email:elecivcon@yahoo.com<br />
			Capital: 180,000<br />
			Ownership: PLC<br />
			Employee: 3<br />
			<br />
			Name: Tof Trading<br />
			Sector: Import, Construction, Printing<br />
			Interest: Import, Export, Agro processing<br />
			Mob:0911615267<br />
			Email: toficsh@yahoo.com<br />
			Capital: 1,000,000<br />
			Ownership: Sole proprietorship<br />
			Employee: 5<br />
			<br />
			Name: Kebede kassaye Construction<br />
			Sector: Construction<br />
			Interest: Automobile &amp; motor cycle<br />
			Pharmaceuticals, Small agricultural machine<br />
			Mob:0911209551<br />
			Mob:0939532919<br />
			Email: kebede.kbc@gmail.com<br />
			Capital: 70,000,000<br />
			Ownership: Sole proprietorship<br />
			Employee: 60<br />
			<br />
			Name: Mer View Import and travel agency<br />
			Sector: Import, Travel agency, Transport<br />
			Interest: Agro processing, Import, Export<br />
			Mob: 0911209897<br />
			Mob; 0910155858<br />
			Email: yemer200@gmail.com<br />
			Capital: 3,000,000<br />
			Ownership: Sole proprietorship<br />
			Employee: 10<br />
			<br />
			Name: Anteneh Wondafrash general import &amp; Export<br />
			Sector: Import, Export<br />
			Interest: Export Agro processing<br />
			Mob:0911220976<br />
			Fax: 0114652752<br />
			Email: awt.traders@gmail.com<br />
			Capital: 1,200,000<br />
			Ownership: Sole proprietorship</p>
		<hr />
		<p>
			Beza Industry PLC<br />
			Sector: Mill/Millstones &amp; Parts<br />
			Phone: +251 11 2798943/2795937/2791744<br />
			Mobile: +251 91 1218532<br />
			Fax: +251 91 1218532</p>
		<p>
			AHADU PLC<br />
			Sector: Manufacturing, Commercial Farm, Packaging, Real Estate, Trading, Logistics<br />
			Business Interest: Pharmaceuticals, Agriculture<br />
			P.O. Box: 100448<br />
			Tel: +251-116-29-88-26<br />
			Fax: +251-116-29-88-19<br />
			Mobile: +251-912-62-35-91<br />
			Email: info@ahadugroup.com<br />
			Website: www.ahadugroup.com</p>
		<p>
			ALOEVERA Pharmaceuticals and Medical Equipment PLC<br />
			Sector: Pharmaceuticals Import<br />
			Business Interest: Pharmaceuticals and medical equipment manufacturers and Dental<br />
			Supplies: +251-116-61-66-05<br />
			Fax: +251-116-61-66-05<br />
			Mobile:+251-910-95-04-99/ 251-930-03-46-32<br />
			Email: frhwtbatri@yahoo.com</p>
		<p>
			SAMUEL LEMA GENERAL IMPORT AND EXPORT<br />
			Sector: Import Export<br />
			Business Interest: Import of consumer goods, Export of agricultural product<br />
			Tel: +251-118-70-39-05<br />
			Fax: +251-111-26-62-12<br />
			Mobile: +251-911-22-04-73<br />
			E-mail: samuellemaexp@yahoo.com<br />
			P.o.box : 29621</p>
		<p>
			FENETICA DRUG AND MEDICAL SUPPLIES WHOLESALE PLC<br />
			Sector: Pharmaceuticals<br />
			Business Interest: Pharmaceuticals, Medical Equipment, Reagents manufacturers and Suppliers<br />
			Tel: +251-118-69-79-66<br />
			Fax: +251-251-118-69-80-46<br />
			Mobile: 251-935-40-24-03<br />
			E-mail: tsfy_tekalign@yahoo.com</p>
		<p>
			AFRICAWIT CONSTRUCTION PLC<br />
			Sector: Construction<br />
			Business Interest: Construction<br />
			P.O.Box:41142<br />
			Tel: 251-114-40-39-82<br />
			Fax: 251-114-42-02-95<br />
			Mobile: 251-911-20-13-52<br />
			E-mail: afric@ethionet.et</p>
		<p>
			MILCOM PHARMACY<br />
			Sector: Pharmaceuticals<br />
			Business Interest: Agriculture, Pharmaceuticals<br />
			P.O.Box:4075 Jimma Ethiopia<br />
			Tel: 251-4711-12-26-16<br />
			Mobile: 251-917-80-27-69<br />
			E-mail:birhanulemu@gmail.com</p>
		<p>
			HAMDIAN PENSION<br />
			Sector: Hotel<br />
			Business Interest: Hospitality and Agriculture<br />
			P.O.Box: 143 Jimma Ethiopia<br />
			Mobile:+251-914-31-73-63<br />
			Telephone: 251-4711-11-00-15</p>
		<p>
			BEHAJA PENSION AND CAFE<br />
			Sector: Hotel and Restaurant<br />
			Business Interest: Hospitality, Health, Pharmaceuticals, Textile, Cosmetics<br />
			Tel: 251-4711-11-97-06<br />
			Mobile: +251-917-55-02-28</p>
		<p>
			AGRO-VET PL<br />
			Sector: Import<br />
			Business Interest: Milk processing machine, poultry equipment, Bee equipment, Artificial insemination equipment<br />
			P.O.Box: 190268 Addis Ababa Ethiopia<br />
			Tel: 251-115-54-47-49<br />
			Fax: +251-115-54-47-30<br />
			Mobile: +251-911-20-38-49<br />
			E-mail: agrovet@ethionet.et</p>
		<p>
			BEDA BUNA DIARY FARM AND MILK PROCESSING<br />
			Sector: Agro processing<br />
			Business Interest: Agricultural Machinery, Diary farm Equipment, Milk processing machinery<br />
			P.O.Box: 552 Jimma, Ethiopia<br />
			Tel: +251-471-11-43-46<br />
			Mobile: +251-917-55-22-28<br />
			E-mail: Samuel.argawe@gmail.com</p>
		<p>
			RETINA PHARMACEUTICALS<br />
			Sector: Pharmaceuticals<br />
			Business Interest: Pharmaceuticals and Medical Equipment, Medical supplies, and Lab reagent<br />
			Tel: +251-118-96-60-96<br />
			Mobile:+251-911-74-35-97<br />
			Fax: +251-115-52-06-77<br />
			Email: deme@retinapharmaceuticals.com<br />
			WWW.Retinapharmaceuticals.com</p>
		<p>
			RAMSAY SHOE FACTORY<br />
			Sector: Manufacturing<br />
			Business Interest: Leather<br />
			P.O.Box: 182807 Addis Ababa, Ethiopia<br />
			Tel: +251-114-42-52-95<br />
			Mobile: +251-911-20-56-12/251-911-42-64-14<br />
			Fax: +251-114-42-18-21<br />
			E-mail: ramsayshoe@ymail.com</p>
		<p>
			JEMAL ADEM BUSINESS<br />
			Sector: Wholesale<br />
			Business Interest: Textile, Automobile, Soap machinery<br />
			P.O.Box:799 Jimma, Ethiopia<br />
			Mobile: 251-917-55-00-44<br />
			E-mail: jamhana85@gmail.com</p>
		<p>
			AL AMIR WHOLESALE PLC<br />
			Sector: Wholesale<br />
			Business Interest: Edible Oil, Clothing, Textile<br />
			P.O.Box: 1369 Jimma, Ethiopia<br />
			Mobile: +251-917-55-16-70/ 251-917-80-33-75</p>
		<p>
			TESURCON TRADING PLC<br />
			Sector: Construction<br />
			Business Interest: Construction, Engineering, Infrastructure<br />
			P.O.Box: 9148 Addis Ababa, Ethiopia<br />
			Tel: +251-116-26-14-68<br />
			Mobile: +251-911-52-30-33<br />
			E-mail: tesfaye100@hotmail.com</p>
		<p>
			MEDCO BIO MEDICAL CONSULTANCY PLC<br />
			Sector: EDUCATION, CONSULTANCY, AGRICULTURE, MEDIA<br />
			Business Interest: EDUCATION, AGRICULTURE, MANUFACTURING, ANY DEVELOPMENT AREA<br />
			P.O.Box: 270 code 1110 Addis Ababa, Ethiopia<br />
			Tel: +251-114-16-55-05<br />
			Mobile: +251-911-20-53-02<br />
			E-mail: medco@ethionet.et<br />
			Web site: www.medcibiomed.et</p>
		<p>
			3M ENGINEERING AND CONSTRUCTION<br />
			Sector: CONSTRUCTION<br />
			Business Interest: CONSTRUCTION AND INFRASTRUCTURE<br />
			P.O.Box: 101806 Addis Ababa, Ethiopia<br />
			Tel: +251-114-16-71-04/05<br />
			Mobile: +251-935-40-16-56<br />
			E-mail: meazaephrem@yahoo.com</p>
		<p>
			HERMATA MENTENA BUSINESS CENTER<br />
			Sector: Trading<br />
			Business Interest: Textile, Tailor Machine, Agriculture, Construction, Cosmetics<br />
			Mobile: +251-917-80-17-62</p>
		<p>
			HAICOF PLC<br />
			Sector: Export<br />
			Business Interest: Construction, Spare parts, Paper products<br />
			Tel: +251-115-51-51-17<br />
			Fax: +251-115-51-68-88<br />
			Mobile: +251911-21-01-70<br />
			E-mail: haicofcoffee@gmail.com<br />
			P.o.box : 4854 Addis Ababa, Ethiopia</p>
		<p>
			HAICOF PLC<br />
			Sector: Export<br />
			Business Interest: Construction, Spare parts, Paper products<br />
			Tel: +251-115-51-51-17<br />
			Fax: +251-115-51-68-88<br />
			Mobile: +251911-20-88-07<br />
			E-mail: haicofcoffee@gmail.com<br />
			P.o.box : 4854 Addis Ababa, Ethiopia</p>
		<p>
			Tesurcon Trading PLC<br />
			Sector: Constructions, Trading<br />
			Business Interest : Joint venture of Manufacturing of paint raw material<br />
			Address: Tel: 251-116-26-14-68<br />
			&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mobile: 251-911-52-30-33<br />
			&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; E-mail: <a href="mailto:tesfaye100@hotmail.com">tesfaye100@hotmail.com</a></p>
		<p>
			Masho Plastic Factory<br />
			Sector: Manufacturing<br />
			Business Interest: Buyer of plastic raw material and mould<br />
			Address: Tel: 251-334-40-72-02<br />
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mobile: 251-914-30-02-17<br />
			&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; E-mail: zerhailu93@gmail.com</p>
		<p>
			Kivu Trading PLC<br />
			Sector: Import<br />
			Business Interest: Buyer of stationary, White cement, ceramics<br />
			Address: Mobile: 251-911-65-35-09<br />
			&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; E-mail: kivutradingplc@gmail.com</p>
		<p>
			Tewodrose Mechal Import and Export<br />
			Sector: Import<br />
			Business Interest: Buyer of plastic shoe raw material, plastic machinery, plastic floor, white cement<br />
			Address: Mobile: 251-911-13-16-94<br />
			&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; E-mail: seifuimpex@gmail.com</p>
		<p>
			Adulia Advertising and promotion PLC<br />
			Sector: Advertising and Promotion<br />
			Business Interest: Buyer of stationary, ink, print machinery<br />
			Address: Tel: 251-116-69-13-27<br />
			&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mobile: 251-911-22-05-20<br />
			&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; E-mail: adulia.adv.pro@gmail.com</p>
		<p>
			Baheran Trading PLC<br />
			Sector: Chemicals, Packaging<br />
			Business Interest: Buyer of paint raw material (pigment)<br />
			Address: Tel: 251-111-56-62-99<br />
			&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fax: 251-111-56-88-72<br />
			&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mobile: 251-911-20-44-02<br />
			&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; E-mail: <a href="mailto:btrade2009@gmail.com">btrade2009@gmail.com</a></p>
		<p>
			DDgo Trading<br />
			Sector: Beverage, Import<br />
			Business Interest: Buyer of Ink pigment (paint raw material), Plastic floor<br />
			Address: Tel: 251-251-11-29-44<br />
			&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mobile: 251-915-32-02-07<br />
			&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; E-mail: henovich@gmail.com</p>
		<p>
			Emag Metal Engineering<br />
			Sector: Metal Engineering<br />
			Business Interest: Buyer of plastic raw material, plastic mould, fiber products<br />
			Address: Mobile: 251-912-50-70-05<br />
			&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; E-mail: ephremababu@gmail.com</p>
		<p>
			Agrovet PLC<br />
			Sector: Import<br />
			Business Interest: Buyer of Milk processing equipment, chicken equipment and other animal equipment<br />
			Address: Tel: 251-115-54-47-49<br />
			&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fax: 251-115-54-47-30<br />
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mobile: 251-911-20-38-49<br />
			&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; E-mail: agrovet@ethionet.et<br />
			&nbsp;<br />
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      <a href="/Data/Sites/1/all-jv.pdf" target="_blank"><strong>Click Here For More Companies</strong></a></p>
                </Tab>
                <Tab eventKey="contact" title="Forign Campanies" >
               <h4>
			China</h4>
		<p>
			Epidemic Prevention Products Exhibition (personal protective equipment)<br />
			China Council for the Promotion of International Trade (CCPIT), Zhejiang Provincial Committee<br />
			China Chamber of International Commerce (CCOIC), Zhejiang Chamber of Commerce<br />
			No. 466, Yan'an Road, Hangzhou City, Zhejiang Province, P.R.China<br />
			Postcode: 310006<br />
			Tel: 0086-571-8581 1931, 87797173, 85811910<br />
			Fax: 0086-571-8581 1935<br />
			Email: <a href="mailto:info@ccpitzj.gov.cn" target="_blank">info@ccpitzj.gov.cn</a><br />
			Website: <a href="http://www.ccpitzj.gov.cn" target="_blank">www.ccpitzj.gov.cn</a> or <a href="http://www.ccpitzj.gov.cn/web/product/index" target="_blank"><u>http://www.ccpitzj.gov.cn/web/product/index</u></a></p>
		
		<h4>
			Sri Lanka</h4>
		<p>
			Company Name: Natures Beauty Creations Ltd.<br />
			Interest: Impoter<br />
			Sector: cosmetics manufacturer<br />
			MB: +94-77-2083714 PH: +94-34-2261100<br />
			FX: +94-34-2261101<br />
			<a href="mailto:Email:%20shamindi@nbc.lk">Email: shamindi@nbc.lk</a><br />
			WEB: <a href="http://www.naturessecrets.lk/" target="_blank">www.naturessecrets.lk</a><br />
			Company Video (1min): <a href="https://www.youtube.com/watch?v=M8Y4BdT9R_M&amp;t=1s">https://www.youtube.com/watch?v=M8Y4BdT9R_M&amp;t=1s</a></p>
		
		<h4>
			<strong>GERMANY</strong></h4>
		<p>
			Name: Krone Trailer Company<br />
			Interest: Dealer, Distributor or Partner<br />
			Sector/products: refrigerated semi-trailers, vans, tarpaulins, container carriers and special steel/paper coil-carrier semi-trailers.<br />
			Address: Fahrzeugwerk Bernard Krone GmbH &amp; Co. KG<br />
			Bernard-Krone-Str. 1, D-49757 Werlte, GERMANY<br />
			Tel.: 0 232 510 1100 | Fax: 0 232 510 1109<br />
			Mob: 0 533 938 5525<br />
			E-Mail: <a href="mailto:bulent.bayir@krone.com.tr" target="_blank">bulent.bayir@krone.com.tr</a> | Internet: <a href="http://www.krone-trailer.com/" target="_blank">www.krone-trailer.com</a></p>
	
		<h4>
			Egypt</h4>
		<p>
			Company Name: El-Arish Cement Company<br />
			Sector: Marble &amp; granite$ price list (2cm-Tiles)<br />
			<a href="/Data/Sites/1/2012 EC/Attachments/El-Arish-cement-company-product-list.docx" target="_blank">Click for product list</a><br />
			Economic &amp; Commercial Office<br />
			Embassy of Arab Republic of Egypt<br />
			TEL: 00251 111226425<br />
			FAX: 00251 111226419<br />
			P.O.B: 2580<br />
			E-mail : <a href="mailto:addisababa@tamseel-ecs.gov.eg">addisababa@ ecs.gov.eg</a><br />
			Addis Ababa, Ethiopia</p>
		
		<h4>
			Canada Companies</h4>
		<p>
			The Mecco Group<br />
			Sector: Renewable Energy<br />
			Interest: Solar Energy<br />
			For More Information: <a href="/Data/Sites/1/mecco-group.pdf" target="_blank">Click Here</a></p>
		
		<h4>
			French Companies</h4>
		<p>
			Company Name: VICAT<br />
			Sector:&nbsp; Public works, building industry<br />
			Interest:&nbsp; Building Industry<br />
			Contact Address: Pierre-Olivier BOYER<br />
			E-mail: &nbsp;<a href="mailto:pierre-olivier.boyer@vicat.fr">pierre-olivier.boyer@vicat.fr</a><br />
			Office number: +33 4 74 27 59 02<br />
			Mobile number:&nbsp; +33 6 07 94 50 33<br />
			Web: &nbsp;<a href="http://www.vicat.fr">www.vicat.fr</a><br />
			<br />
			Company Name: 6D SOLUTIONS<br />
			Sector: &nbsp;Road construction and maintenance&nbsp;<br />
			Interest: &nbsp;Road construction<br />
			Contact Address: Daniel DOLIGEZ<br />
			E-mail:&nbsp; <a href="mailto:Daniel.doligez@6d-solutions.com">Daniel.doligez@6d-solutions.com</a><br />
			Office number: &nbsp;+33 4 72 16 10 63<br />
			Mobile number: &nbsp;+33 6 11 74 08 11<br />
			Web:&nbsp; <a href="http://www.6dsolutions.com">www.6dsolutions.com</a> &nbsp;<br />
			<br />
			Company Name: SAFEGE SAS-SUEZ<br />
			Sector: Public works, consulting engineers&nbsp;&nbsp;&nbsp;<br />
			Interest: &nbsp;Consulting engineers&nbsp;&nbsp;&nbsp;<br />
			Contact Address: Denis FUENTES<br />
			E-mail: <a href="mailto:denis.fuentes@safege.com">denis.fuentes@safege.com</a> &nbsp;&nbsp;<br />
			Office number:&nbsp; +33 4 72 19 89 53<br />
			Mobile number: &nbsp;+33 6 81 44 46 06<br />
			Web:&nbsp; <a href="http://www.safege.com">www.safege.com</a></p>
		<p>
			Company Name: STRACAU<br />
			Sector: Water industries, chemical Industries, agro-industries, HVAC, etc.<br />
			Interest: &nbsp;Industries<br />
			Contact Address: Sofiane HADRI<br />
			E-mail: &nbsp;<a href="mailto:s.hadri@stracau.com">s.hadri@stracau.com</a><br />
			Office number:&nbsp; +33 4 72 47 70 14&nbsp;<br />
			Mobile number: &nbsp;+33 6 80 99 52 32<br />
			Web:&nbsp; <a href="http://www.stracau.com">www.stracau.com</a><br />
			&nbsp;</p>
		
		<h4>
			Spanish Companies</h4>
		<p>
			Company: Celler Sort de Castel<br />
			Website: <a href="http://www.vermutyzaguirre.com/home_en" target="_blank">http://www.vermutyzaguirre.com/home_en</a><br />
			Sector: Wine<br />
			Products: Red Wine/Apperitifs-Vermouth/Sangría/Sacaramental Wine (Sweet Natural Wines)</p>
		<p>
			Company: Font Salem<br />
			Website: <a href="http://www.fontsalem.com/en/" target="_blank">http://www.fontsalem.com/en/</a><br />
			Sector: Beverages<br />
			Products: Beers: <em>With Alcohol &amp; Non </em><em>Alcohol</em><br />
			Water: <em>Sparkling &amp; Still</em><br />
			Soft Drinks: &nbsp;<em>Bitter | Cola | Diet Cola | Cola wihout Caffeine | Diet Cola wihout</em><br />
			<em>Caffeine | Energetic Drinks | Exotic Drinks | Rasperry | Strawberry | Lemonade | </em><br />
			<em>Isotonic Drinks | Lime | Lemon | Still Lemon | Apple | Multifruit | Orange | Still </em><br />
			<em>Orange | Pineapple | Still Pineapple | Sangría | Teas | Tinto de Verano-Classic | into Verano-Lemon | Tonic Water</em></p>
		<p>
			Company: Fagor<br />
			Website: <a href="http://www.fagor.com/web/es" target="_blank">http://www.fagor.com/web/es</a><br />
			Sector: Home Appliances<br />
			Products: Pressure Cookers/ Cookware/ SDA (Small Domestic Appliances)/ Gas water<br />
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Heaters/ Electric Water Heaters/ Boilers/ Fans/Hoods/Ovens/Cookers/Hobs/<br />
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Refrigerators/Freezers/Washing Machines Tumble driers/Dishwashers/Microwaves.<br />
			<em>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Products manufactured with Ecofriendly Materials according to Eco Design Method</em></p>
		<p>
			Company: Fluidra Export<br />
			Website: <a href="http://www.en.fluidraexport.com/" target="_blank">http://www.en.fluidraexport.com/</a><br />
			Sector: Swimming Pools<br />
			Products: Pumps, Filters, PVC Fittings, Chemicals</p>
		<p>
			Company: Certest Biotec<br />
			Website: <a href="http://www.certest.es/" target="_blank">http://www.certest.es/</a><br />
			Sector: Biotecnology<br />
			Products: Rapid Test: <em>For In vitro Diagnostic</em>/<em>Fecal Antigens/Respiratory Antigens </em>Inflammatory Combos&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br />
			Real time PCR: VIASURE : <a href="http://www.certest.es/catalog/real-time-pcr-qpcr-en-es/" target="_blank"><em>Real Time PCR Detection Kits</em></a><br />
			Turbidimetry: <em>For detection of Faecal Antigens</em></p>
		<p>
			Company: Laboratorios SYVA<br />
			Website: <a href="http://www.syva.es/?lang=en" target="_blank">http://www.syva.es/?lang=en</a><br />
			Sector: Veterinary<br />
			Products: Vaccines (Cats, Cattle, Dogs, Fish, Goats, Porcine &amp; Sheep)<br />
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pharmaceutical Products: Calves, Cats, Cattle, Dogs, Fish, Foals, Goats, Horses,<br />
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Lambs, Piglets, Pig, Porcine, Poultry, Rabbits, Sheep, Sows, Swine.</p>
		<p>
			Company: S. P Veterinaria<br />
			Website: <a href="http://www.spveterinaria.net/empresa" target="_blank">http://www.spveterinaria.net/empresa</a><br />
			Sector: Veterinary<br />
			Products:&nbsp; antibacterial, antiparasetic , Insectisine, Desinfectant, Hormonal,Vitamins,<br />
			&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Amino Acids, Minerals, Additives ,NSAIDs,</p>
	
		<h5>
			Iranian Companies</h5>
		<p>
			1. <u>Iran Khodro</u><br />
			Sector: Trucks, Buses, Vans and Minibuses<br />
			Interest: Export of Trucks, Buses, Vans and Minibuses<br />
			Tel: +982155272460-1<br />
			E-mail:info@lkd-co.com<br />
			Website: www.ikd-co.com</p>
		<p>
			1. <u>Sepahan Delta Knowledge-Oriented Co.</u><br />
			Sector: Agriculture Machinery<br />
			Interest: Partner on Rice seedlings and Sprayers for pesticides.<br />
			E-mail:info@sepahandelta.com<br />
			Website: www.sepahandelta.com</p>
		<p>
			1.<u> Hero Eco Group</u><br />
			Sector: Hero Brand Bicycles and Electric Scooters<br />
			Interest: to work with Ethiopian companies want to start or already in Bicycle business<br />
			Email: herogroup@gmail.com<br />
			2. <u>Water life India PLC</u><br />
			Sector: Water Purifying<br />
			Interest: Partner<br />
			Email: smenon@waterlifeindia.com<br />
			<u>3.Oakridge Energy PVt.LTd.</u><br />
			Sector: Energy<br />
			Interest: Partners<br />
			<u>4. Mahashakti Energy Limited</u><br />
			Sector: Energy<br />
			Interest:Partner<br />
			Email:mcplbti@gmail.com<br />
			<u>5. One World Corporation</u><br />
			Sector: Auromatic goods<br />
			Interest: Partner, plantation Auromatic woods<br />
			Email: arun@oneworldcorporation.in<br />
			<u>6. ReeLabs Pvt. Ltd.</u><br />
			Sector: Health<br />
			Interest: Partners and stand sales for Hospitals<br />
			Email; jitinder.gill@reelabs.com<br />
			<u>7. dph software services pvt.Ltd.</u><br />
			Sector: IT<br />
			Interest: Partner<br />
			<u>8. Suman Engineering Inc.</u><br />
			Sector: Vegetable oils,Diary, Beverage processing plants<br />
			Interest: Partner, Joint venture<br />
			Email: bhatoy@gmail.com<br />
			<u>9. Fieldking</u><br />
			Sector: Agriculture<br />
			Interest: Partner, Export machineries<br />
			Email: ankit.bhatnagar@fieldking.com<br />
			<u>10. Ascendere Ventures Pvt.Ltd</u><br />
			Sector: Minerals, Metals<br />
			Interest: Partner<br />
			Email: ankit.bhatnagar@fieldking.com<br />
			<u>11. LEA Associates South Asia</u><br />
			Sector: Consultancy<br />
			Interest: Consultancy in Engineering and planning<br />
			Email: biswarup@iasaindia.com<br />
			<u>12. Elcom Innovations Pvt Ltd</u><br />
			Email: rjunneja@elcominnovations.com<br />
			<u>13. Invert Sugar Pvt Ltd</u><br />
			Email: ashish@invertsugar.in<br />
			<u>14. Tube Investments of India</u><br />
			Email: srinivasanav@tii.murugappa.com<br />
			<u>15. JSW Steel Limited</u><br />
			Sector: Minerals<br />
			Interest: partners to import from Ethiopia<br />
			Email: ranapratap.singh@jsw.in<br />
			<u>16. Power Engineering India</u><br />
			Sector: Power<br />
			Interest: partner<br />
			Email: raghavendra@paikane.com<br />
			<u>17. Paradise Sanitary Ware Pvt. Ltd</u><br />
			Sector: Sanitary Ware<br />
			Interest:Partner<br />
			Email: paradisepottery72@gmail.com<br />
			<u>18. National Innovation Foundation-India</u><br />
			Sector: Agriculture, Electricity<br />
			Interest: Importers, small scale enterprises<br />
			Email: animeshp@nifindia.org<br />
			<u>19. D&amp;H Secheron Electrodes Pvt. Ltd</u><br />
			Sector: Export<br />
			Interest: partner for welding machines<br />
			Email: yogeshjoshi@dnhsecheron.net<br />
			<u>20. Winter Apparel Ltd</u><br />
			Sector:Textile, garment<br />
			Interest: Business Buyer<br />
			Email: Wintexties@rediffmail.com<br />
			<u>21. IL&amp;FS Education &amp; technology services</u><br />
			Sector: Education<br />
			Interest: Partner<br />
			Email: pooja.gianchandani@ilfsindia.com<br />
			<u>22. R.S. Pigments</u><br />
			Sector: Manufacturing, Export of Ultramarine Blue<br />
			Interest: GB partners, Importers of Edible oils<br />
			Email: info@ultramarineblueindia.in<br />
			<u>23. Real Boilers Pvt.Ltd</u><br />
			Email: ibrboilers@gmail.com<br />
			<u>24. Telcoma</u><br />
			Emailgagan@telcomatraining.com<br />
			<u>25. Modern Business Equipments &amp; services Pvt. Ltd</u><br />
			Sector: Digital Scales, Personal Health Care Products<br />
			Interest: Partner (want also to import Gold)<br />
			<u>26. Chrisleen Clean Room Projects</u><br />
			Sector: Furniture, Pharmaceuticals<br />
			Interest: Partner<br />
			Email: contact@chrisleengroup.com<br />
			<u>27. Dr. Agarwal’s Eye hospital</u><br />
			Sector: Health<br />
			Interest: Partner<br />
			Email: srividhyag@dragarwal.com<br />
			<u>28. Svarn Group</u><br />
			Sector: Telecom,Solar, prefab Houses<br />
			Interest: Partner<br />
			Email: jkmishra@svarn.com<br />
			<u>29. Anuar Biotech Industries</u><br />
			Sector: Pharmaceuticals<br />
			Interest: Partners, Pharma companies, importers of chemicals for cosmotics<br />
			Email: anuarbiotech1@gmail.com<br />
			<u>30. Empiire Group Infra and Holdings Pvt Ltd</u><br />
			Sector: Export/Import, Mining, Power, Infrastructure,Education<br />
			Interest: Partners<br />
			Email: raam@empiiregroup.com<br />
			<u>31. Versa Agro Industries</u><br />
			Sector: Manufacturing of blades<br />
			Interest: Partners<br />
			Email: versaagro@harvesterblades.com<br />
			<u>32. Bulk House</u><br />
			Sector: ecommerce, training<br />
			Interest: Distributers, Partners<br />
			Email: ali@bulkhouse.com<br />
			<u>33. Claas India</u><br />
			Sector: Manufacturing, Agriculture<br />
			Interest: Distributers, Partners<br />
			Email: varinder.bedi@claas.com<br />
			<u>34. Nimbuzz</u><br />
			Sector: Education and Media. Data analytics<br />
			Interest: To provide solution for Ethiopian businesses<br />
			Email: jobybabu@nimbuzz.com<br />
			<u>35. Coronet Foods</u><br />
			Sector: Foods<br />
			Interest: Partners<br />
			Email: coronet.off@gmail.com<br />
			<u>36. Kovai Agro Foods</u><br />
			Sector: Agro processing, plantation of cocunuts<br />
			Interest: Partners(proposal submitted to Embassy)<br />
			Email: kovaikisan@gmail.com<br />
			<u>37. Three Sixty Power Products</u><br />
			Sector: Leading manufacturer and Exporter of high quality power backup products<br />
			Interest: partner work closely to build brand in Ethiopia<br />
			Email: pranay.sharma@threesixty.in<br />
			<u>37. Ajanta Soya Limited</u><br />
			Sector: Oil<br />
			Interest: Partners<br />
			Email: abhey@ajantasoya.com<br />
			<u>38. Shri Niwas Food Industries</u><br />
			Sector: Agro processing<br />
			Interest: Partners (want to establish processing plant)<br />
			Email: singhalapoorv@gmail.com<br />
			<u>39. Vimal Organics Limited</u><br />
			Sector: Fertilizers, Chemicals, Edible oil<br />
			Interest: Partners<br />
			Email: arunaggrawal@yahoo.com<br />
			<u>40. National Agro Industries</u><br />
			Sector: Agro Processing<br />
			Interest: Partners<br />
			Email: sales@nationalagro.com<br />
			<u>41. Bravat India</u><br />
			Sector: Construction<br />
			Interest: Partners<br />
			Email: africa@bravatindia.com<br />
			<u>42. Clairvoyant Mobile Solutions</u><br />
			Sector: IT<br />
			Interest: Partners, IT/Mobile Application<br />
			Email: sid@clairvoyant.com<br />
			<u>43. SPML Infra Limited</u><br />
			Sector: Construction, Water treatment<br />
			Interest: Partners<br />
			Email: npsingh@spml.com.in<br />
			<u>44. Bob eprocure</u><br />
			Sector: eAuctions,edisposals,eTenders<br />
			Interest: Partner on the IT sector,Agriculture<br />
			Email: balkrishna.shetty@bobeprocure.com45.<br />
			<u>45. Name: Mr. Jitendra nair</u><br />
			Company: Suite No-312, Orion Business Park, G.B.<br />
			E-mail: <a href="mailto:Jeetendra.nair@unilightventures.com">Jeetendra.nair@unilightventures.com</a><br />
			Web: <a href="http://www.edulight.co.in">www.edulight.co.in</a><br />
			Activities: Education sector, Interested in Joint venture in Vocational Training, distance Education &amp; setting up Schools &amp; corporate training.<br />
			<u>46. Name: Mr. Shreepal Babulal Bafna</u><br />
			Company: Mohanlal Babulal Bafna<br />
			E-mail: <a href="mailto:mobotex@vsnl.com">mobotex@vsnl.com</a><br />
			Web: <a href="http://www.mbfashionhouse.com">www.mbfashionhouse.com</a><br />
			Activities: Manufacturers &amp; Exporters of Textile, Garments, Dress Materials &amp; All types of Cotton Fabrics.<br />
			<u>47. Name: Mr. Harikishan s, Chanchlani</u><br />
			Company: Chanchlani impex<br />
			E-mail: <a href="mailto:chanchlaniimpex@gmail.com">chanchlaniimpex@gmail.com</a><br />
			Web: <a href="http://www.chanchlaniimex.com">www.chanchlaniimex.com</a><br />
			Activities: Manufacturer &amp; Exporter of wall tiles and ceramic tiles<br />
			<u>48. Name: Mr.N. Gopalan</u><br />
			Company: Tesla Transformers Ltd<br />
			E-mail: <a href="mailto:mktg@teslatransformers.com">mktg@teslatransformers.com</a>,&nbsp; <a href="mailto:gopalan@teslatransformer.com">gopalan@teslatransformer.com</a>,&nbsp; <a href="mailto:tesla@bsnl.in">tesla@bsnl.in</a><br />
			Web: <a href="http://www.teslatransformers.com">www.teslatransformers.com</a><br />
			Activities: Tesla Group is a multi product and service supplying, erecting, testing and commiss-ion of power transformers&amp; distribution transformers, complete 220KV sub-Stations and turnkey projects.<br />
			<u>49. Name: Mr. B Sheetharam Shetty</u><br />
			Company: Onshore Constructions Co.Pvt. Ltd<br />
			E-mail: <a href="mailto:Info@onshoreconstruction.con">Info@onshoreconstruction.con</a><br />
			Web: <a href="http://www.onshoreconstruction.con">www.onshoreconstruction.con</a><br />
			Activities: Engineering Construction Company in the field, metallurgical, Chemical, Petro-Chemical, Refinery, Fertilizer, power, Air Separation, Vegetable Oil, Processing &amp; Pharmaceuticals Plants<br />
			<u>50. Name: Mr. Prashant Gadepalli</u><br />
			Company: Eletropneumatics &amp; Hydraulics (I) pvt. Ltd<br />
			E-mail: <a href="mailto:exports@electropneumatics.com">exports@electropneumatics.com</a><br />
			Web: <a href="http://www.electropneumatics.com">www.electropneumatics.com</a><br />
			Activities: Manufacturer of Hydraulic Press, Servo Mechanical Press Tube Bends &amp; Special Purpose Machine (SPM)<br />
			<u>51. Name: Arun Dadda Kriparam Verma</u><br />
			Company: Economode Food Equipment<br />
			E-mail: <a href="mailto:arundadda@hotmail.com">arundadda@hotmail.com</a>,&nbsp; <a href="mailto:finance@economodefood.com">finance@economodefood.com</a><br />
			Web: <a href="http://www.economode.in">www.economode.in</a><br />
			Activities: Manufacturer of Food processing machine<br />
			<u>52. Name: Mr. Dilip Poddar</u><br />
			Company: Emami Ltd<br />
			E-mail: <a href="mailto:dpoddar@emamigroup.com">dpoddar@emamigroup.com</a>, <a href="mailto:sudarshna@emamigroup.com">sudarshna@emamigroup.com</a><br />
			Web: <a href="http://www.emamigroup.com">www.emamigroup.com</a><br />
			Activities: Exports of Cosmetic &amp; Herbal Products<br />
			<u>53. Name: Mr. Shivadtta Sharma</u><br />
			Company: Ajanta Marbles Pvt. Ltd<br />
			E-mail: <a href="mailto:atmarble@gmail.com">atmarble@gmail.com</a><br />
			Web: <a href="http://www.ajantamarbles.com">www.ajantamarbles.com</a><br />
			Activities: Export marbles, Building Materials, Natural Stones.<br />
			<u>54. Name: &nbsp;Mr P.R. Lakshman Rao</u><br />
			Company: Kakatiya Energy Systems Pvt.Ltd<br />
			E-mail: <a href="mailto:mail@natureswitch.com">mail@natureswitch.com</a>, <a href="mailto:sales@natureswitch.com">sales@natureswitch.com</a><br />
			Web: <a href="http://www.natureswitch.com">www.natureswitch.com</a><br />
			Activities: Manufacturer of Energy Saving Product<br />
			<u>55. Name: Mr. Nazim Khan</u><br />
			Company: G.M Export<br />
			E-mail: <a href="mailto:info@gmexponline.com">info@gmexponline.com</a><br />
			Web: <a href="http://www.gmexportsindia.com">www.gmexportsindia.com</a><br />
			Activities: Exports, Terry Towels<br />
			<u>56. Name: Amit Mehrotra</u><br />
			Company: Intime Fire Appliances Pvt.Ltd<br />
			E-mail:amit@intimefire.com <a href="mailto:ho@intimefire.com">ho@intimefire.com</a><br />
			Web: <a href="http://www.Intimefire.com">www.Intimefire.com</a><br />
			Activities: Fire Extinguishers, Fire Fighting@ Suppression Systems Manufacturing, designing &amp; Installations.<br />
			<u>57. Name: Mr. Preeti todi</u><br />
			Company: Canpac Trends Pvt. Ltd<br />
			E-mail: <a href="mailto:buynow@canpac.in/team@canpac.in">buynow@canpac.in/team@canpac.in</a><br />
			Web: <a href="http://www.campac.in">www.campac.in</a><br />
			Activities: Manufacturer of printed Mono &amp; Corrugated Made of Paper of Paper Board.<br />
			<u>58. Name: Mr. Mohmmed Arif Malek</u><br />
			Company: Legion Energy Pvt. Ltd<br />
			E-mail: <a href="mailto:arif@legionenergy.org.in">arif@legionenergy.org.in</a><br />
			Web: <a href="http://www.legionenergy.org.in">www.legionenergy.org.in</a><br />
			Activities: Manufacturer of Automotive Filters&amp;* Trade for Electrical &amp; Instrument Equipment.<br />
			<u>59. Name: Mr. Salam Bin Sayeed Bin Mehfooz</u><br />
			Company: Royal Buona pvt. Ltd<br />
			E-mail: <a href="mailto:royalbuona@gmail.com">royalbuona@gmail.com</a><br />
			Web: <a href="http://www.royalbuona.com">www.royalbuona.com</a><br />
			Activities: They are in Travel &amp; Tourism, Sales &amp; services of electronic products, imports &amp; Export of all type of construction materials.<br />
			<u>60. Name: Mr. Ashok Gautam</u><br />
			Company: Sitson India Pvt. Ltd<br />
			E-mail: <a href="mailto:ashokg@sitsonindia.com">ashokg@sitsonindia.com</a><br />
			Web: <a href="http://www.sitsonindia.com">www.sitsonindia.com</a><br />
			Activities: Manufacturers of Industrial Boilers upto 300TPH &amp; also undertakes EPC Contracts of power plants upto 110MW &amp; sugar plants upto 7500TCD on turnkey basis.<br />
			<u>61. Name: Mr. suhas Dixit</u><br />
			Company: pyrocrat Systems LLP<br />
			E-mail: <a href="mailto:suhas@pyrocrat.com">suhas@pyrocrat.com</a><br />
			Web: <a href="http://www.Pyrocrat.com">www.Pyrocrat.com</a><br />
			Activities: Manufactures &amp; suppliers of a wide rang of the finest quality of continuous type paralysis plants. The product range consists of plastic pyrolysis plants, Tyre pyrolysis plants&amp; Gas Handling System, engaged in offering excellent pyrolysis Consultant Services.<br />
			<u>62 Name: Ms. Anuradha Agarwal</u><br />
			Company: Winners industries Pvt.Ltd<br />
			E-mail: <a href="mailto:anuradha@winnerssipl.com">anuradha@winnerssipl.com</a><br />
			Web: <a href="http://www.winnersipl.com">www.winnersipl.com</a><br />
			Activities: Manufacture, Exporter &amp; Suppliers of Stainless Steel Kitchenware, House wares, Bar wares, Ceramics ware, Porcelain wares &amp; bone china products from India.<br />
			<u>63. Name: Mr. M. Shesha sai</u><br />
			Company: Best Engineering Technologies<br />
			E-mail: <a href="mailto:bestengineering@gmail.com">bestengineering@gmail.com</a><br />
			Web: <a href="http://www.bestengineeringtechnologies.com">www.bestengineeringtechnologies.com</a><br />
			Activities: They are into Renewable energy Systems for Power Gas &amp; thermal Application.<br />
			<u>64. Name: Mr. Anshul/Krishan</u><br />
			Company: Super Gifts<br />
			E-mail: <a href="mailto:super.gift@yahoo.com">super.gift@yahoo.com</a>, <a href="mailto:ent.ashoka@yahoo.com">ent.ashoka@yahoo.com</a><br />
			Activities: They deal in corporate gifts &amp; sales promotional gifts. Such as pen stand, keychain, briefcase, coaster, purses, clock, pen drive&amp; many more.<br />
			<u>65. Name: Mr.Anshul/Krishan</u><br />
			Company: super Gifts<br />
			E-mail: <a href="mailto:super.gift@yahoo.com">super.gift@yahoo.com</a>, <a href="mailto:ent.ashoka@yahoo.com">ent.ashoka@yahoo.com</a><br />
			Activities: They deal in corporate gifts &amp; sales promotional gifts. Such as pen stand, keychain, briefcase, coaster, purses, clock, pen drive &amp; many more.<br />
			<u>66. Name: Mr. Farzin R. Karma</u><br />
			Company: Farohar Enterprises Private Ltd.<br />
			E-mail: <a href="mailto:Karma@Faroharltd.com">Karma@Faroharltd.com</a><br />
			Web: <a href="http://www.faroharltd.com">www.faroharltd.com</a><br />
			Activities: Farohar enterprises Limited is Poised to establish its place as global leader in providing specialist services especially to the maritime, oil-gas industries and other businesses that with to optimize their ability&nbsp; to handle sporadic, non-core, labor intensive or specialist projects with efficiencies absolutely similar to (and often better) than dome internally by themselves.<br />
			<u>67. Name: Mr. Rakesh Telawne</u><br />
			Company: Telawne power Equipment pvt.Ltd|<br />
			E-mail: <a href="mailto:deb@telawne.com">deb@telawne.com</a> <a href="mailto:/rst@telawne.com">/rst@telawne.com</a><br />
			Web: <a href="http://www.telawne.com">www.telawne.com</a><br />
			Activities: Manufacture of Distribution/power Transformer &amp; USS<br />
			<u>68. Name: Mr. Gurpreet Singh Soni</u><br />
			Company: ATLAS MACHINES (INDIA)<br />
			E-mail: <a href="mailto:info@atlasmachninesidia.com">info@atlasmachninesidia.com</a>, <a href="mailto:gurpreetsingh@atlasmachinesindia.com">gurpreetsingh@atlasmachinesindia.com</a><br />
			Web: www.atlasmachinesindia.com<br />
			Activities: They service the needs of the quality conscious buyer with impeccable heavy engineering &amp; infrastructure solutions.<br />
			<u>69. Name: Mr. Yarlagadda Sreekanth</u><br />
			Company: VSA Machines India<br />
			E-mail: <a href="mailto:sreekanth@vsamachines.com">sreekanth@vsamachines.com</a><br />
			Web: <a href="http://www.vsamachines.com">www.vsamachines.com</a></p>
		<p>
			1.<u> Hero Eco Group</u><br />
			Sector: Hero Brand Bicycles and Electric Scooters<br />
			Interest: to work with Ethiopian companies want to start or already in Bicycle business<br />
			Email: herogroup@gmail.com<br />
			2. <u>Water life India PLC</u><br />
			Sector: Water Purifying<br />
			Interest: Partner<br />
			Email: smenon@waterlifeindia.com<br />
			<u>3.Oakridge Energy PVt.LTd.</u><br />
			Sector: Energy<br />
			Interest: Partners<br />
			<u>4. Mahashakti Energy Limited</u><br />
			Sector: Energy<br />
			Interest:Partner<br />
			Email:mcplbti@gmail.com<br />
			<u>5. One World Corporation</u><br />
			Sector: Auromatic goods<br />
			Interest: Partner, plantation Auromatic woods<br />
			Email: arun@oneworldcorporation.in<br />
			<u>6. ReeLabs Pvt. Ltd.</u><br />
			Sector: Health<br />
			Interest: Partners and stand sales for Hospitals<br />
			Email; jitinder.gill@reelabs.com<br />
			<u>7. dph software services pvt.Ltd.</u><br />
			Sector: IT<br />
			Interest: Partner<br />
			<u>8. Suman Engineering Inc.</u><br />
			Sector: Vegetable oils,Diary, Beverage processing plants<br />
			Interest: Partner, Joint venture<br />
			Email: bhatoy@gmail.com<br />
			<u>9. Fieldking</u><br />
			Sector: Agriculture<br />
			Interest: Partner, Export machineries<br />
			Email: ankit.bhatnagar@fieldking.com<br />
			<u>10. Ascendere Ventures Pvt.Ltd</u><br />
			Sector: Minerals, Metals<br />
			Interest: Partner<br />
			Email: ankit.bhatnagar@fieldking.com<br />
			<u>11. LEA Associates South Asia</u><br />
			Sector: Consultancy<br />
			Interest: Consultancy in Engineering and planning<br />
			Email: biswarup@iasaindia.com<br />
			<u>12. Elcom Innovations Pvt Ltd</u><br />
			Email: rjunneja@elcominnovations.com<br />
			<u>13. Invert Sugar Pvt Ltd</u><br />
			Email: ashish@invertsugar.in<br />
			<u>14. Tube Investments of India</u><br />
			Email: srinivasanav@tii.murugappa.com<br />
			<u>15. JSW Steel Limited</u><br />
			Sector: Minerals<br />
			Interest: partners to import from Ethiopia<br />
			Email: ranapratap.singh@jsw.in<br />
			<u>16. Power Engineering India</u><br />
			Sector: Power<br />
			Interest: partner<br />
			Email: raghavendra@paikane.com<br />
			<u>17. Paradise Sanitary Ware Pvt. Ltd</u><br />
			Sector: Sanitary Ware<br />
			Interest:Partner<br />
			Email: paradisepottery72@gmail.com<br />
			<u>18. National Innovation Foundation-India</u><br />
			Sector: Agriculture, Electricity<br />
			Interest: Importers, small scale enterprises<br />
			Email: animeshp@nifindia.org<br />
			<u>19. D&amp;H Secheron Electrodes Pvt. Ltd</u><br />
			Sector: Export<br />
			Interest: partner for welding machines<br />
			Email: yogeshjoshi@dnhsecheron.net<br />
			<u>20. Winter Apparel Ltd</u><br />
			Sector:Textile, garment<br />
			Interest: Business Buyer<br />
			Email: Wintexties@rediffmail.com<br />
			<u>21. IL&amp;FS Education &amp; technology services</u><br />
			Sector: Education<br />
			Interest: Partner<br />
			Email: pooja.gianchandani@ilfsindia.com<br />
			<u>22. R.S. Pigments</u><br />
			Sector: Manufacturing, Export of Ultramarine Blue<br />
			Interest: GB partners, Importers of Edible oils<br />
			Email: info@ultramarineblueindia.in<br />
			<u>23. Real Boilers Pvt.Ltd</u><br />
			Email: ibrboilers@gmail.com<br />
			<u>24. Telcoma</u><br />
			Emailgagan@telcomatraining.com<br />
			<u>25. Modern Business Equipments &amp; services Pvt. Ltd</u><br />
			Sector: Digital Scales, Personal Health Care Products<br />
			Interest: Partner (want also to import Gold)<br />
			<u>26. Chrisleen Clean Room Projects</u><br />
			Sector: Furniture, Pharmaceuticals<br />
			Interest: Partner<br />
			Email: contact@chrisleengroup.com<br />
			<u>27. Dr. Agarwal’s Eye hospital</u><br />
			Sector: Health<br />
			Interest: Partner<br />
			Email: srividhyag@dragarwal.com<br />
			<u>28. Svarn Group</u><br />
			Sector: Telecom,Solar, prefab Houses<br />
			Interest: Partner<br />
			Email: jkmishra@svarn.com<br />
			<u>29. Anuar Biotech Industries</u><br />
			Sector: Pharmaceuticals<br />
			Interest: Partners, Pharma companies, importers of chemicals for cosmotics<br />
			Email: anuarbiotech1@gmail.com<br />
			<u>30. Empiire Group Infra and Holdings Pvt Ltd</u><br />
			Sector: Export/Import, Mining, Power, Infrastructure,Education<br />
			Interest: Partners<br />
			Email: raam@empiiregroup.com<br />
			<u>31. Versa Agro Industries</u><br />
			Sector: Manufacturing of blades<br />
			Interest: Partners<br />
			Email: versaagro@harvesterblades.com<br />
			<u>32. Bulk House</u><br />
			Sector: ecommerce, training<br />
			Interest: Distributers, Partners<br />
			Email: ali@bulkhouse.com<br />
			<u>33. Claas India</u><br />
			Sector: Manufacturing, Agriculture<br />
			Interest: Distributers, Partners<br />
			Email: varinder.bedi@claas.com<br />
			<u>34. Nimbuzz</u><br />
			Sector: Education and Media. Data analytics<br />
			Interest: To provide solution for Ethiopian businesses<br />
			Email: jobybabu@nimbuzz.com<br />
			<u>35. Coronet Foods</u><br />
			Sector: Foods<br />
			Interest: Partners<br />
			Email: coronet.off@gmail.com<br />
			<u>36. Kovai Agro Foods</u><br />
			Sector: Agro processing, plantation of cocunuts<br />
			Interest: Partners(proposal submitted to Embassy)<br />
			Email: kovaikisan@gmail.com<br />
			<u>37. Three Sixty Power Products</u><br />
			Sector: Leading manufacturer and Exporter of high quality power backup products<br />
			Interest: partner work closely to build brand in Ethiopia<br />
			Email: pranay.sharma@threesixty.in<br />
			<u>37. Ajanta Soya Limited</u><br />
			Sector: Oil<br />
			Interest: Partners<br />
			Email: abhey@ajantasoya.com<br />
			<u>38. Shri Niwas Food Industries</u><br />
			Sector: Agro processing<br />
			Interest: Partners (want to establish processing plant)<br />
			Email: singhalapoorv@gmail.com<br />
			<u>39. Vimal Organics Limited</u><br />
			Sector: Fertilizers, Chemicals, Edible oil<br />
			Interest: Partners<br />
			Email: arunaggrawal@yahoo.com<br />
			<u>40. National Agro Industries</u><br />
			Sector: Agro Processing<br />
			Interest: Partners<br />
			Email: sales@nationalagro.com<br />
			<u>41. Bravat India</u><br />
			Sector: Construction<br />
			Interest: Partners<br />
			Email: africa@bravatindia.com<br />
			<u>42. Clairvoyant Mobile Solutions</u><br />
			Sector: IT<br />
			Interest: Partners, IT/Mobile Application<br />
			Email: sid@clairvoyant.com<br />
			<u>43. SPML Infra Limited</u><br />
			Sector: Construction, Water treatment<br />
			Interest: Partners<br />
			Email: npsingh@spml.com.in<br />
			<u>44. Bob eprocure</u><br />
			Sector: eAuctions,edisposals,eTenders<br />
			Interest: Partner on the IT sector,Agriculture<br />
			Email: balkrishna.shetty@bobeprocure.com45.<br />
			<u>45. Name: Mr. Jitendra nair</u><br />
			Company: Suite No-312, Orion Business Park, G.B.<br />
			E-mail: <a href="mailto:Jeetendra.nair@unilightventures.com">Jeetendra.nair@unilightventures.com</a><br />
			Web: <a href="http://www.edulight.co.in">www.edulight.co.in</a><br />
			Activities: Education sector, Interested in Joint venture in Vocational Training, distance Education &amp; setting up Schools &amp; corporate training.<br />
			<u>46. Name: Mr. Shreepal Babulal Bafna</u><br />
			Company: Mohanlal Babulal Bafna<br />
			E-mail: <a href="mailto:mobotex@vsnl.com">mobotex@vsnl.com</a><br />
			Web: <a href="http://www.mbfashionhouse.com">www.mbfashionhouse.com</a><br />
			Activities: Manufacturers &amp; Exporters of Textile, Garments, Dress Materials &amp; All types of Cotton Fabrics.<br />
			<u>47. Name: Mr. Harikishan s, Chanchlani</u><br />
			Company: Chanchlani impex<br />
			E-mail: <a href="mailto:chanchlaniimpex@gmail.com">chanchlaniimpex@gmail.com</a><br />
			Web: <a href="http://www.chanchlaniimex.com">www.chanchlaniimex.com</a><br />
			Activities: Manufacturer &amp; Exporter of wall tiles and ceramic tiles<br />
			<u>48. Name: Mr.N. Gopalan</u><br />
			Company: Tesla Transformers Ltd<br />
			E-mail: <a href="mailto:mktg@teslatransformers.com">mktg@teslatransformers.com</a>, <a href="mailto:gopalan@teslatransformer.com">gopalan@teslatransformer.com</a>, <a href="mailto:tesla@bsnl.in">tesla@bsnl.in</a><br />
			Web: <a href="http://www.teslatransformers.com">www.teslatransformers.com</a><br />
			Activities: Tesla Group is a multi product and service supplying, erecting, testing and commiss-ion of power transformers&amp; distribution transformers, complete 220KV sub-Stations and turnkey projects.<br />
			<u>49. Name: Mr. B Sheetharam Shetty</u><br />
			Company: Onshore Constructions Co.Pvt. Ltd<br />
			E-mail: <a href="mailto:Info@onshoreconstruction.con">Info@onshoreconstruction.con</a><br />
			Web: <a href="http://www.onshoreconstruction.con">www.onshoreconstruction.con</a><br />
			Activities: Engineering Construction Company in the field, metallurgical, Chemical, Petro-Chemical, Refinery, Fertilizer, power, Air Separation, Vegetable Oil, Processing &amp; Pharmaceuticals Plants<br />
			<u>50. Name: Mr. Prashant Gadepalli</u><br />
			Company: Eletropneumatics &amp; Hydraulics (I) pvt. Ltd<br />
			E-mail: <a href="mailto:exports@electropneumatics.com">exports@electropneumatics.com</a><br />
			Web: <a href="http://www.electropneumatics.com">www.electropneumatics.com</a><br />
			Activities: Manufacturer of Hydraulic Press, Servo Mechanical Press Tube Bends &amp; Special Purpose Machine (SPM)<br />
			<u>51. Name: Arun Dadda Kriparam Verma</u><br />
			Company: Economode Food Equipment<br />
			E-mail: <a href="mailto:arundadda@hotmail.com">arundadda@hotmail.com</a>, <a href="mailto:finance@economodefood.com">finance@economodefood.com</a><br />
			Web: <a href="http://www.economode.in">www.economode.in</a><br />
			Activities: Manufacturer of Food processing machine<br />
			<u>52. Name: Mr. Dilip Poddar</u><br />
			Company: Emami Ltd<br />
			E-mail: <a href="mailto:dpoddar@emamigroup.com">dpoddar@emamigroup.com</a>, <a href="mailto:sudarshna@emamigroup.com">sudarshna@emamigroup.com</a><br />
			Web: <a href="http://www.emamigroup.com">www.emamigroup.com</a><br />
			Activities: Exports of Cosmetic &amp; Herbal Products<br />
			<u>53. Name: Mr. Shivadtta Sharma</u><br />
			Company: Ajanta Marbles Pvt. Ltd<br />
			E-mail: <a href="mailto:atmarble@gmail.com">atmarble@gmail.com</a><br />
			Web: <a href="http://www.ajantamarbles.com">www.ajantamarbles.com</a><br />
			Activities: Export marbles, Building Materials, Natural Stones.<br />
			<u>54. Name: Mr P.R. Lakshman Rao</u><br />
			Company: Kakatiya Energy Systems Pvt.Ltd<br />
			E-mail: <a href="mailto:mail@natureswitch.com">mail@natureswitch.com</a>, <a href="mailto:sales@natureswitch.com">sales@natureswitch.com</a><br />
			Web: <a href="http://www.natureswitch.com">www.natureswitch.com</a><br />
			Activities: Manufacturer of Energy Saving Product<br />
			<u>55. Name: Mr. Nazim Khan</u><br />
			Company: G.M Export<br />
			E-mail: <a href="mailto:info@gmexponline.com">info@gmexponline.com</a><br />
			Web: <a href="http://www.gmexportsindia.com">www.gmexportsindia.com</a><br />
			Activities: Exports, Terry Towels<br />
			<u>56. Name: Amit Mehrotra</u><br />
			Company: Intime Fire Appliances Pvt.Ltd<br />
			E-mail:amit@intimefire.com <a href="mailto:ho@intimefire.com">ho@intimefire.com</a><br />
			Web: <a href="http://www.Intimefire.com">www.Intimefire.com</a><br />
			Activities: Fire Extinguishers, Fire Fighting@ Suppression Systems Manufacturing, designing &amp; Installations.<br />
			<u>57. Name: Mr. Preeti todi</u><br />
			Company: Canpac Trends Pvt. Ltd<br />
			E-mail: <a href="mailto:buynow@canpac.in/team@canpac.in">buynow@canpac.in/team@canpac.in</a><br />
			Web: <a href="http://www.campac.in">www.campac.in</a><br />
			Activities: Manufacturer of printed Mono &amp; Corrugated Made of Paper of Paper Board.<br />
			<u>58. Name: Mr. Mohmmed Arif Malek</u><br />
			Company: Legion Energy Pvt. Ltd<br />
			E-mail: <a href="mailto:arif@legionenergy.org.in">arif@legionenergy.org.in</a><br />
			Web: <a href="http://www.legionenergy.org.in">www.legionenergy.org.in</a><br />
			Activities: Manufacturer of Automotive Filters&amp;* Trade for Electrical &amp; Instrument Equipment.<br />
			<u>59. Name: Mr. Salam Bin Sayeed Bin Mehfooz</u><br />
			Company: Royal Buona pvt. Ltd<br />
			E-mail: <a href="mailto:royalbuona@gmail.com">royalbuona@gmail.com</a><br />
			Web: <a href="http://www.royalbuona.com">www.royalbuona.com</a><br />
			Activities: They are in Travel &amp; Tourism, Sales &amp; services of electronic products, imports &amp; Export of all type of construction materials.<br />
			<u>60. Name: Mr. Ashok Gautam</u><br />
			Company: Sitson India Pvt. Ltd<br />
			E-mail: <a href="mailto:ashokg@sitsonindia.com">ashokg@sitsonindia.com</a><br />
			Web: <a href="http://www.sitsonindia.com">www.sitsonindia.com</a><br />
			Activities: Manufacturers of Industrial Boilers upto 300TPH &amp; also undertakes EPC Contracts of power plants upto 110MW &amp; sugar plants upto 7500TCD on turnkey basis.<br />
			<u>61. Name: Mr. suhas Dixit</u><br />
			Company: pyrocrat Systems LLP<br />
			E-mail: <a href="mailto:suhas@pyrocrat.com">suhas@pyrocrat.com</a><br />
			Web: <a href="http://www.Pyrocrat.com">www.Pyrocrat.com</a><br />
			Activities: Manufactures &amp; suppliers of a wide rang of the finest quality of continuous type paralysis plants. The product range consists of plastic pyrolysis plants, Tyre pyrolysis plants&amp; Gas Handling System, engaged in offering excellent pyrolysis Consultant Services.<br />
			<u>62 Name: Ms. Anuradha Agarwal</u><br />
			Company: Winners industries Pvt.Ltd<br />
			E-mail: <a href="mailto:anuradha@winnerssipl.com">anuradha@winnerssipl.com</a><br />
			Web: <a href="http://www.winnersipl.com">www.winnersipl.com</a><br />
			Activities: Manufacture, Exporter &amp; Suppliers of Stainless Steel Kitchenware, House wares, Bar wares, Ceramics ware, Porcelain wares &amp; bone china products from India.<br />
			<u>63. Name: Mr. M. Shesha sai</u><br />
			Company: Best Engineering Technologies<br />
			E-mail: <a href="mailto:bestengineering@gmail.com">bestengineering@gmail.com</a><br />
			Web: <a href="http://www.bestengineeringtechnologies.com">www.bestengineeringtechnologies.com</a><br />
			Activities: They are into Renewable energy Systems for Power Gas &amp; thermal Application.<br />
			<u>64. Name: Mr. Anshul/Krishan</u><br />
			Company: Super Gifts<br />
			E-mail: <a href="mailto:super.gift@yahoo.com">super.gift@yahoo.com</a>, <a href="mailto:ent.ashoka@yahoo.com">ent.ashoka@yahoo.com</a><br />
			Activities: They deal in corporate gifts &amp; sales promotional gifts. Such as pen stand, keychain, briefcase, coaster, purses, clock, pen drive&amp; many more.<br />
			<u>65. Name: Mr.Anshul/Krishan</u><br />
			Company: super Gifts<br />
			E-mail: <a href="mailto:super.gift@yahoo.com">super.gift@yahoo.com</a>, <a href="mailto:ent.ashoka@yahoo.com">ent.ashoka@yahoo.com</a><br />
			Activities: They deal in corporate gifts &amp; sales promotional gifts. Such as pen stand, keychain, briefcase, coaster, purses, clock, pen drive &amp; many more.<br />
			<u>66. Name: Mr. Farzin R. Karma</u><br />
			Company: Farohar Enterprises Private Ltd.<br />
			E-mail: <a href="mailto:Karma@Faroharltd.com">Karma@Faroharltd.com</a><br />
			Web: <a href="http://www.faroharltd.com">www.faroharltd.com</a><br />
			Activities: Farohar enterprises Limited is Poised to establish its place as global leader in providing specialist services especially to the maritime, oil-gas industries and other businesses that with to optimize their ability to handle sporadic, non-core, labor intensive or specialist projects with efficiencies absolutely similar to (and often better) than dome internally by themselves.<br />
			<u>67. Name: Mr. Rakesh Telawne</u><br />
			Company: Telawne power Equipment pvt.Ltd|<br />
			E-mail: <a href="mailto:deb@telawne.com">deb@telawne.com</a> <a href="mailto:/rst@telawne.com">/rst@telawne.com</a><br />
			Web: <a href="http://www.telawne.com">www.telawne.com</a><br />
			Activities: Manufacture of Distribution/power Transformer &amp; USS<br />
			<u>68. Name: Mr. Gurpreet Singh Soni</u><br />
			Company: ATLAS MACHINES (INDIA)<br />
			E-mail: <a href="mailto:info@atlasmachninesidia.com">info@atlasmachninesidia.com</a>, <a href="mailto:gurpreetsingh@atlasmachinesindia.com">gurpreetsingh@atlasmachinesindia.com</a><br />
			Web: www.atlasmachinesindia.com<br />
			Activities: They service the needs of the quality conscious buyer with impeccable heavy engineering &amp; infrastructure solutions.<br />
			<u>69. Name: Mr. Yarlagadda Sreekanth</u><br />
			Company: VSA Machines India<br />
			E-mail: <a href="mailto:sreekanth@vsamachines.com">sreekanth@vsamachines.com</a><br />
			Web: <a href="http://www.vsamachines.com">www.vsamachines.com</a><br />
			Activities: Manufacturer and Exporter of Bakery &amp; Biscuit making plants.<br />
			<u>70. Name: Mr. Chintan Sampat</u><br />
			Company: Oman Trade<br />
			E-mail: <a href="mailto:exports@omantrade.co.in">exports@omantrade.co.in</a><br />
			Web: <a href="http://www.omantrade.in">www.omantrade.in</a><br />
			Activities: Exports of Rice,and also exporters, suppliers of spices and dairy products<br />
			<u>71. Name: Mr. Pratik Mody</u><br />
			Company: Pratik Exim<br />
			E-mail: <a href="mailto:Info@pratikexim.in">Info@pratikexim.in</a><br />
			Web: <a href="http://www.pratikexim.in">www.pratikexim.in</a><br />
			Activities: Indenting Agents for export of Fabrics and Garments<br />
			<u>72. Name: Mr. Pranay Parekh</u><br />
			Company: Kalash Enterprises<br />
			E-mail: <a href="mailto:pranaymparekh@kalashenterprise.in">pranaymparekh@kalashenterprise.in</a><br />
			Activities<u>:</u>Interested setting up Food Processing Unit in Joint Venture, Import of cashews &amp; other Products from Mozambique<br />
			<u>73. Name: Mr. Himanshu Poojara</u><br />
			Company: Pooja Industries<br />
			E-mail: <a href="mailto:himanshu@poojagroup.in">himanshu@poojagroup.in</a><br />
			Web: <a href="http://www.poojagroup.in">www.poojagroup.in</a><br />
			Activities: Suppliers &amp; Manufacturers of engineering consumables &amp; specialized engineering equipments, industrial equipments including heavy lifting, earth moving drilling, safety, complete range of process engineering, instrumentation, mechanical transmission &amp; measurement including pumps, valves etc.<br />
			<u>74. Name: Mr. Vljay Pawar</u><br />
			Company: Accord India Gifts<br />
			E-mail: <a href="mailto:accordindiagifts@gmail.com">accordindiagifts@gmail.com</a><br />
			Web: <a href="http://www.accordindiagifts.com">www.accordindiagifts.com</a><br />
			Activities: they are designer of products such as clothing range comprised of caps, T-shirts, shirts, uniforms, &amp; few other like Umbrellas, watches etc.<br />
			<u>75. Name: Mr. Manoj Toshniwal</u><br />
			Company: EMC Ltd<br />
			E-mail: <a href="mailto:mtoshniwal@emcpower.com">mtoshniwal@emcpower.com</a><br />
			Activities: Turnkey Solution, Power Transmission, Tele communication Towers.<br />
			<u>76. Name: Mr. Rajesh Kothari</u><br />
			Company: ASHUTOSH RUBBER PVT.LTD<br />
			E-mail: <a href="mailto:mktg@ashutoshrubber.com">mktg@ashutoshrubber.com</a><br />
			Web: <a href="http://www.ashutoshrubber.com">www.ashutoshrubber.com</a><br />
			Activities: They are engaged in manufacturing of wide range of Rubber Products.<br />
			<u>77. Name: Mr. Suresh Chitangya</u><br />
			Company: GSR Marketing Ltd<br />
			E-mail:suresh@gsrlimited.invijayashree@gsrlimited.in<br />
			Activities: Merchant Exporters<br />
			<u>78. Name: Mr. Bala</u><br />
			Company: Prima Plastics Limited<br />
			E-mail: <a href="mailto:exports@primaplastics.com">exports@primaplastics.com</a>, <a href="mailto:bala@primaplastics.com">bala@primaplastics.com</a><br />
			Web: <a href="http://www.primaplastics.com">www.primaplastics.com</a><br />
			Activities: Manufacture plastic moulded furniture form chairs, baby chairs, dining tables, stools and teapoys in a wide range of attractive colors.<br />
			<u>88. Name: Raj Nair</u><br />
			Company: AGR Knowledge Services pvt.Ltd<br />
			E-mail: <a href="mailto:rishikeshd@avalonglobalresearch.com">rishikeshd@avalonglobalresearch.com</a><br />
			Web: <a href="http://www.avalonglobalresearch.com">www.avalonglobalresearch.com</a><br />
			Activities: They are engaged in the business of providing research and information on various sections to companies and governments in India and abroad.<br />
			<u>89. Name: Mr. H.P.Yadav</u><br />
			Company: Paltech Cooling Towers &amp; Equipment Ltd.<br />
			E-mail: <a href="mailto:exports@paltech.in">exports@paltech.in</a><br />
			Web: <a href="http://www.Paltech.in">www.Paltech.in</a><br />
			Activities: Manufacturing of Cooling Towers (FRP/Wooden/RCC)@Chilling plants for Various industrial Applications<br />
			<u>90. Name: Mr. Akash Agarwal</u><br />
			Company: Crystal Logistic Cool Chain Ltd<br />
			E-mail: <a href="mailto:aa@crystalgoup.in">aa@crystalgoup.in</a><br />
			Web: <a href="http://www.crystalgroup.in">www.crystalgroup.in</a><br />
			Activities: Crystal Logistic Cool Chain Ltd is a complets Logistics and Supply chain Service provide Crystal is One of The largest and Fastest Growing Service Providers in the integrate cold Chain Logistics Solution space. Crysta warehousing (Cold Storages), Refrigerate Transportation on a Pan-India Network.<br />
			<u>91. Name: Mr. Mahesh Dashrath Bobadi</u><br />
			Company: Petrof refining Technologies<br />
			E-mail: <a href="mailto:info@petrofrefining.com">info@petrofrefining.com</a><br />
			Web: <a href="http://www.pretrofrefining.com">www.pretrofrefining.com</a><br />
			Activities: Used oil Re-refining pants &amp; Machinery, Grease plant, Lube Oil Blending Plant, Supply, Installation &amp; Operation<br />
			<u>92. Name: Mr. Sanjay Kumar Jain</u><br />
			Company: PSS Marmi Granites LLP<br />
			E-mail: <a href="mailto:ananjay@jaiininternational.in">ananjay@jaiininternational.in</a><br />
			Web: <a href="http://www.jaiinternatonal.in">www.jaiinternatonal.in</a><br />
			Activities: Manufacturer of Marble, Granite, Sandstone, Slate Stone.<br />
			<u>93. Name: Mr. Alok Singh</u><br />
			Company: LYkis Ltd.<br />
			E-mail: <a href="mailto:intlsales@lykisgroup.com">intlsales@lykisgroup.com</a>, <a href="mailto:sales@lykisgroup.com">sales@lykisgroup.com</a><br />
			Web: <a href="http://www.lykisgroup.com">www.lykisgroup.com</a><br />
			Activities: they are into tea plantation, manufactory of quality tea and sale and export of the tea in both domestic and overseas markets. The company has an ongoing development programme for its garden, which is expected to result in increase in output. The Tea is sold through auctions and in addition, it is also directly marketed through private and direct sales in bulk tea bags.<br />
			<u>94. Name: Mr. Cruz lgnatius</u><br />
			Company: ITL WORLD- Travel Management Company<br />
			E-mail: <a href="mailto:cruz.ignatius@ITLtravel.com">cruz.ignatius@ITLtravel.com</a><br />
			Web: <a href="http://www.ITLWorld.com">www.ITLWorld.com</a><br />
			Activities: Their business activates and services cater to Corporate Companis/ Clients for all their travel requirements ranging from providing airline travel (ticketing &amp; reservation), hotel procurement, conference/event management, training, team building &amp; marketing representation etc-we also offer entire turnkey solutions for all MICE requirements.<br />
			<u>95. Name: Mr. Parag Tejura</u><br />
			Company: Saurashtra Vyapar Udyog Mahamandal<br />
			E-mail: <a href="mailto:Inquiry.svum@gmail.com">Inquiry.svum@gmail.com</a><br />
			Web: <a href="http://www.svum.in">www.svum.in</a><br />
			Activities: An Apex body of Trade &amp; industry, Working for the problem solving and development organization.<br />
			<u>96. Name: Mr. Nirav Khambati</u><br />
			Company: Tata ClassEdge<br />
			E-mail: <a href="mailto:nkhambati@tataclassedge.com">nkhambati@tataclassedge.com</a><br />
			Web: <a href="http://www.tataclassedge.com">www.tataclassedge.com</a><br />
			Activities: Tata ClassEdge provides an innovative solution that empowers teachers to teach better, with an effective blend of curriculum-mapped classroom activates and interactive technology.<br />
			<u>97. Name: Mr. Ashutos Biyani</u><br />
			Company: vinayak Corporation<br />
			E-mail: <a href="mailto:sales@vinayakcorporation.com">sales@vinayakcorporation.com</a><br />
			Web: <a href="http://www.vinayakcorporation.com">www.vinayakcorporation.com</a><br />
			Activities: Food ingredients, pharmaceutical Raw Material, Herbal Extract, Food Colurs, Menthol Crystals, Spice Oils/Oleoresins.<br />
			<u>98. Name: Parivesh Khandelwal</u><br />
			Company: HM Overseas<br />
			E-mail: <a href="mailto:hiparivesh@gmail.com">hiparivesh@gmail.com</a><br />
			Activities: Manufacture of readymade garments (especially for children)<br />
			<u>99. Name: Atul B.Jain</u><br />
			Company: Jain Irrigation Systems Ltd.<br />
			E-mail: <a href="mailto:atul@jains.com,ps@jains.com">atul@jains.com,ps@jains.com</a><br />
			Web: <a href="http://www.jains.com/">http://www.jains.com/</a><br />
			Activities: Manufactures &amp; Exporters of Drip, Micro Sprinkler, Irrigation Systems, PVC Foam Sheets, PVC Pipes, etc.<br />
			<u>100. Name: Rajesh Bhatia</u><br />
			Company: prerna Enterprises<br />
			E-mail: <a href="mailto:nikonindia@gmail.com">nikonindia@gmail.com</a><br />
			Activities: Consumer Electronics<br />
			<u>101. Name: Dhvanil Shah</u><br />
			Company: Jhanvi EarthMovers<br />
			E-mail: <a href="mailto:spares@jhanviearthmovers.com">spares@jhanviearthmovers.com</a><br />
			Web: <a href="http://www.jhanviearthmovers.com">www.jhanviearthmovers.com</a><br />
			Activities:Spares for Earthmoving equipments<br />
			<u>102. Name: Jay Thakker</u><br />
			Company: Bhairav Textile Exports pvt. Ltd.<br />
			E-mail: <a href="mailto:Jay@bhairav.com">Jay@bhairav.com</a><br />
			Web: <a href="http://www.bhairav.com">www.bhairav.com</a><br />
			Activities: Manufacturers &amp; Exporters of Textile, Garments, Dress materials &amp; All types of Cotton Fabrics.<br />
			<u>103. Name: Gautam Mehta</u><br />
			Company: Velocity International<br />
			E-mail: <a href="mailto:Gautammehtas@hotmail.com">Gautammehtas@hotmail.com</a><br />
			Activities: Gensets &amp; Agro Related Equipments<br />
			<u>104. Name: Vishal Nathwani</u><br />
			Company: KICH Architectural products pvt.ltd.<br />
			E-mail: <a href="mailto:Manager.Export@kichindia.com">Manager.Export@kichindia.com</a><br />
			Web: <a href="http://www.kichindia.com">www.kichindia.com</a><br />
			Activities: Leading manufacturer in the premium segment of Architectural Hardware, Bathroom Accessories and Handrails &amp; Baluster Systems<br />
			<u>105. Name: Mittal Patel</u><br />
			Company: DSV Air &amp; sea P. Ltd.<br />
			E-mail: <a href="mailto:mittal.paterl@in.dsv.com">mittal.paterl@in.dsv.com</a><br />
			Web: <a href="http://www.dsv.com">www.dsv.com</a><br />
			Activities: Global Transport &amp; Logistic Company<br />
			<u>106. Name: M.R.Rajesh</u><br />
			Company: Power One Microsystems P.Ltd.<br />
			E-mail: <a href="mailto:rajeshmr@Poweroneups.com">rajeshmr@Poweroneups.com</a><br />
			Web: <a href="http://www.poweroneup.com">www.poweroneup.com</a><br />
			Activities: Manufacturers of Power Supply Systems and power Conditioning Equipments. Online uninterruptable power Supply (UPS), Solar power Systems, Solar Power Conditioning Unit, Line interactive, Transformer, Servo Controlled Voltage Stabilizer, Frequency Converters<br />
			<u>107. Name: Sanjay Mehta</u><br />
			Company: Nakoda Products<br />
			E-mail: <a href="mailto:info@nakodaproducts.com">info@nakodaproducts.com</a>, <a href="mailto:purchase.nakoda@gmail.com">purchase.nakoda@gmail.com</a><br />
			Web: <a href="http://www.nakodaproducts.com">www.nakodaproducts.com</a><br />
			Activities: Manufacturing Transformers.<br />
			Name: Pranay Bhattacharya<br />
			<u>108. Company: Symphony Limited</u><br />
			E-mail: <a href="mailto:pranay.bhattacharya@symphonylimited.com">pranay.bhattacharya@symphonylimited.com</a><br />
			Web: <a href="http://www.symphonylimited.com">www.symphonylimited.com</a><br />
			Activities: A world leader in evaporative Air coolers.<br />
			<u>109. Name: Paresh Bhatt</u><br />
			Company: Harbor Agro P. Ltd. (Harbor Group)<br />
			E-mail: <a href="mailto:Paresh.vippl@gmail.com">Paresh.vippl@gmail.com</a><br />
			Web: <a href="http://www.harborgroup.in">www.harborgroup.in</a><br />
			Activities: Consulting Services &amp; Agro.<br />
			110. Name: Manojkumar Tarachand Pansari<br />
			Company: Tarpan Prints Pvt.ltd.<br />
			E-mail: <a href="mailto:manojpansari@gmail.com">manojpansari@gmail.com</a><br />
			Activities: Manufacturer of Embroidered Fabrics.<br />
			<br />
			Name: Sanjay Mangal<br />
			Company: HINDPRAKSH TRADELINK Pvt.ltd<br />
			E-mail: <a href="mailto:adp@hindprakash.com">adp@hindprakash.com</a><br />
			Web: <a href="http://www.hindprkash.com">www.hindprkash.com</a><br />
			Activities: Manufacturers, Importers, Exporters of Acid Dyes, Reactive Dyes, Dye Intermediates Auxiliaries<br />
			<br />
			Name: Nayan jain<br />
			Company: S.S. Metal Industries<br />
			E-mail: <a href="mailto:ssmetalindustries90@yahoo.com">ssmetalindustries90@yahoo.com</a>, <a href="mailto:nayan_1234@yahoo.com">nayan_1234@yahoo.com</a><br />
			Activities: Specialist in SS Tubes, Designer Sheets &amp; All Kinds of Fabrication Works<br />
			<br />
			Name: Satyanarayana Moharana<br />
			Company: Aspire Disruptive Skill foundation<br />
			E-mail: <a href="mailto:satya@adsfoundation.or">satya@adsfoundation.or</a><br />
			Activities: Skill Development foundation, Creating Skilled Workforce and Social Entrepreneurs<br />
			<br />
			Name: Ashok tambulkar<br />
			Company: Perfect Systems<br />
			E-mail: <a href="mailto:tambulkarashok@gmail.com">tambulkarashok@gmail.com</a>, <a href="mailto:perfectsystem@yahoo.com">perfectsystem@yahoo.com</a><br />
			Activities: Manufacturer, supplier, exporter of Writing Instrument, machinery, pharmaceutical Liquid Filing Machinery, processing plan and Fluid Dispensing system.<br />
			<br />
			Name: Mr. Nikhil Punamiya<br />
			Company: Punamiya Trading House Opc pvt. Ltd<br />
			E-mail: <a href="mailto:Ptradinghouse@gmail.com">Ptradinghouse@gmail.com</a><br />
			Activities: Garment<br />
			<br />
			Name: Nayan Shah<br />
			Company: Friends Exim<br />
			E-mail: <a href="mailto:nayan06@gmail.com">nayan06@gmail.com</a><br />
			Activities: Dealer of Engineering items, All Food products, All Kitchen Ware products.<br />
			<br />
			Name: Mr. Charmmy M. damani<br />
			Company: MHC Trading Company<br />
			E-mail: <a href="mailto:mhctrading@hotmail.com">mhctrading@hotmail.com</a><br />
			Activities: Rice, sugar, Pharma, PP woven Bags, General Exports Etc<br />
			<br />
			Name: Mr. Mahendra Damani<br />
			Company: MHC Trading Co.<br />
			E-mail: <a href="mailto:mhctrading@hotmail.com">mhctrading@hotmail.com</a><br />
			Activities: Rice, Sugar, Pharma, PP Woven Bags, General Exports Etc.<br />
			<br />
			Name: Ms. Chitra Bosmaiya<br />
			Company: Archi Exim Pvt. Ltd.<br />
			E-mail: <a href="mailto:chitra@archiimpex.com">chitra@archiimpex.com</a><br />
			Activities: Distributive Manufacturing, custom Mfg, Food, Personal Care, Pharma, Agro, Oil &amp;Gas<br />
			<br />
			Name: Mr. C Dhandayuthapani<br />
			Company: MAG Solvics private Limited<br />
			E-mail: <a href="mailto:cdpani@magsolvics.com">cdpani@magsolvics.com</a><br />
			Web: <a href="http://www.magsolvics.com">www.magsolvics.com</a><br />
			Activities: MAG Solvics private Limited is a multiproduct, multi-market enterprise that provides total testing solutions and online monitoring solutions for all the segments of Textile industry viz. ginning, spinning, weaving, Knitting, processing, apparels and garments all over the world with installation exceeding 8000 units<br />
			<br />
			Name: Ms. Naman Shah<br />
			Company: Archi Exim Pvt.Ltd<br />
			E-mail: <a href="mailto:import@archiimpex.com">import@archiimpex.com</a><br />
			Activities: Distributive Manufacturing, Custom Mfg, Food, Personal Care, pharma, Agro, Oil &amp; Gas<br />
			<br />
			Name: Mr. S Kasi viswanathan<br />
			Company: MAG Solvics Private Limited<br />
			E-mail: <a href="mailto:kasi@magsolvics.com">kasi@magsolvics.com</a><br />
			Activities: MAG Solvics private Limited is a multiproduct, multi-market enterprise that provides total testing solutions and on line monitoring solutions for all the segments of Textile industry viz. ginning, spinning, weaving, knitting, processing, apparels and garments all over the world with installations exceeding 8000 units.<br />
			<br />
			Name: Mr. Avirat Patel<br />
			Company: NBE Motors pvt Ltd<br />
			E-mail: <a href="mailto:info@newbharat.com">info@newbharat.com</a><br />
			Web: <a href="http://www.newbharat.com">www.newbharat.com</a><br />
			Activities: Manufactures AC &amp; DC motors, Textile applications motors, coolant pumps, special motors like Vibratiory motor, Brake motor, Cooling tower motor, Torque motor, Dual Speed motor, Geared motor, Gear Box, Bench Grinder, Polisher, Air blower, regenerative Blower.<br />
			<br />
			Name: Mr.Deepak Wagh<br />
			Company: innovative International Energy Solutions<br />
			E-mail: <a href="mailto:Deepak.wagh@iies.com.in">Deepak.wagh@iies.com.in</a><br />
			Web: <a href="http://www.iies.co.in">www.iies.co.in</a><br />
			Activities: Exporter of power and Distribution Transformer<br />
			<br />
			Name: Mr. Prasad Pawar<br />
			Company: innovative international Energy Solutions<br />
			E-mail: <a href="mailto:Deepak.wagh@iies.com.in">Deepak.wagh@iies.com.in</a><br />
			Web: <a href="http://www.iies.co.in">www.iies.co.in</a><br />
			Activities: Exporter of power and Distribution Transformer<br />
			<br />
			Name: Mr. Rajesh V. Shar<br />
			Company: Plamadera Composite PVT. Ltd.<br />
			E-mail: rajesh@plamadera.com<br />
			Activities: Manufacturer of WPC Boards &amp; WPC Door Frames<br />
			Company<br />
			Name: Chanderpur Works PVT. Ltd.<br />
			Sector: Leading manufacturers and exporters of Biomass Grasifiers, Cement Plants, Fertilizer Plants, Mineral Processing Equipment, Lime Plants, and Material Handling Equipment.<br />
			Interest: Dealers’/Individuals /Companies, who are interested in becoming their dealer to promote their company in Ethiopia.<br />
			Consultants (on paid basis) to help them in the marketing of these gasifiers.<br />
			E-mail: buyethiopian@gmail.com<br />
			<br />
			Name Company: A Expert<br />
			Address: 407-408, Sarthink Square, Near Gnfic Info Tower, S G Road<br />
			City: Ahmedabed<br />
			State: Gujarat<br />
			Tel: 91-7930070400, 91-79 66611999, 91-8866004008<br />
			E-mail: <a href="mailto:ami@labhgroup.com">ami@labhgroup.com</a> , <a href="mailto:sunit@labhgroup.com">sunit@labhgroup.com</a><br />
			Web: <a href="http://www.labhgroup.com">www.labhgroup.com</a><br />
			Activities: Manufacturer and Exporter of wide range of packaging machines, food &amp;<br />
			Beverages processing machines, pharmaceutical machines etc.<br />
			<br />
			Name Company: P Export<br />
			Address: 407-408, Sarthink Square, near Gnfc Info Tower, S G Road<br />
			City: Ahmedabad<br />
			State: Gujarat<br />
			Tel: 91-7930070400, 91-79 66611999, 91-8866002001<br />
			E-mail: <a href="mailto:sunit@labhgroup.com">sunit@labhgroup.com</a> , <a href="mailto:sunit@labhgroup.com">sunit@labhgroup.com</a><br />
			Web: <a href="http://www.labhgroup.com">www.labhgroup.com</a><br />
			Activities: Manufacturer and Exporter of wide range of quality packaging materials, Plastic<br />
			products, food and agro products etc.<br />
			<br />
			Name Company: Abdos Lamitube Pvt. Ltd.<br />
			Address: Vill.Pub-Boragaon, Jalukbari, Dist.Kanrup<br />
			City: Guwahati<br />
			State: Assam<br />
			Tel: 91-361 2740640, 91 361 2740640, 91 9864265279<br />
			E-mail: <a href="mailto:amit.agarwall@abdosindia.com">amit.agarwall@abdosindia.com</a><br />
			Web: <a href="http://www.abdosindia.com">www.abdosindia.com</a><br />
			Activities: Manufacture and supply of laminate tube, export.<br />
			<br />
			Name Company: Adachi Natural Polymers Pvt. Ltd.<br />
			Address: C-1, 91/14, phase I, F Road, GIDC, Vatva<br />
			City: Ahmedabad<br />
			State: Gujarat<br />
			Tel: 91-79 25831375, 91 79 25893255, 91 7574870546<br />
			E-mail: <a href="mailto:gaurav@adachigroup.com">gaurav@adachigroup.com</a>, <a href="mailto:info@adachigroup.com">info@adachigroup.com</a><br />
			Web: <a href="http://www.adachigroup.com">www.adachigroup.com</a><br />
			Activities: Textile printing Thickeners, Fast Hydration Guar Gum powder, Guar Gum<br />
			powder for food and feed Grad, Industrial Grade Guar Gum powder.<br />
			<br />
			Name Company: Adgums Pvt. Ltd.<br />
			Address: Mahalazmi Compound, opp. Rustom Mills, Dudheshwar Road<br />
			City: Ahmedabad<br />
			State: Gujarat<br />
			Tel: 91- 7925622632, 91- 9825048605<br />
			E-mail: <a href="mailto:adgums@priniting-thickeners.com">adgums@priniting-thickeners.com</a><br />
			Web: <a href="http://www.printing-thickeners.com">www.printing-thickeners.com</a><br />
			Activities: Food Grade Gums, Textile Gums, Natural Paper Gums, Textile Sizing Chemicals<br />
			<br />
			Name Company: Aglo Exim Pvt. Ltd.<br />
			Address: Diamond Chamber4, Chowringhee Lane, Block-IISuit<br />
			City: Kolkata<br />
			State: West Bengal<br />
			Tel: 91-9830093390<br />
			E-mail: <a href="mailto:operation@aglopackagings.com">operation@aglopackagings.com</a><br />
			Activities: plastic products<br />
			<br />
			Name Company: Aglo Pacagins Pvt. Ltd.<br />
			Address: 13a, Dacres Lane, 5<sup>th</sup> Floor, Room No. 508<br />
			City: Kolkata<br />
			State: West Bengal<br />
			Tel: 91- 3322430309, 91-3330287939, 91-9836793789<br />
			E-mail: <a href="mailto:operation@aglopackagings.com">operation@aglopackagings.com</a><br />
			Web: <a href="http://www.algopackagings.com">www.algopackagings.com</a><br />
			Activities: Plastic caps and closures<br />
			<br />
			Name Company: Agri Life<br />
			Address: Plot No. 154/A5-1, Svice, ida Bollaram, Medak Dist<br />
			City: Hyderabad<br />
			State: Telangana<br />
			Tel: 91-8458279905, 91-9885449278<br />
			E-mail: <a href="mailto:dr.venkatesh@agrilige.in">dr.venkatesh@agrilige.in</a>, <a href="mailto:agrilife@agrilife.in">agrilife@agrilife.in</a><br />
			Web: <a href="http://www.agrilife.in">www.agrilife.in</a><br />
			Activities: Bio Pesticide, Bio Fertilizers, Bio Stimulants, Agri Inputs, Bio Gardens &amp; Turfs<br />
			<br />
			Name Company: Akik Dye Chem (Unit-2)<br />
			Address: Behind J.K. Barrel, Near Chandola Police Chowki, Danilimda<br />
			City: Ahmedabad<br />
			State: Gujarat<br />
			Tel: 91-7925000000, 91-7925323778, 91-9825040000<br />
			E-mail: <a href="mailto:info@akikdyechem.com">info@akikdyechem.com</a><br />
			Web: <a href="http://www.akikdyechem.com">www.akikdyechem.com</a><br />
			Activities: Vat Dyes, Disperse Dyes, Reactive Dyes, and Pigments<br />
			<br />
			Name Company: Akr Plastic Industry<br />
			Address: No. 31, A &amp; B, North Street, Mannarpuram<br />
			City: Tiruchirapalli<br />
			State: Tamil Nadu<br />
			Tel: 91-4316504103, 91-4312520237, 91-9842455536<br />
			E-mail: <a href="mailto:akrmachinery@gmail.com">akrmachinery@gmail.com</a><br />
			Activities: Paper cup manufacturing machines<br />
			<br />
			Name Company: Ambuja Intermediates Ltd.<br />
			Address: 4<sup>th</sup> Floor, Apsara Cinema Building, D.B. Road, Lamington road<br />
			City: Mumbai<br />
			State: Maharashtra<br />
			Tel: 91-2242127777<br />
			E-mail: <a href="mailto:aakash@ambujaindia.com">aakash@ambujaindia.com</a> , <a href="mailto:info@ambujaindia.com">info@ambujaindia.com</a><br />
			Web: <a href="http://www.ambujaindia.com">www.ambujaindia.com</a><br />
			Activities: Intermediates, Dyestuffs, Pigments<br />
			<br />
			Name Company: Anil Plastics and Enterprises<br />
			Address: Plot No. 16, S.No. 253/2/3 Tirumala Ind Estate Behind Filtrum Tools, Hinjewadi<br />
			City: Pune<br />
			State: Maharashtra<br />
			Tel: 91-2066758036<br />
			E-mail: <a href="mailto:anil.naik@anilplastics.com">anil.naik@anilplastics.com</a> Web: <a href="http://www.anilplastics.com">www.anilplastics.com</a><br />
			Activities: Plastic injection Moulded Parts, Plastic Clips, Cable Ties, Auto Parts<br />
			<br />
			Name Company: Apan Imex Pvt. Ltd.<br />
			Address: 31Titanium, Corporate Road Prahaladnagar<br />
			City: Ahmedabad<br />
			State: Gujarat<br />
			Tel: 91- 9740000000, 91-7940321881, 91- 9925010682<br />
			E-mail: <a href="mailto:accounts@apanimex.com">accounts@apanimex.com</a><br />
			Web: <a href="http://www.apanimex.com">www.apanimex.com</a><br />
			Activities: Colorants, Optical Brightening Agents, Polymers, Laminates, Colorant<br />
			Intermediates, Pharmaceutical, Intermediates, Active Pharmaceutical,<br />
			Ingredients, Fragrances &amp; Flavours.<br />
			<br />
			Name Company: Ascent India<br />
			Address: L-2, Sector 3, Industrial Area, Bawana<br />
			City: Delhi<br />
			State: Delhi<br />
			Tel: 91- 1145142141, 91- 1145142141, 91- 9810685021<br />
			E-mail: <a href="mailto:varun@ascenthose.com">varun@ascenthose.com</a><br />
			Web: <a href="http://www.ascenthose.com">www.ascenthose.com</a><br />
			Activities: Welding Hose, Spary and Mining Hose, Sanitary Hoses, PVC Braided Hoses,<br />
			Fire Fighting Hoses, Automobiles Hoses, PVC Flexible Pipe and Tubes, Medical<br />
			Tubes, Thermoplastic Hoses, Air Hose<br />
			<br />
			Name Company: Asia Bulk Sacks Pvt. Ltd.<br />
			Address: 344/1/3, Kalyanpura Road, Nani Kadi<br />
			City: Mehsana<br />
			State: Gujarat<br />
			Tel: 91- 2764327533, 91- 2764242533, 91- 9998953909<br />
			E-mail: <a href="mailto:info@asiabulksacks.com">info@asiabulksacks.com</a><br />
			Web: <a href="http://www.asiabulksacks.com">www.asiabulksacks.com</a><br />
			Activities: Packaging materials<br />
			<br />
			Name Company: Asiatic Colour Chem Ind. Ltd.<br />
			Address: Plot No. 1503/1504, GIDC, Phase 1 Naroda<br />
			City: Ahmedabad<br />
			State: Gujarat<br />
			Tel: 91- 9913000000, 91-9913000000<br />
			E-mail: <a href="mailto:intl@asiaticcolour.com">intl@asiaticcolour.com</a><br />
			Web: <a href="http://www.asiaticcolour.com">www.asiaticcolour.com</a><br />
			Activities: Ink Dyes, Fur Dyes, Textile Dyes<br />
			<br />
			Name Company: Associated Dyestuff Pvt. Ltd.<br />
			Address: A-1/4, Phase 1, Vatva GIDc<br />
			City: Ahmedabad<br />
			State: Gujarat<br />
			Tel: 91- 7965000000, 91-9825000000<br />
			E-mail: <a href="mailto:v.shah@aicdyes.com">v.shah@aicdyes.com</a><br />
			Web: <a href="http://www.aicdyes.com">www.aicdyes.com</a><br />
			Activities: Colorants &amp; intermediates, Reactive Dyestuffs, Dye Intermediates<br />
			<br />
			Name Company: Aumento Polymers Tekniks Pvt. Ltd.<br />
			Address: No-28, second Floor, 4D Suare Mall, Opp IIT Gandhinagar Sabarmati, Motera<br />
			City: Ahmedabad<br />
			State: Gujarat<br />
			Tel: 91- 7940083823, 91- 9712964255<br />
			E-mail: <a href="mailto:info@aumentopolymer.co.in">info@aumentopolymer.co.in</a><br />
			Web: <a href="http://www.aumentopolymer.co.in">www.aumentopolymer.co.in</a><br />
			Activities: Woven polypropylene Bages, Woven polypropylene Fabric, Laminated Woven<br />
			Polypropylene Bags, AD Star Block Block Bottom Valve Bag, Flexible<br />
			Laminated Films and pouches, Woven HDPE bags and fabrics.<br />
			<br />
			Name Company: Azeocryst Organics Pvt. Ltd.<br />
			Address: 1G, Siddhivinayak Chamber, Opp. MIG Club, Gandhi Nagar, Bandra<br />
			City: Mumbai<br />
			State: Maharashtra<br />
			Tel: 91- 2226511513, 91-9869001672<br />
			E-mail: <a href="mailto:nandubhirud@azeocryst.com">nandubhirud@azeocryst.com</a><br />
			Activities: Grignard Reagents. Bulk drugs and intermediates, perfumery and R &amp; D and<br />
			Custom Sythesis<br />
			<br />
			Name Company: Bagadia Colorchem Ltd.<br />
			Address: 502 Sahhiya Enclave, V.P.Road, Vile Parle (W)<br />
			City: Mumbai<br />
			State: Maharashtra<br />
			Tel: 91-2226111982<br />
			E-mail: <a href="mailto:bagadiacolourchem@gmail.com">bagadiacolourchem@gmail.com</a><br />
			Web: <a href="http://www.bagadiacolourchem.com">www.bagadiacolourchem.com</a><br />
			Activities: Dyes &amp; Pigments<br />
			<br />
			Name Company: Bankim Plast Pvt. Ltd<br />
			Address: 601, Bhakti Park, R H B Road, Mulund (W)<br />
			City: Mumbai<br />
			State: Maharashtra<br />
			Tel: 91-2261577522, 91- 2261577566, 91- 9819377754<br />
			E-mail: <a href="mailto:info@bankimplast.com">info@bankimplast.com</a> , <a href="mailto:ahkaria@bankimplast.com">ahkaria@bankimplast.com</a><br />
			Web: <a href="http://www.bankimplast.com">www.bankimplast.com</a><br />
			Activities: Plastic Master batch Colours<br />
			<br />
			Name Company: Bhavani Chemicals<br />
			Address: C-1, 91/15, Phase I, F Road, GIDC, Vatva<br />
			City: Ahmedabad<br />
			State: Gujarat<br />
			Tel: 91- 7925831375, 91-7925893255, 91- 9925037759<br />
			E-mail: <a href="mailto:gaurav@adachigroup.com">gaurav@adachigroup.com</a> , <a href="mailto:info@adachigroup.com">info@adachigroup.com</a><br />
			Web: <a href="http://www.adachigroup.com">www.adachigroup.com</a><br />
			Activities: Agricultural, Cattle Feed, Dyes &amp; Dye Intermediates Raw Materials, Poultry Feed Raw Materials, Pharmaceuticals, Industrial Chemicals<br />
			<br />
			Name Company: Bhim Polyfab Industries<br />
			Address: 406, 4<sup>th</sup> Floor, Lotus House, New Marine Lines, Near Liberty Cinema, Marine Lines City: Mumbai<br />
			State: Maharashra<br />
			Tel: 91- 2266338754, 91-9819160964<br />
			E-mail: <a href="mailto:rajesh@damanpolyfabs.com">rajesh@damanpolyfabs.com</a><br />
			<br />
			Name Company: Biocare (India) Pvt. Ltd.<br />
			Address: Shewalkar Garden Wing ‘E Block No -57/57a, Opp. Vnit Gate, S.A. Road, Gopal Nagar<br />
			City: Nagpur<br />
			State: Maharashtra<br />
			Tel: 91-7122224344, 91- 7122224344, 91- 9822565830<br />
			E-mail: <a href="mailto:biocare_2000@yahoo.com">biocare_2000@yahoo.com</a><br />
			Web: <a href="mailto:biocare.team@gmail.com">biocare.team@gmail.com</a><br />
			Activities: Organic Silica Soil Conditioner, Plant Growth Promoter, Organic Fertilizer, Crop<br />
			Amendment, Growth Enhancer, Botanical Pesticide And Fungicide<br />
			<br />
			Name Company: Bolw Engineering<br />
			Address: 1-Milan Sadan, Behind Madina Hotel, Kherani Road, Sakinaka, Andheri<br />
			City: Mumbai<br />
			State: Maharashtra<br />
			Tel: 91- 2228520554, 91-2228563419<br />
			E-mail: <a href="mailto:sales@blowengg.com">sales@blowengg.com</a><br />
			Web: <a href="http://www.blowengg.com">www.blowengg.com</a><br />
			Activities: Plastic Molds, Injection Blow Molding Machine, Blow Molding Machine, Multi<br />
			Layer Blow Molding Machine, Plastic Scrap Grinder, High Speed Color Mixer<br />
			<br />
			Name Company: Bodal Chemicals Ltd.<br />
			Address: Plot No: 123-124, Phase-1, GIDC, Batva<br />
			City: Ahmedabad<br />
			State: Gujarat<br />
			Tel: 91-7925835437, 91- 7925865245, 91- 9898378007<br />
			E-mail: <a href="mailto:pratit.pate@bodal.com">pratit.pate@bodal.com</a> , <a href="mailto:nimesh@bodal.com">nimesh@bodal.com</a><br />
			Web: <a href="http://www.bodal.com">www.bodal.com</a><br />
			Activities: Dye &amp; Dye Intermediaries<br />
			<br />
			Name Company: Brijlazmi Paper Products Pvt. Ltd.<br />
			Address: 14/3, Mathura Road<br />
			City: Faridabad<br />
			State: Haryana<br />
			Tel: 91- 1293000000, 91- 1292577739, 91- 8800000000<br />
			E-mail: <a href="mailto:isha.malhotra@shrachi.com">isha.malhotra@shrachi.com</a><br />
			Web: <a href="http://www.shrachi.com">www.shrachi.com</a><br />
			Activities: Manufacturer, supplier and exporter of note books, register<br />
			<br />
			Name Company: Century Inky Pvt. Ltd.<br />
			Address: ‘‘A/14, Bonanza Ind. Est, A.C Road, Kanivi (E)<br />
			City: Mumbai<br />
			State: Maharashtra<br />
			Tel: 91-2228873360<br />
			E-mail: <a href="mailto:ommantry@gmail.com">ommantry@gmail.com</a> / <a href="mailto:centuryink@gmail.com">centuryink@gmail.com</a><br />
			Web: <a href="http://www.centurymarkers.com">www.centurymarkers.com</a><br />
			Activities: CENTUry INKS is one of the largest manufacture &amp; exporter of Permanent<br />
			fabric market also known as markpen or dye resist marker with international<br />
			quality standards in the world. We are the only ones that manufacture pump-type textile market like Texpen in Asia. The markers are available through agents and</p>

                </Tab>
                <Tab eventKey="Israeli" title="Israeli Campanies" >
                <a href="/Data/Sites/1/2012 EC/Attachments/Business Offers from FICC - October   2019.pdf" target="_blank">Business Opportunities from Israel</a>
                </Tab>
                <Tab eventKey="Business" title="Business Opportunities " >
                <a href="/Data/Sites/1/2012 EC/Attachments/Business Opprotunity Form.docx" target="_blank">BUSINESS OPPORTUNITIES FROM</a>
                </Tab>
              </Tabs>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default App;
