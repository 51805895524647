
import React, { Component } from 'react';
import { Breadcrumb,Nav, Container, Carousel, Card, Row, Col, Form } from "react-bootstrap";

class HomeSlider extends Component {
    render(){
       return(
          <Carousel variant="light">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./images/slider/1.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5>S.G. Welcomes Belgian Ambassador </h5>
            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./images/slider/2.jpg"
            alt="Second slide"
          />
          <Carousel.Caption>
            <h5>ECCSA Receives Sri Lankan Delegation </h5>
           {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./images/slider/3.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h5>ITME Africa 2020 Officially Opened </h5>
            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./images/slider/4.jpg"
            alt="Second slide"
          />
          <Carousel.Caption>
            <h5>ECCSA-CCA: Bilateral Talk on Business Collaboration  </h5>
           {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>  
    )
}}

export default HomeSlider