import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.green,
    padding: theme.spacing(2, 0, 1),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    maxWidth:'100%',
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor:'#fff',
    padding: theme.spacing(1),
  },
  title:{
    color: '#198754',
    // textAlign: 'center',
    // fontFamily: 'AvenirBlack',
    fontWeight: '700',
    fontStyle: 'normal',

  },
  paper:{ 
    padding:theme.spacing(1),
    textAlign:'center',
    color: '#000',

    background:'#fff',
  },
}));

// const cards = [1, 2, 3, 4];

export default function Album() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      
      <main>
        {/* Hero unit */}
        {/* <div className={classes.heroContent}>
       
          
        </div> */}
        <Container className={classes.cardGrid} >
          {/* End hero unit */}
          <Grid container spacing={1}>
            {/* {cards.map((card) => ( */}
              <Grid item  xs={12} sm={6} md={12}>
                <Card className={classes.card}>
                 
                <Typography gutterBottom variant="h6" className={classes.title}>
                    S.G. Welcomes Belgian Ambassador                 </Typography>
                  <CardMedia
                    className={classes.cardMedia}
                    image="../../images/be.jpg"
                    title="Image title"
                    maxWidth="90%"
                  />
                  <CardContent className={classes.cardContent}>
                    
                    <Typography class="body">
               <p>   Secretary General (S.G.) Yesuf Ademnur of the Ethiopian Chamber of Commerce and Sectoral Associations (ECCSA) had a business talk on 25 February 2020 at his office with Francois Dumont, Ambassador of Belgium to Ethiopia and his team Eric SANTKIN, Trade and Investment Commissioner.

In their meetings, they discussed on facilitating cooperation between their respective private sector operators.  </p><p>

</p><p>Ambassador Dumont expressed the interest on their side stating that they want to make a multi sectoral mission here in Addis focusing on business-to-business meetings between Belgian and Ethiopian companies operating especially in textile, agro-flora, renewable energy, steel, pharmaceutical manufacturing and logistics, etc.

</p><p>They also want to cooperate with ECCSA in their endeavor to develop Ethiopian small businesses through the creation of industry-university linkage as SMEs are basis for big industries, said the Ambassador.

</p><p>Welcoming the Ambassador’s interest, Yesuf of ECCSA forwarded a short brief about the Chamber’s activity. He also stated the capacity and demand on the side of Ethiopia.

</p><p>According to his statement, Ethiopia warmly invites FDI to accelerate the ongoing economic growth seen over the last years and the cooperation of the two businesses is vital.

</p><p>Finally, they agreed to facilitate a forthcoming business event between their respective companies. It was learnt that there is a plan of bringing about 50 Belgian companies on the mission.
                         </p>               </Typography>
                  </CardContent>
                  
                </Card>
                
              </Grid>
              
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      
      {/* End footer */}
    </React.Fragment>
  );
}