import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';
import { classExpression } from '@babel/types';
import HomeSlider from '../component/slider/HomeSlider';
import MainMenu from '../component/NavMenus/MainMenu';

import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { CardMedia, Card } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import 'bootstrap/dist/css/bootstrap.css';
import Welcome from '../component/Welcome/Welcome';
import News from '../component/News/Blog';
import Footer from '../component/Footer/Footer';
// import News from '../component/News/News';

const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  }
}))

function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
      {/* <img            
               
               className="d-block w-70"
                class="img-fluid"
                src="../images/header.jpg" 
                href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a>
   <MainMenu/> */}

      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
          {/* <MainMenu/> */}
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={12}>
          <Grid item xs={12} sm={6} md={12}>
            <Card className={classes.card}>

              <Typography gutterBottom variant="h6" className={classes.title}>
                ITME Africa 2020 Officially Opened            </Typography>
              <CardMedia
                className={classes.cardMedia}
                src="http://ethiopianchamber.com/Data/Sites/1/2012%20EC/images/I6.jpg"
                title="Image title"
                maxWidth="90%"
              />
              <CardContent className={classes.cardContent}>

                <Typography class="body">
                  <p>
                    <img src="http://ethiopianchamber.com/Data/Sites/1/2012%20EC/images/I6.jpg" width="75%" />
                  </p><p>The inauguration of the International Textile Machinery and Engineering Exhibition (ITME-Africa 2020) which is going to stay for three days long was officially opened on 14 February 2020 at the Millennium Hall here in Addis at the presence of senior government dignitaries from India and Ethiopia, business representatives from various countries and company owners.</p><p>Following the event, said the Ambassador, lots of Sri Lankan companies made inquiries of making business meetings with their Ethiopian counterparts in late March this year, citing a prominent Sri Lankan construction company as an instance, in order that they would be able to work in partnership.
                  </p><p>This huge and remarkable event was organized by Indian ITME Society in partnership with the Ethiopian Chamber of Commerce and Sectoral Associations (ECCSA) under the theme: “Prosperity for Africa through Textile Technology” with view to initiate a revolution in technology up gradation entrepreneurship and investments in textiles across the region and beyond. It was also reported that the event anticipates being instrumental in establishing Africa’s textile footprints prominently across the globe.</p><p>According to his statement, Ethiopia warmly invites FDI to accelerate the ongoing economic growth seen over the last years and the cooperation of the two businesses is vital.

                  </p><p> In his welcoming address, Melaku Ezezew (Eng.), President of ECCSA stated that the business relation of the business communities of both countries should not only be to enhance the existing relation but also to reserve Indian and Ethiopian commercial legacy two millennia long
                  </p><p> Due to low productivity, limited quality awareness and ineffective and inefficient management structures and limitations of skills training, Ethiopian textile is said still not in a position to compete in the international market, said the President. This exhibition is hopefully expected to bring about a better experience and knowledge from the various cultures that can fill the gap, he added
                  </p><p> Addressing on the event, Teka Gebreyesus, State Minister of Ethiopian Ministry of Trade and Industry also forwarded his impression of the event being organized in his country saying that it would bring a promising and favorable development podium to the regional, national and private sector growth in textile technology.
                  </p><p>    Pointing out that India is 4th trading partner of Africa, H.E. Ms. Dorothy Tembo, Executive Director, International Trade Centre, UN, Geneva, explained that the synergy in textile and other developments is very important because growth in economy is all about improving the lives of African people.

                    India’s total trade with Ethiopia stood at 1.27 billion in 2017-18, out of which India’s export to Ethiopia were 1.22 billion USD and imports were 45 million USD, according to the National Bank of Ethiopia annual report.            </p>               </Typography>
              </CardContent>

            </Card>

          </Grid>


        </Grid>

        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
