import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid,Paper,Button } from "@material-ui/core";

import 'bootstrap/dist/css/bootstrap.min.css';
 import '../App.css';
 import './Pages.css';
import '../component/slider/HomeSlider';
import MainMenu from '../component/NavMenus/MainMenu2'; 

import 'bootstrap/dist/css/bootstrap.css';

import MyServices from '../component/Services/MyServices';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import Footer from '../component/Footer/Footer';

import ServiceLinks from '../component/NavMenus/ServiceLinks'; 
const useStyle =makeStyles((theme)=>({
  grid:{
    width:'100%',
    margin:'15px',
    padding:'1px',
    row:'20px',
      background:'#fff',
  },
  main:{
    width:'100%',
    background:'#fff',
    padding:'10px',
  

  },
  paper:{ 
    // padding:theme.spacing(1),
    textAlign:'left',
    color: '#198754',
    background:'#fff',
    padding:'10px',
    
    // elevation:'0',
  },
  counter:{
    background:'#fff',
    color:'#fff',
    textAlign:'center',
    fontWeight:'900',
  },
  appBar:{
    color: '#000',
    background:'#fff',
  },
  margin:{
    marginLeft:'100px',
    marginTop:'5px',
    color:'primary',
    
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
  }


}))

function App() {
  const classes=useStyle();
  const el = document.querySelector( '.counter' )
  return (
    <div class="mmenu">
     {/* <img            
                
                className="d-block w-70"
                 class="img-fluid"
                 src="../images/header.jpg" 
                 href="/"></img>  <a href="/user" class="btn btn-outline-success">My Page</a>
    <MainMenu/> */}
  
   {/* <div className={classes.main} > */}
  
     
        
   <Grid container spacing={2} className={classes.grid} className="grid">   
   <Grid item xs={12} md={12}>
          {/* <MainMenu/> */}

          <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum}>
          <Link to="/#">Home</Link>
                    
            <Link to="#">My Page</Link>
          </Breadcrumbs></Grid>
          <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
 <Grid item xs={10} md={10}>
         {/* <div class="row"> */}
   {/* </div>  */}
           
  
         <MyServices />
         
       
               </Grid>
              
       
       
               <Grid item xs={1} md={1}>

{/* <Welcome /> */}

</Grid>
        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
     </Grid>
    </div>
    
  );
}

export default App;
