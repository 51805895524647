import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Container } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import 'bootstrap/dist/css/bootstrap.css';

import Footer from '../component/Footer/Footer';


const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
       }
}))

function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
   

      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum} >
                <Link color="inherit" to="/" >
              Home
            </Link>

            <Link
              color="textPrimary"
              to="#"
              
              aria-current="page">
              Events
            </Link>
            </Breadcrumbs>
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={12}>

          <Container className={classes.cardGrid} >
            {/* End hero unit */}
            <Grid container spacing={1}>
              {/* {cards.map((card) => ( */}
              <Grid item xs={12} sm={6} md={3}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://ethiopianmonitor.com/wp-content/uploads/2021/06/198815882_3050247588532143_2746811636147079007_n-e1623612525297-800x445.jpg"
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h6" className={classes.title}>
                      New Year Exhibition                </Typography>

                    Ethioppian New Year Exhibition </CardContent>
                  <CardActions>

                    <Button variant="outlined" href="#" class="btn btn-outline-success" color="primary">
                      Read more
                    </Button>
                  </CardActions>
                </Card>

              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://ethiopianmonitor.com/wp-content/uploads/2021/06/198815882_3050247588532143_2746811636147079007_n-e1623612525297-800x445.jpg"
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h6" className={classes.title}>
                      New Year Exhibition                </Typography>

                    Ethioppian New Year Exhibition </CardContent>
                  <CardActions>

                    <Button variant="outlined" href="#" class="btn btn-outline-success" color="primary">
                      Read more
                    </Button>
                  </CardActions>
                </Card>

              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image="https://ethiopianmonitor.com/wp-content/uploads/2021/06/198815882_3050247588532143_2746811636147079007_n-e1623612525297-800x445.jpg"
                    title="Image title"
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h6" className={classes.title}>
                      New Year Exhibition                </Typography>

                    Ethioppian New Year Exhibition </CardContent>
                  <CardActions>

                    <Button variant="outlined" href="#" class="btn btn-outline-success" color="primary">
                      Read more
                    </Button>
                  </CardActions>
                </Card>

              </Grid>

            </Grid>
          </Container>


        </Grid>

        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
