import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';

import ServiceLinks from '../component/NavMenus/ServiceLinks';

import 'bootstrap/dist/css/bootstrap.css';

import Footer from '../component/Footer/Footer';
import Services from '../component/Services/Services';

const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '15px',
    padding: '1px',
    row: '20px',
    background: '#fff',
  },
  main: {
    width: '100%',
    background: '#fff',

  },
  paper: {
    // padding:theme.spacing(1),
    textAlign: 'left',
    color: '#198754',
    background: '#fff',
    padding: '10px',

    // elevation:'0',
  },
  counter: {
    background: '#fff',
    color: '#fff',
    textAlign: 'center',
    fontWeight: '900',
  },
  appBar: {
    color: '#000',
    background: '#fff',
  }

}))

function App() {
  const classes = useStyle();
  return (
    <div >
      {/* <Topbar/> */}

      <Grid container spacing={2} className={classes.grid} className="grid" >
        <Grid item xs={12} md={12}>
          {/* <MainMenu/> */}
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={8}>



          <Services />


        </Grid>
        <Grid item xs={12} md={3}>
          <Paper >
            <ServiceLinks />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
