import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    
    useParams,
  } from "react-router-dom";
//   import { Button } from "@material-ui/core";
  import { Navbar,Nav,NavDropdown,Container,NavItem,Form,FormControl,Button } from 'react-bootstrap';
  import './MainMenu.css';
  import { makeStyles } from '@material-ui/core/styles';
//   import Home from './Home';
//   import AboutUs from './AboutUs';
import 'bootstrap/dist/css/bootstrap.min.css';
const useStyles =makeStyles((theme)=>({
   
    main:{
      width:'100%',
      background:'#198754',
      fontWeight: '600',
      fontStyle: 'normal',
      
      row:'10px',
    //   textAlign:'left',
      color: '#ffffff',
      alignContent: 'center',
      padding:'10px',
     
      
      // elevation:'0',
    },
  margin:{
    marginLeft:'200px',
    marginTop:'5px',
    marginBottom:'0px'
    // color:'primary',
    // float: 'right',
    }
  
  }))

  export default function Album() {
    const classes = useStyles();
    // const classes=useStyle();
    // render(){
        return(
           
           <Navbar collapseOnSelect className={classes.main} sticky="top" expand="lg" class="dark2" >
            <Container>
            <Navbar.Brand href="/">
            
            </Navbar.Brand>
           
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <NavDropdown title="About Us" href="/about" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/about">About the Chamber</NavDropdown.Item>
                                        <NavDropdown.Item href="/ChamberstructurePage">Organizational Structure</NavDropdown.Item>
                                        <NavDropdown.Item href="/assembly">General Assembly </NavDropdown.Item>
                                        <NavDropdown.Item href="/board">Board of Directors</NavDropdown.Item>
                                        <NavDropdown.Item href="/team"> The Secretariat</NavDropdown.Item>
                                        {/* <NavDropdown.Divider /> */}
                                        <NavDropdown.Item href="#">Former Presidents & Secretary G.</NavDropdown.Item>
                                        <NavDropdown.Item href="#">Our Location</NavDropdown.Item>
                                        <NavDropdown.Item href="#">Our Partners</NavDropdown.Item>
                                        <NavDropdown.Item href="#">Strategic Plan</NavDropdown.Item>
                                        <NavDropdown.Item href="#">Chamber Law (+Directives)</NavDropdown.Item>
                                        <NavDropdown.Item href="#">ECCSA Projects</NavDropdown.Item>
                                   
                                   
                                    </NavDropdown>
                                    <NavDropdown href="./services" title="Services" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/trade-investment" >Trade & Investment Promotion</NavDropdown.Item>
                                        <NavDropdown.Item href="/capacity-building">Capacity Building</NavDropdown.Item>
                                        <NavDropdown.Item href="/research-advocacy">research & advocacy</NavDropdown.Item>
                                      
                                        {/* <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item> */}
                                    </NavDropdown>
                                    <Nav.Link href="/membership" class="navitems" activeClassName="selected">Membership</Nav.Link>
                                    <Nav.Link href="/" class="navitems" activeClassName="selected">Events</Nav.Link>
                                  
                                    <Nav.Link class="navitems" href="/news">News </Nav.Link>
                                    {/* <Nav.Link class="navitems" href="/news">Work with us </Nav.Link> */}
                                    <NavDropdown href="./services" title="Resources" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/eresources" >e-Resources</NavDropdown.Item>
                                        <NavDropdown.Item href="/eresources">Publications at RC</NavDropdown.Item>
                                        <NavDropdown.Item href="/eresources">E-Newsletter Download</NavDropdown.Item>
                                        <NavDropdown.Item href="/eresources">Important Links</NavDropdown.Item>
                                        <NavDropdown.Item href="/eresources">Downloads</NavDropdown.Item>
                                        <NavDropdown.Item href="/eresources">Publications for Sale</NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown href="./services" title="Opportunities" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/trade-investment" >Business To Business</NavDropdown.Item>
                                        <NavDropdown.Item href="/capacity-building">Training </NavDropdown.Item>
                                        <NavDropdown.Item href="/research-advocacy">Business Opportunities</NavDropdown.Item>
                                        <NavDropdown.Item href="/research-advocacy">Exhibition</NavDropdown.Item>
                                        </NavDropdown>
                                    <Nav.Link href="">About Ethiopia </Nav.Link>
                                    <Nav.Link href="/contact">Contact Us</Nav.Link> 
                                    
                                    
              </Nav>
              {/* <Nav>
                <Nav.Link href="#deets">More deets</Nav.Link>
                <Nav.Link eventKey={2} href="#memes">
                  Dank memes
                </Nav.Link>
              </Nav> */}
            </Navbar.Collapse>
            </Container>
          </Navbar>
         
  );
}

// export default MainMenu;