import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { Link } from 'react-router-dom';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';
import { classExpression } from '@babel/types';
import HomeSlider from '../component/slider/HomeSlider';
import MainMenu from '../component/NavMenus/MainMenu';
import QuickLinks from '../component/NavMenus/QuickLinks';
import header from '../images/header.jpg';
import Topbar from '../component/topbar/Topbar';
import 'bootstrap/dist/css/bootstrap.css';
import Welcome from '../component/Welcome/Welcome';
import News from '../component/News/News';
import Footer from '../component/Footer/Footer';

const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
  }
}))
function handleClick(event) {
  event.preventDefault();

}
function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
      {/* <img

        className="d-block w-70"
        class="img-fluid"
        src="../images/header.jpg"
        href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a>
      <MainMenu/> */}


      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
          {/* <MainMenu/> */}

          <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum}>
           
            <Link  to="/" > Home</Link>
              <Link  eventKey="about" aria-current="page" to="#" > About ECCSA</Link>
          
            
          </Breadcrumbs></Grid>
        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={8}>
          <Paper ><div class="row">
            <section id="team" class="pb-5">
              <div class="container">
                <h5 class="section-title h3">About The Chamber  </h5>
                <div class="row">
                  <p class="card-text">
                    

The concept of Chamber of Commerce was introduced to Ethiopia for the first time in 1943. The need for its establishment basically emanated from the economic crisis occurred during that time. The establishment of the Chamber of Commerce was seen as a solution to address the distribution of scarce commodities such as cotton, yarn, and woolen products. Members of the Chamber of Commerce were allowed to distribute those scarce commodities to stabilize the market.

Cognizant to these the Government issued Charter No. 90/47 in 1947 in a bid to establishing the Chamber as a legally recognized institution. This was the turning point for the establishment of an apex organization of the private sector in the country. The Charter clearly defined the roles and functions of the Chamber and stipulated membership to be mandatory.

</p><p>A cursory investigation of some documents indicates that the Chamber had good Government support with patronage at the highest political level. In 1959, the Government as part of its endeavors to strengthen the Chamber provided a plot of land on which the current a seven-story Head Quarters building was constructed. The construction of the building was also facilitated by the Government. The National Chamber had then branches in Asmara, Diredawa, Gondar, Jimma and Nazareth.

Following the change of Government in 1974 proclamation No. 148/74 was enacted that declared membership of business entities mandatory and restructured the Chamber in line with the command economic policy adopted by the then administration. 

</p><p>The Chambers’ activities during this period were focused mainly on promoting export trade, participating in international trade fairs, conducting research on trade constraints and establishing relations with other chambers.

In 2003, the incumbent Government promulgated Proclamation No. 341/2003 with a view to reorganizing Chambers of Commerce in line with the free market economic policy and the Government’s Industrial Development Strategy (IDS) of the nation. Accordingly, the Ethiopian Chamber of Commerce has been restructured and rechristened the Ethiopian Chamber of Commerce and Sectoral Associations in 2007.

The Ethiopian Chamber of Commerce and Sectoral Associations (ECCSA) is an apex organization of Chambers and Sectoral Associations in Ethiopia. It has eighteen members including nine Regional Chambers of Commerce and Sectoral Associations, two City Chambers of Commerce and Sectoral Associations, one National Chamber of Sectoral Associations and six Sectoral Associations organized at national level.

        
        
      <br />
                    </p>
                </div>
              </div>
            </section>

          </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper >
            {/* <h5 class="text-uppercase">Quick Links</h5> */}

            <QuickLinks />
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
