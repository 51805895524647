import { render } from '@testing-library/react';
import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Component } from 'react';
import { Breadcrumb,Nav, Container, Carousel, Card, Row, Col, Form } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
// import { faBook } from '@fortawesome/free-solid-svg-icons';
import {faBook} from '@fortawesome/free-solid-svg-icons';
import {faChalkboardTeacher} from '@fortawesome/free-solid-svg-icons';
import AnimatedNumber from "animated-number-react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Numbers.css';
// import 'font-awesome/css/font-awesome.min.css';
const useStyle =makeStyles((theme)=>({
    grid:{
      width:'100%',
      margin:'15px',
      padding:'1px',
      row:'20px',
        background:'#fff',
    }}))
class Numbers extends Component {
    state = {
        value: 1150,
        value2:785,
        value3:75,
        duration: 900
      };
      formatValue = value => ` ${Number(value).toFixed(0)}`;
    render(){
       return(
<div class="row">
{/* <div class="row">
    <div class="bg">Our Statstics</div>
 </div>    */}
    <div class="row">
<div class="col-xl-3 col-md-3 ">
                            <div class="card  text-center border1 py-1">
                                 <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col"><FontAwesomeIcon icon="coffee" />
                                        <div class="icon2">
                                            <AnimatedNumber
          value={this.state.value}
          formatValue={this.formatValue}
          duration={this.state.duration}
          className="icon2"
        />
                                        
                                        </div>
                                          <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                          

                                          <a href="#" class="nos"  >
                                                   
                                          Private Members</a> 
                                             
                                          
                                                
                                                </div>
                                        </div>
                                        <div class="col-auto">
                                                                                <i class="fas fa-calendar fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
<div class="col-xl-3 col-md-3  ">
                            <div class="card text-center py-1 card-box  border2">
                            <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col"><FontAwesomeIcon icon="coffee" />
                                        <div class="icon2">
                                         <AnimatedNumber
          value={this.state.value2}
          formatValue={this.formatValue}
          duration={this.state.duration} 
          className="icon2" /></div>
                                     </div>     <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                          
                                     <a href="/capacity-building" class="nos">
                                              
       
                               
                                         Associations </a> </div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">
                                                
                                             
                                                </div>
                                        {/* </div> */}
                                        <div class="col-auto">
                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                        <div class="col-xl-3 col-md-3 ">
                            <div class="card text-center py-1 ard-box border3 ">
                            <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col"><FontAwesomeIcon icon="coffee" />
                                        <div class="icon2">
                                            <AnimatedNumber
          value={this.state.value}
          formatValue={this.formatValue}
          duration={this.state.duration}
          className="icon2"
        /> </div></div>
                                            <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                          
                                          <a href="/capacity-building" class="nos" >
                                                   
            
                                    
                                          Processed  Applications</a> </div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">
                                                
                                                
                                                </div>
                                        </div>
                                        <div class="col-auto">
                                        <FontAwesomeIcon icon={['fab', 'microsoft']} />
                                            <i class="fas fa-calendar fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div class="col-xl-3 col-md-3 ">
                            <div class="card  text-center py-1 ard-box border4">
                            <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col"><FontAwesomeIcon icon="coffee" />
                                        <div class="icon2">
                                            <AnimatedNumber
                                                value={this.state.value3}
                                                formatValue={this.formatValue}
                                                duration={this.state.duration}
                                                className="icon2"
                                                /> </div></div>
                                            <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                          
                                          <a href="/capacity-building" class="nos" >
                                                   
            
                                    
                                              Trainings</a> </div>
                                            <div class="h5 mb-0 font-weight-bold text-gray-800">
                                                
                                                
                                                </div>
                                        </div>
                                        <div class="col-auto">
                                        <FontAwesomeIcon icon={['fab', 'microsoft']} />
                                            <i class="fas fa-calendar fa-2x text-gray-300"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                </div>
                </div>

       )}}

export default Numbers