import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import '../component/slider/HomeSlider';

import 'bootstrap/dist/css/bootstrap.css';

import Footer from '../component/Footer/Footer';
import { Link } from 'react-router-dom';

const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '0px',
    padding: '3px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    background: theme.palette.success.light,
  },
  breadcrum: {
    backgroundColor: '#fff',
    padding: '10px',
    marginLeft: '100px',
       }
}))
function handleClick(event) {
  event.preventDefault();

}
function App() {
  const classes = useStyle();
  return (
    <div class="mmenu">
      {/* <img            
               
               className="d-block w-70"
                class="img-fluid"
                src="../images/header.jpg" 
                href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a>
   <MainMenu/> */}


      <Grid container spacing={2} className={classes.grid} className="grid">
        <Grid item xs={12} md={12}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum} >
            <Link color="inherit" to="/" >
              Home
            </Link>

            <Link
              color="textPrimary"
              to="#"
              aria-current="page">
            Trade Fair 
            </Link>
            </Breadcrumbs>
        </Grid>

        <Grid item xs={1} md={1}>

          {/* <Welcome /> */}

        </Grid>
        <Grid item xs={11} md={8}>

        <h3>Trade Fair </h3>
        
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper >
            {/* <ServiceLinks /> */}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* <Paper className={classes.paper}>Footer Section</Paper> */}
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
