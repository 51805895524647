import React from 'react';
// import Navbar from "./component/Navbar/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Button } from "@material-ui/core";
// import AppBar from '@material-ui/core/AppBar';
// import { Breadcrumb, Nav, Navbar, NavItem, NavDropdown, Container, form, Carousel, Card, Row, Col, Form } from "react-bootstrap";
// import { Dropdown, MenuItem, DropdownButton } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
// import LabelImportantIcon from '@material-ui/icons/LabelImportant';
// import { BrowserRouter as Router, Route, Swithch, Link, Redirect } from 'react-router-dom';
import '../App.css';
import './Pages.css';
import '../component/slider/HomeSlider';
// import { classExpression } from '@babel/types';
import HomeSlider from '../component/slider/HomeSlider';
// import MainMenu from '../component/NavMenus/MainMenuy';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Numbers from '../component/Numbers/Numbers';
import News from '../component/News/News';
import Services from '../component/Services/Services';
// import ScrollToTop from '../component/ScrollToTop/ScrollToTop';
import Partners2 from '../component/Teams/Partners';
import Team from '../component/Team2/Team';
import Events from '../component/Event/Event';
import Event2 from '../component/Event/Event2';
import Ads from '../component/Ads/Ads';
import Footer from '../component/Footer/Footer';
import Faq from '../component/Faq/Faq';
import EventTimeline from '../component/Event/EventTimeline';

const useStyle = makeStyles((theme) => ({
  grid: {
    width: '100%',
    margin: '15px',
    padding: '1px',
    row: '20px',
    background: '#fff',
  },
  main: {
    width: '100%',
    background: '#fff',
    padding: '10px',


  },
  paper: {
    // padding:theme.spacing(1),
    textAlign: 'left',
    color: '#198754',
    background: '#fff',
    padding: '10px',

    // elevation:'0',
  },

  counter: {
    background: '#fff',
    color: '#fff',
    textAlign: 'center',
    fontWeight: '900',
  },
  appBar: {
    color: '#000',
    background: '#fff',
  },
  margin: {
    marginLeft: '100px',
    marginTop: '5px',
    color: 'primary',

  }


}))

function App() {
  const classes = useStyle();
  const el = document.querySelector('.counter')
  return (
    <div class="mmenu">
      {/* <img
        className="d-block w-70"
        class="img-fluid"
        src="../images/header.jpg"
        href="/"></img>  <a href="/login" class="btn btn-outline-success">Login/Register</a> */}
       {/* <MainMenu/> */}

      <div className={classes.main} >
        <Grid container spacing={2} className={classes.grid} className="grid">
          <Grid item xs={12} md={12}>
            <Paper elevation={3} variant="outlined" square>
              <HomeSlider />
            </Paper>
          </Grid>
          <Grid elevation={0} item xs={12} md={12}>

            <Numbers />


          </Grid>
          <Grid item xs={12} md={12} className={classes.counter}>
            <div class="row">
              <div class="bg">Our Services</div>
            </div>
            {/* <AppBar position="relative" className={classes.paper}> 
        <Typography variant="h6" color="success" noWrap>
        <StopIcon /> Services   </Typography></AppBar> */}
            <Services />





          </Grid>
          <Grid item xs={12} md={12}>

            {/* <AppBar position="relative" className={classes.paper}> 
        <Typography variant="h6" color="success" noWrap>
        <StopIcon /> Recent News    </Typography></AppBar> */}
            <div class="row">
              <div class="bg">Recent News</div>
            </div>
            <News />

          </Grid>
          <Grid item xs={12} md={6}>
            {/* <AppBar position="relative" className={classes.paper} > 
        <Typography variant="h6" color="success" noWrap>
        <StopIcon /> Events          </Typography></AppBar> */}
            <div class="row">
              <div class="bg">Events</div>
            </div>
            <Event2 />
            
            <Link  eventKey="events" to="/events" class="btn btn-success"> More Events</Link>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* <AppBar position="relative" className={classes.paper}> 
        <Typography variant="h6" color="success" noWrap>
        <StopIcon /> Event Details      </Typography></AppBar> */}
            <div class="row">
              <div class="bg">Event Details</div>
            </div>
            <EventTimeline />
          </Grid>
          <Grid item xs={12} md={12}>

          </Grid>
          <Grid item xs={12} md={6}>

            <div class="row">
              <div class="bg">Membership Advantages</div>
            </div>
            <Faq />
            <Link  eventKey="membership1" to="/membership1" class="btn btn-success"> Become a Member</Link>
            
          </Grid>
          <Grid item xs={12} md={6}>
            {/* <AppBar position="relative" className={classes.paper}><Toolbar>
          
          <Typography variant="h6"  color="success" noWrap>
        <StopIcon />   Ads
          </Typography>
        </Toolbar></AppBar> */}
            <div class="row">
              <div class="bg">Ads</div>
            </div>
            <Ads />
          </Grid>
          <Grid item xs={12} md={12}>
            <div class="row">
              <div class="bg">Our Partners</div>
              {/* <ScrollToTop /> */}
            </div>

            <Partners2 />
          </Grid>

          <Grid item xs={12} md={12}>
            {/* <Paper className={classes.paper}>Footer Section</Paper> */}

          </Grid>
        </Grid>
        <Footer />
      </div>
    </div>
  );
}

export default App;
